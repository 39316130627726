import React from 'react'

export default function FAQElement ({ id, question, answer, answerShowing = false, onToggle }) {
  return (
    <>
      <dt key={`question_${id}`} className={`FAQ__question ${answerShowing ? 'FAQ__question--active' : ''}`}>
        <button
          className={`FAQ__question-button ${answerShowing ? 'FAQ__question-button--active' : ''}`}
          aria-expanded={answerShowing}
          aria-controls={`${id}_desc`}
          onClick={() => onToggle(id)}
        >
          {question}
          <div className={`FAQ__question-button-state-indicator ${answerShowing ? 'FAQ__question-button-state-indicator--active' : ''}`} />
        </button>
      </dt>
      <dd key={`answer_${id}`} className={`FAQ__answer ${answerShowing ? 'FAQ__answer--active' : ''}`}>
        <div id={`${id}_desc`} className={`FAQ__answer-content ${answerShowing ? 'FAQ__answer-content--active' : ''}`}>
          {answer}
        </div>
      </dd>
    </>
  )
}
