const ADDRESS = /^[A-Za-z0-9 ,.-]+$/
const CITY = ADDRESS
const POSTAL_US = /^([0-9]{5}|([0-9]{5}-[0-9]{4}))$/
const POSTAL_CA = /^[A-Z][0-9][A-Z] [0-9][A-Z][0-9]$/
const ADDRESS2 = /^[A-Za-z0-9 ,/#'@%.-]*$/
const STATE_CA = /^\b(?:AB|ALB|Alta|alberta|BC|CB|British Columbia|LB|Labrador|MB|Man|Manitoba|N[BLTSU]|Nfld|NF|Newfoundland|NWT|Northwest Territories|Nova Scotia|New Brunswick|Nunavut|ON|ONT|Ontario|PE|PEI|IPE|Prince Edward Island|PC|QUE|QU|Quebec|QC|quebec|qc|SK|Sask|Saskatchewan|YT|Yukon|Yukon Territories)\b/i

export default {
  ADDRESS,
  CITY,
  POSTAL_US,
  POSTAL_CA,
  ADDRESS2,
  STATE_CA
}
