import React, { useState, useEffect, useRef } from 'react'
import noop from 'lodash/noop'
/**
 * @param states the possible states the button can morph into
 * @param initialState state that the button starts in
 * @param play whether or not the morphing element states are playing
 * @param durationBetweenStates time, in ms, spent transitioning between each state
 * @param stateRenderer a function that recieves each state and then renders a react component
 *
 * @example ```jsx
 * <MorphingRenderer states={[1,2,3,4,5]} stateRenderer={state => <h1>{state}</h1}>
 *     Click me to count up to 5!
 * </MorphingRenderer>
 * ```
 */

const MorphingRenderer = React.memo(({ states, initialState = 0, play = true, durationBetweenStates = 500, stateRenderer, children }) => {
  const [stateIndex, setStateIndex] = useState(initialState)
  const incrementState = useRef()

  useEffect(() => {
    incrementState.current = (cb = noop) => {
      if (stateIndex < states.length) {
        setStateIndex(stateIndex + 1)
        return cb()
      }
    }
  }, [stateIndex])

  useEffect(() => {
    if (play) {
      const timeoutRef = incrementState.current(() => setTimeout(() => incrementState.current(), durationBetweenStates))
      return () => clearTimeout(timeoutRef)
    }
  }, [play])

  return [
    children,
    ...states.map(stateRenderer)
  ][stateIndex]
})

export default MorphingRenderer
