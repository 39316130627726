import './CartBlock.scss'
import './CartItem.scss'

import React, { useCallback, useState, useEffect } from 'react'
import Swatch from 'components/Swatch/Swatch'
import extractFullColorNumber from 'app/Utils/extractFullColorNumber.util'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'
import useCart from 'app/hooks/useCart'
import TrashIcon from 'src/svg/trash.svg'
import PlusIcon from 'src/svg/plus.svg'
import MinusIcon from 'src/svg/minus.svg'
import SVG from 'react-inlinesvg'
import { LIMIT_CART_CHIP, LIMIT_ITEM_PEEL_STICK } from 'app/constants/product'
import { isColorEde } from 'app/Utils/isColorFamily.util'
import CircleLoader from 'components/loaders/CircleLoader/CircleLoader'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import KitItem from 'components/Cart/KitItem'
import Tooltip from 'components/Tooltip/Tooltip'

export const DISPLAY_MODES = {
  NONE: 'NONE',
  SMALL: 'SMALL',
  LARGE: 'LARGE'
}

const CLASS = 'CartBlock'

function CartBlock ({ children, className = '', ...other }) {
  return (<>
    <div tabIndex='0' role='list' aria-label='cart section' {...other} className={`${CLASS} ${className}`}>{children}</div>
  </>)
}

function CartItemWrapper ({ big = false, spaceAfter = false, blank = false, children }) {
  return <div tabIndex={blank ? '-1' : '0'} role={!blank ? 'listitem' : null} aria-hidden={blank} className={`${CLASS}__item ${big ? `${CLASS}__item--big` : ''} ${spaceAfter ? `${CLASS}__item--space-after` : ''}`}>{children}</div>
}

function CartItem ({ item = {}, removed = false, onQtyChange = noop, displayMode = DISPLAY_MODES.NONE }) {
  const { colors, isSwatch, qty, price = 0, discountedPrice } = item
  const isKit = item.isKit
  const color = isKit ? {} : colors[0]
  const hasItem = !isEmpty(item)
  const [inputValue, setInputValue] = useState(qty)
  const { countChips, loading } = useCart()
  const [inputMax, setInputMax] = useState(Infinity)
  const handleQtyChange = useCallback((item, qty, abs = false) => {
    const intQty = parseInt(qty)

    if (isNaN(intQty)) return

    onQtyChange(item, intQty, abs)
  }, [])

  useEffect(() => setInputValue(qty), [qty])
  useEffect(() => {
    if (isSwatch || isKit) return setInputMax(LIMIT_ITEM_PEEL_STICK)
    setInputMax(LIMIT_CART_CHIP - countChips + qty)
  }, [isSwatch, isKit, countChips, qty])

  const isRemoved = removed || qty === 0

  const size = displayMode === DISPLAY_MODES.SMALL
    ? (isSwatch ? Swatch.SIZES.SMALL : Swatch.SIZES.XSMALL)
    : (isSwatch ? Swatch.SIZES.DEFAULT : Swatch.SIZES.SMALL)

  const contentOutside = isSwatch
    ? false
    : (displayMode === DISPLAY_MODES.SMALL)

  return (
    <CartItemWrapper big={isSwatch} spaceAfter={hasItem}>
      <div className='CartItem'>
        <div className='CartItem__swatch'>
          {isKit
            ? <KitItem product={item} />
            : <Swatch
            contentOutside={contentOutside}
            size={size}
            hasOverlay={isRemoved}
            name={color.name}
            number={extractFullColorNumber(color, true)}
            color={color.hex}
            swatchStyle={isSwatch ? Swatch.STYLES.SQUARE : Swatch.STYLES.DEFAULT}
            inside={() => <>
              {isColorEde(color) ? <Swatch.Tag><FormattedMessage id='DESIGNER_COLOR_COLLECTION' /></Swatch.Tag> : null}
              {isRemoved || loading
                ? <Swatch.Overlay>
                {loading
                  ? <CircleLoader className='is-size-3' />
                  : isRemoved
                    ? <div className='my-5'><FormattedMessage id='REMOVED_FROM_CART' /></div>
                    : null}
              </Swatch.Overlay>
                : null}
            </>} />}
        </div>
        {isKit
          ? <span className='Tooltip'>
          <Tooltip
            topOffset={35}
            leftOffset={105}
            mode='click'
            colors={colors}
          />
        </span>
          : null}
        <div className="CartItem__swatch-info">
          <div className='CartItem__warning'>
            {(isSwatch || isKit) && qty >= LIMIT_ITEM_PEEL_STICK ? <p><FormattedMessage id='MAX_QUANTITY_IS' values={{ quantity: LIMIT_ITEM_PEEL_STICK }} /></p> : null}
          </div>
          <div className='CartItem__qty'>
          <div className='field is-horizontal has-addons is-justify-content-center'>
            <p className='control'>
              <button disabled={loading || qty === 0} onClick={() => handleQtyChange(item, -1)} className={`CartItem__qty__btn button is-rounded rounded-full ${qty > 1 ? '' : 'button__delete'}`}>
                {qty > 1
                  ? <SVG aria-label='Decrease quantity by 1' className='svg' src={MinusIcon} />
                  : <SVG aria-label='Remove item from cart' className='svg-outline' src={TrashIcon} /> }
              </button>
            </p>
            <p className='control'>
              <input aria-label='Number in cart' readOnly min={0} max={inputMax} disabled={loading} className='CartItem__qty__input CartItem__qty__input__with-background input is-small has-text-centered' value={inputValue} type='tel' style={{ borderRadius: '2px' }} />
            </p>
            <p className='control'>
              <button disabled={loading || qty >= inputMax} onClick={() => handleQtyChange(item, 1)} className='CartItem__qty__btn CartItem__qty__input__btn--more button is-rounded rounded-full'>
                <SVG aria-label='Increase quantity by 1' className='svg' src={PlusIcon} />
              </button>
            </p>
          </div>
          {price > 0 && (
            <p className='has-text-centered is-size-5'>
              {discountedPrice === undefined
                ? (
                  <span className='has-text-weight-semibold'>
                    <FormattedNumber style='currency' value={price * qty} currency='USD' />
                  </span>
                  )
                : (
                <>
                  <span className='has-text-grey-light x-has-text-weight-semibold' style={{ textDecoration: 'line-through' }}>
                    <FormattedNumber style='currency' value={price * qty} currency='USD' />
                  </span>
                  <br />
                  <span className='has-text-green has-text-weight-semibold'>
                    <FormattedNumber style='currency' value={discountedPrice * qty} currency='USD' />
                  </span>
                </>
                  )
              }
            </p>
          )}
          </div>
        </div>
      </div>
    </CartItemWrapper>
  )
}

CartBlock.Item = CartItem
CartBlock.Blank = props => <CartItemWrapper blank {...props} />

export default CartBlock
