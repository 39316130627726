import withUniqueId from 'app/HOCs/withUniqueId'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { withIsLoading } from './withIsLoading'

export const NewsletterOptin = withUniqueId(withIsLoading(({ isLoading, register, id }) => (
  <div className='field'>
    <div className='control'>
      <label className='checkbox OrderForm__email-opt-in mb-4' htmlFor={id}>
        <input className='mr-2' readOnly={isLoading} type='checkbox' id={id} name='newsletterOptIn' ref={register} />&nbsp;
        <FormattedMessage id='CHECKOUT_OPTIN_EMAIL_FROM_SW' />
      </label>
    </div>
  </div>
)))
