import React from 'react'
import RequiredAsterisk from '../RequiredAsterisk/RequiredAsterisk'
import { FormattedMessage, injectIntl } from 'react-intl'
import { ErrorMessage } from 'react-hook-form'
import { withIsLoading } from './withIsLoading'
import withUniqueId from 'app/HOCs/withUniqueId'

export const City = withUniqueId(injectIntl(withIsLoading(({ registerAs, isLoading, errors, register, intl, id }) => {
  return (
    <div className='field'>
      <label className='label has-text-weight-normal' htmlFor={id}>
        <FormattedMessage id='CHECKOUT_FIELDS_CITY' /> <RequiredAsterisk required />
        <div className={`control ${isLoading ? 'is-loading' : ''}`}>
          <input
            id={id}
            readOnly={isLoading}
            className={`input ${errors.city ? 'is-danger' : ''}`}
            type='text'
            name={registerAs}
            ref={register}
            autoComplete='new-password' />
          <ErrorMessage name={registerAs} errors={errors}>
            {({ message }) => <p className='help is-danger'>{message}</p>}
          </ErrorMessage>
        </div>
      </label>
    </div>
  )
})))
