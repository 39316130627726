import React, { useEffect, useState, useContext } from 'react'
import uniqueId from 'lodash/uniqueId'
import CountryLangCtx from 'app/contexts/countryLang/CountryLangCtx'
import countries from 'app/constants/countries'
import { setLoading } from 'app/Store/actions/products'
import { useDispatch } from 'react-redux'
import { setAuthId } from 'app/Store/actions/cart'
import noop from 'lodash/noop'
import { getServiceBaseUrl } from 'app/Utils/serviceUtils'
import CircleLoader from 'components/loaders/CircleLoader/CircleLoader'

import './CardForm.scss'
import { useIntl } from 'react-intl'
import { setCustomErrors } from 'app/Store/slices/OrderForm'

export default function CardForm (props) {
  const dispatch = useDispatch()
  const { title, handlePaymentError, billingAddress, orderId } = props
  const [iFrameStyle, setIFrameStyle] = useState(null)
  const { country } = useContext(CountryLangCtx)
  const isUS = country === countries.us
  const [isLoading, setIsLoading] = useState(true)
  const intl = useIntl()

  // When payment form is submitted
  const [submitFuncName] = useState(uniqueId(`submitFunc${Date.now()}`))
  if (!window[submitFuncName]) {
    window[submitFuncName] = function (d) {
      console.log('submit called:', d)
    }
  }

  // success callback is data sya so
  const handlePaymentFromComplete = (data) => {
    if (data?.approved && data?.authId) {
      dispatch(setAuthId(data.authId))
      return
    }
    // handle error, refusals come back as 200s with resultCode: "Refused"
    dispatch(setCustomErrors([`${intl.formatMessage({ id: 'PAYMENT_ERROR_GENERIC_TITLE' })} ${intl.formatMessage({ id: 'PAYMENT_ERROR_GENERIC_DETAIL' })}`]))
  }

  const handlePaymentFormError = (err) => {
    handlePaymentError(err)
  }

  // check errors here
  const handlePaymentFormChange = noop

  const handlePaymentFormSubmit = (data) => {
    console.log('Payment form submitted.')
    dispatch(setLoading(true))
  }

  const handlePaymentFormLoaded = (data) => {
    const styleObj = { minHeight: `${Math.floor(data.height) + 20}px` }
    setIFrameStyle(styleObj)
  }

  const callbacks = {
    handleChange: handlePaymentFormChange,
    handleError: handlePaymentFormError,
    handlePaymentComplete: handlePaymentFromComplete,
    handleSubmit: handlePaymentFormSubmit,
    handleLoaded: handlePaymentFormLoaded,
    billingAddress
  }

  // new for related data
  // use as loading flag, should be called after session created
  const [initFuncName] = useState(uniqueId(`initFunc${Date.now()}`))
  if (!window[initFuncName]) {
    window[initFuncName] = function (config) {
      // this small timeout helps mitigate jank from trying to match the payment spinner
      setTimeout(() => {
        setIsLoading(false)
      }, 500)
      config.init(callbacks)
    }
  }

  useEffect(() => {
    // clean up global pollution - cpt planet.js
    return () => {
      window[initFuncName] = undefined
    }
  }, [])

  const paymentUrl = `${getServiceBaseUrl}/payment/iframe?orderId=${orderId}&onInit=${initFuncName}`

  return <div className='card-form'>
    {isLoading ? <div className='card-form__loader'><CircleLoader fill='#0777F6' opacity={1} speed={1.3} className='card-form__loader__inner' /></div> : null}
    {isUS ? <iframe style={iFrameStyle} className='card-form__inner' title={title} src={paymentUrl} /> : null}
  </div>
}
