import './OptionsModal.scss'

import React, { useEffect, useRef, useContext } from 'react'
import Swatch from 'components/Swatch/Swatch'
import OptionsModalOption from './OptionsModalOption'
import extractFullColorNumber from 'app/Utils/extractFullColorNumber.util'
import { useHistory } from 'react-router-dom'
import useEffectAfterMount from 'app/hooks/useEffectAfterMount'
import { isColorEde } from 'app/Utils/isColorFamily.util'
import { FormattedMessage } from 'react-intl'
import withUniqueId from 'app/HOCs/withUniqueId'
import ChooseSample from 'components/OptionsModal/ChooseSample'
import CountryLangCtx from 'app/contexts/countryLang/CountryLangCtx'
import countries from 'app/constants/countries'

function OptionsModal ({ id, color, onClose }) {
  const closeBtnRef = useRef()
  const { country } = useContext(CountryLangCtx)
  const isUS = country === countries.us

  useEffect(() => {
    if (closeBtnRef.current) closeBtnRef.current.focus()
  }, [closeBtnRef.current])

  useEffect(() => {
    const closeOnEsc = e => (e.keyCode === 27) ? onClose() : null
    document.addEventListener('keydown', closeOnEsc)
    return () => document.removeEventListener('keydown', closeOnEsc)
  }, [])

  const { location } = useHistory()

  useEffectAfterMount(() => {
    onClose()
  }, [location])

  const getModalClassNames = (isUs) => {
    return `modal-content has-background-white ${isUs ? '' : 'modal-content-small'}`
  }

  return (
    <section className='OptionsModal modal is-active' id='cartModal' aria-labelledby={`modalTitle_${id}`}>
      {/* eslint-disable-next-line */}
      <div className='OptionsModal__bg modal-background' onMouseUp={onClose} />
      <button ref={closeBtnRef} onClick={onClose} className='OptionsModal__close-btn modal-close is-large' aria-label='close' />
      <div className={getModalClassNames(isUS)}>
        <h1 id={`modalTitle_${id}`} className='has-text-centered has-text-left-tablet is-size-6 is-size-4-tablet pb-3'>
          <ChooseSample colorName={color.name} />
        </h1>
        <div className='is-flex is-justify-content-space-between is-align-content-center is-flex-wrap-wrap py-6' style={{}}>
          <Swatch
            className='swatch'
            color={color.hex}
            size={Swatch.SIZES.SMALL}
            name={color.name}
            number={extractFullColorNumber(color, true)}
            hasShadow={false}
            inside={() => isColorEde(color) ? <Swatch.Tag><FormattedMessage id='DESIGNER_COLOR_COLLECTION' /></Swatch.Tag> : null}
          >
            {isColorEde(color) ? <Swatch.Tag><FormattedMessage id='DESIGNER_COLOR_COLLECTION' /></Swatch.Tag> : null}
          </Swatch>
          {isUS
            ? <OptionsModalOption
            className='option'
            tabIndex='0'
            type={OptionsModalOption.TYPES.SWATCH}
            color={color}
          />
            : null}
          <OptionsModalOption
            className={`option ${!isUS ? 'option--ca' : ''}`}
            tabIndex='0'
            type={OptionsModalOption.TYPES.CHIP}
            color={color}
          />
        </div>
      </div>
    </section>
  )
}

export default withUniqueId(OptionsModal)
