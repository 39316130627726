import './DisclaimerFAQCombo.scss'

import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import VisitFaq from './VisitFAQ'

function DisclaimerFAQCombo ({ showFaq = true }) {
  return (
    <div className='DisclaimerFAQCombo is-size-7'>
      <div className='DisclaimerFAQCombo__disclaimer mb-2'>
        <p><FormattedMessage id='DISCLAIMER_COLOR_VARY' /></p>
      </div>
      {showFaq && <div className='DisclaimerFAQCombo__faq mb-2'>
        <p><VisitFaq /></p>
      </div>}
    </div>
  )
}

DisclaimerFAQCombo.propTypes = {
  showFaq: PropTypes.bool
}

export default DisclaimerFAQCombo
