import './CartBadge.scss'

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import useCart from 'app/hooks/useCart'
function CartBadge (props) {
  const { className = '', isSmall = false } = props
  const { count } = useCart()
  const [jiggle, setJiggle] = useState(false)

  useEffect(() => {
    if (count) {
      setJiggle(true)

      setTimeout(() => {
        setJiggle(false)
      }, 750)
    }
  }, [count])

  return (
    <div className={`CartBadge ${isSmall ? 'CartBadge--small' : ''} ${className}`}>
      <svg aria-hidden='true' className={`svg CartBadge__cart ${jiggle ? 'CartBadge--jiggle' : ''}`}
        width='60'
        height='60'
        viewBox='10 0 60 60'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g transform='scale(1.8) translate(10,10)'>
          <g className='basket'>
            <path
              d='M3.75202 6H20.1014C20.2113 6 20.3198 6.02413 20.4193 6.07068C20.5188 6.11724 20.6068 6.18508 20.6772 6.26941C20.7476 6.35374 20.7986 6.45251 20.8266 6.55872C20.8546 6.66494 20.859 6.77601 20.8393 6.88409L19.2039 15.8841C19.1725 16.0569 19.0814 16.2132 18.9466 16.3258C18.8117 16.4383 18.6417 16.5 18.466 16.5H6.29237C6.11679 16.5 5.94678 16.4384 5.81195 16.3259C5.67713 16.2135 5.58603 16.0573 5.55453 15.8846L3.18041 2.86545C3.14892 2.69273 3.05782 2.53652 2.92299 2.42406C2.78817 2.3116 2.61816 2.25 2.44258 2.25H0.75'
              stroke='black'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </g>
          <circle cx='6.75' cy='20.25' r='0.75' stroke='black' strokeWidth='1.5' />
          <circle cx='18' cy='20.25' r='0.75' stroke='black' strokeWidth='1.5' />
          {count
            ? (
            <g transform={`${count > 9 ? 'translate(18, 2)' : 'translate(22, 2)'}`}>
              <circle cx='0' cy='0' r='10' fill='#ffffff' stroke='white' strokeWidth='1' />
              <g className='CartBadge__count'>
                <text x='-8' y='7'>{`${count}`}</text>
              </g>
            </g>)
            : null}
        </g>
      </svg>
      <span className='is-sr-only'><FormattedMessage id='CART_TITLE' /></span>
    </div>
  )
}

CartBadge.propTypes = {
  className: PropTypes.string
}

export default CartBadge
