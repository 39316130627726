import React, { useContext, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import FAQElement from './FAQelement'
import './FAQ.scss'
import CountryLangCtx from 'app/contexts/countryLang/CountryLangCtx'
import countries from 'app/constants/countries'
import languages from 'app/constants/languages'

const buildLink = (href, txt) => <a href={href} target='_blank' rel="external noreferrer">{txt}</a>

function FAQ () {
  const [activeId, setActiveId] = useState('')
  const { country, language } = useContext(CountryLangCtx)
  const isUS = country === countries.us
  const isEnglish = language === languages.en

  const caStoreLocatorLink = isEnglish ? 'https://www.sherwin-williams.ca/en/find-a-store' : 'https://www.sherwin-williams.ca/fr/trouver-un-magasin/'
  const caEdeLink = isEnglish ? 'https://images.sherwin-williams.com/content_images/SW-PDF-CA-DIY-POPULAR-EN.pdf' : 'https://images.sherwin-williams.com/content_images/SW-PDF-CA-DIY-POPULAR-FR.pdf'

  // @todo these should probably come from the config... -RS
  const values = {
    acctExecCTA: txt => buildLink('https://www.sherwin-williams.com/architects-specifiers-designers/color/designer-account-executives', txt),
    buyColorToGoCTA: txt => buildLink('https://www.sherwin-williams.com/homeowners/products/color-to-go', txt),
    buyPaintCTA: txt => buildLink('https://www.sherwin-williams.com/homeowners/buy-paint', txt),
    colorSnapWebCTA: txt => buildLink('https://www.sherwin-williams.com/visualizer#/active', txt),
    colorSnapAppCTA: txt => buildLink('https://www.sherwin-williams.com/homeowners/color/try-on-colors/colorsnap-mobile', txt),
    colorSnapTabletCTA: txt => buildLink('https://www.sherwin-williams.com/homeowners/color/try-on-colors/color-snap-studio-for-ipad', txt),
    colorToGoCTA: txt => buildLink('https://www.sherwin-williams.com/homeowners/color/try-on-colors/color-to-go-shelf', txt),
    edeCTA: txt => buildLink(isUS ? 'https://www.sherwin-williams.com/en-us/color/color-collections/emerald-designer-edition' : caEdeLink, txt),
    ml: txt => <p className='ml-3'>{txt}</p>,
    mySwCTA: txt => buildLink('http://www.mySW.com', txt),
    stainsCTA: txt => buildLink('https://www.sherwin-williams.com/homeowners/color/find-and-explore-colors/stain-colors', txt),
    storeLocatorCTA: txt => buildLink(isUS ? 'https://www.sherwin-williams.com/store-locator' : caStoreLocatorLink, txt),
    termsAndConditions: txt => buildLink('https://www.sherwin-williams.com/terms-and-conditions', txt)
  }

  return (
    <div className='FAQ has-text-left'>
      <dl className='FAQ__list'>
        {[
          'WHEN_WILL_MY_CHIPS_ARRIVE',
          'HOW_MANY_ORDERS',
          'WHAT_SIZE_ARE_THEY',
          'CAN_I_ORDER_ONLINE',
          'HOW_TO_SEE_COLORS_ONLINE',
          'CAN_I_ORDER_EDE',
          'CAN_I_ORDER_STAINS',
          'REPLACEMENT_PRO_COLOR_TOOLS',
          'WHAT_SHEEN',
          'CAN_I_ORDER_PAINT_SAMPLES',
          'CHIPS_DONT_MATCH_SCREEN',
          'CAN_I_EXPEDITE_ORDER',
          'AVAILABLE_OUTSIDE_US',
          'INCOMPLETE_ORDER_WHAT_HAPPENED',
          'HOW_TO_GET_STARTED',
          'CAN_I_CHANGE_ORDER',
          'HOW_MUCH_FOR_SHIPPING',
          'RETURN_POLICY',
          'RETURN_TO_STORE',
          'PEEL_AND_STICK_REMOVABLE',
          'WHEN_CREDIT_CARD_CHARGED',
          'FORMS_OF_PAYMENT',
          'PEEL_AND_STICK_SHIPPING',
          'CAN_I_MIX',
          'HOW_WERE_COLORS_SELECTED',
          'WHAT_IF_I_ORDER'
        ].filter(item => {
          if (isUS) {
            return true
          }

          return [
            'CAN_I_CHANGE_ORDER',
            'HOW_MUCH_FOR_SHIPPING',
            'RETURN_POLICY',
            'RETURN_TO_STORE',
            'PEEL_AND_STICK_REMOVABLE',
            'WHEN_CREDIT_CARD_CHARGED',
            'FORMS_OF_PAYMENT',
            'PEEL_AND_STICK_SHIPPING',
            'CAN_I_MIX',
            'HOW_WERE_COLORS_SELECTED',
            'WHAT_IF_I_ORDER'
          ].indexOf(item) === -1
        }).map((id, i) => (
          <FAQElement
            key={i}
            id={i}
            question={<FormattedMessage id={`FAQ_${id}_Q`} values={values} />}
            answer={<FormattedMessage id={`FAQ_${id}_A`} values={values} />}
            answerShowing={activeId === i}
            onToggle={id => setActiveId(activeId === id ? '' : id)}
          />
        ))}
      </dl>
    </div>
  )
}

export default FAQ
