import './Tooltip.scss'
import React, { useState, useEffect } from 'react'
import SVG from 'react-inlinesvg'
import CloseImage from 'src/svg/close.svg'
import { FormattedMessage } from 'react-intl'

function Tooltip (props) {
  const {
    topOffset = 0,
    leftOffset = 0,
    colors
  } = props
  const [isToggled, setIsToggled] = useState(false)

  const handleToggle = () => {
    setIsToggled(!isToggled)
  }

  useEffect(() => {
    const closeTooltip = (e) => {
      const isTooltipButton = !!e.target.dataset?.tooltipButton
      if (!isTooltipButton) {
        setIsToggled(false)
      }
    }
    window.addEventListener('click', closeTooltip)
    return () => {
      window.removeEventListener('click', closeTooltip)
    }
  }, [])

  return (
    <div className='Tooltip--wrapper' >
      <button onClick={handleToggle} className='Tooltip--toggle-btn button' data-tooltip-button='1'>
        <span className='Tooltip--heading'><FormattedMessage id='TOOLTIP_INSIDE' />&nbsp;</span>
        <div className='Tooltip--icon'>?</div>
      </button>
      {isToggled && (
        <div
          className='Tooltip--tip'
          style={{
            top: topOffset,
            left: leftOffset
          }}
        >
          <div className='Tooltip--content'>
            <button onClick={handleToggle} className='Tooltip--close-btn is-large'><SVG aria-hidden='true' className='svg' src={CloseImage} /></button>
            <p className='tooltip-text'><FormattedMessage id='TOOLTIP_INCLUDES' />:</p>
            { colors.map((color) =>
              <div className='tooltip-info' key={color.id}>
                <span className='Tooltip--swatch-petal' style={{ backgroundColor: color.hex }} />
                <span className='kitItem-color'><span style={{ fontWeight: '600' }}>{ color.name }</span> &nbsp;{ color.brandKey }&nbsp;{ color.colorNumber }</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Tooltip
