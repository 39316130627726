import Countries from 'app/constants/countries'
import { PS_BUNDLE_ITEM_TYPE } from 'app/constants/product'

export function adjustCatalog (products, country) {
  if (country === Countries.us) {
    return products
  }
  // remove siriano products
  return products.filter((item) => item.itemType !== PS_BUNDLE_ITEM_TYPE)
}
