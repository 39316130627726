import React, { useEffect, useCallback, useRef, useState, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory, Link } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import PropTypes from 'prop-types'
import find from 'lodash/find'
import debounce from 'lodash/debounce'
import includes from 'lodash/includes'
import { useForm } from 'react-hook-form'
import { GoogleApiWrapper } from 'google-maps-react'
import without from 'lodash/without'
import CircleLoader from 'components/loaders/CircleLoader/CircleLoader'
import ArrowIcon from 'src/svg/arrow-right.svg'
import useCart from 'app/hooks/useCart'
import usePrependPath from 'app/hooks/usePrependPath'
import CountryLangCtx from 'app/contexts/countryLang/CountryLangCtx'
import Recaptcha from '../Recaptcha/Recaptcha'
import axios from 'axios'
import { Address, Address2, City, Country, Email, FirstName, LastName, NewsletterOptin, Postal, State } from './Fields'
import { setCustomErrors, addCustomError, setRecaptchaError, setNoOrderError, setEmptyCartError, setIsLoading, resetState } from 'app/Store/slices/OrderForm'
import { updateOrderByOrderId, submitOrder } from 'app/Utils/orderSubmission.util'
import { INTENTIONAL_CANCELLATION } from 'app/Utils/ajax.util'
import extractUser from 'app/Utils/user.util'
import { clearTokensAndIDs, setDeliveryFee } from 'app/Store/actions/cart'

import './OrderForm.scss'
import countries from 'app/constants/countries'
import states from 'app/constants/states'
import validationPatterns from 'app/constants/validationPatterns'
import labels from 'app/constants/adobeAnalytics'

const BAD_ADDRESS_ERROR = 'Chosen location does not contain a valid address. Please verify your location.'

const CancelToken = axios.CancelToken

function getAddressComponent (components, type, version = 'long_name') {
  const addressComponent = find(components, c => includes(c.types, type))
  return addressComponent ? addressComponent[version] : ''
}

function OrderForm ({ google, children }) {
  const intl = useIntl()
  const cancelToken = useRef(CancelToken.source())
  const { register, handleSubmit, errors, setValue, triggerValidation, reset } =
      useForm({
        defaultValues: {
          newsletterOptIn: true
        }
      })
  const dispatch = useDispatch()
  const history = useHistory()
  const { country } = useContext(CountryLangCtx)
  const { orderId, load, nonZeroItems, subtotal, loading: cartLoading, empty, user, errors: cartErrors, setUser } = useCart()
  const prependPath = usePrependPath()
  const { customErrors, recaptchaEnabled, recaptchaResponse, recaptchaError, emptyCartError, isLoading, noOrderError } = useSelector(state => state.orderForm)
  const [submissionDisabled, setSubmissionDisabled] = useState(false)
  const criticalErrorsRef = useRef([])
  criticalErrorsRef.current = [
    emptyCartError,
    noOrderError
  ].filter(Boolean)

  const isUS = country === countries.us

  useEffect(() => {
    reset() // reset the form

    // reset related redux on arrive at page
    dispatch(resetState())

    return () => {
      recaptchaRef.current = undefined
      errorRef.current = undefined
      autocompleteRef.current = undefined
      criticalErrorsRef.current = undefined
      cancelToken.current.cancel(INTENTIONAL_CANCELLATION)
    }
  }, [])

  useEffect(() => {
    setSubmissionDisabled([
      emptyCartError,
      noOrderError,
      recaptchaError,
      isLoading,
      cartLoading,
      ...(cartErrors || [])
    ].filter(Boolean).length > 0)
  }, [emptyCartError, noOrderError, recaptchaError, isLoading, cartLoading, cartErrors])

  // toggle button text based on whether we are going to the payment page or checking out from here
  const SHOULD_PROCEED_TO_PAYMENT = isUS ? (subtotal > 0) : true
  const CHECKOUT_BUTTON_TEXT = (SHOULD_PROCEED_TO_PAYMENT) ? 'CHECKOUT_CTA_PROCEED_TO_PAYMENT' : 'CHECKOUT_CTA_PLACE_ORDER'

  const scrollToError = useCallback(debounce(() => {
    const { current } = errorRef
    if (current) {
      current.focus()
    }
  }, 100), [])

  useEffect(() => {
    if ([
      ...(cartErrors ?? []),
      ...(customErrors ?? []),
      emptyCartError,
      recaptchaError,
      noOrderError
    ].filter(Boolean).length) {
      scrollToError()
    }
  }, [cartErrors, customErrors, emptyCartError, recaptchaError, noOrderError])

  const hasLoaded = useRef(false)
  useEffect(() => {
    if (orderId && nonZeroItems.length && !hasLoaded.current) {
      load(intl)
      hasLoaded.current = true
    }
  }, [orderId, nonZeroItems])

  useEffect(() => {
    // check for order ID, if not throw error and do not sync
    // custom error with link back to homepage
    // do the same thing on payment page? maybe?
    dispatch(setNoOrderError(!orderId))
  }, [orderId])

  useEffect(() => {
    dispatch(setEmptyCartError(!nonZeroItems.length))
  }, [nonZeroItems])

  const removeCustomErrors = useCallback((removedErrors = []) => {
    const newErrors = without(customErrors, ...removedErrors)
    if (newErrors.length) return dispatch(setCustomErrors(newErrors))
    dispatch(setCustomErrors())
  }, [customErrors])

  const recaptchaRef = useRef()
  const errorRef = useRef()
  const autocompleteRef = useRef()

  const registerAddressRef = useCallback((el, more) => {
    if (register) {
      autocompleteRef.current = el
      register(el, {
        required: intl.formatMessage({ id: 'CHECKOUT_FIELDS_ADDRESS_ERROR_REQUIRED' })
      })
      // FORCE new-password autocomplete attr so the browser won't show its autocomplete box
      setTimeout(() => autocompleteRef.current && autocompleteRef.current.setAttribute('autocomplete', 'new-password'), 500)
    }
  }, [register])

  const resetRecaptcha = () => {
    if (recaptchaRef.current && recaptchaRef.current.reset) {
      recaptchaRef.current.reset()
    }
  }

  const onSubmit = (formData) => {
    // prevent add'l submits while already submitting
    if (isLoading) {
      return
    }

    dispatch(setCustomErrors())
    dispatch(setEmptyCartError())

    if (!nonZeroItems.length) {
      dispatch(setEmptyCartError(true))
      return
    }
    // handle Quebec province
    if (!isUS && (formData.state?.toLowerCase().trim() === 'quebec' || formData.state?.toLowerCase().trim() === 'qc')) {
      const qcError = intl.formatMessage({ id: 'ERROR_NO_QC' })
      setValue('address1', null)
      dispatch(addCustomError([qcError]))
      console.error(qcError)
      return
    }

    if (((isUS && !SHOULD_PROCEED_TO_PAYMENT) || (!isUS && SHOULD_PROCEED_TO_PAYMENT)) &&
      recaptchaEnabled && !recaptchaResponse) {
      dispatch(setRecaptchaError(true))
      return
    }

    // start loader
    dispatch(setIsLoading(true))
    // start order submission process
    updateOrderByOrderId(orderId, formData, cancelToken.current.token, intl)
      .then(data => {
        if (!data) return // indicates user-cancelled operation

        setUser(extractUser(data))

        if (criticalErrorsRef.current?.length) {
          // we have errors! don't submit.
          throw new Error(intl.formatMessage({ id: 'GENERIC_ERROR' }))
        }

        // take the US user to the payment page if we have nonZero cost items
        if (SHOULD_PROCEED_TO_PAYMENT && isUS) {
          history.push(prependPath('payment'))
          // handle Colorado delivery fee, this will fail gracefully
          dispatch(setDeliveryFee(data?.deliveryFee))
          return
        }

        // handle Quebec address entered manually
        if (!isUS && (data.state?.toLowerCase().trim() === 'quebec' || data.state?.toLowerCase().trim() === 'qc')) {
          const qcError = intl.formatMessage({ id: 'ERROR_NO_QC' })
          setValue('address1', null)
          dispatch(addCustomError([qcError]))
          console.error(qcError)
          dispatch(setIsLoading(false))
          return
        }

        // submit the order if we aren't taking the user to the payment page
        return submitOrder(orderId, cancelToken.current.token, '', intl, false)
          .then(data => {
            dispatch(setIsLoading(false))

            if (!data) return // indicates user-cancelled operation

            empty(orderId) // clear the cart

            // redirect to confirmation page with the orderNumber
            // Adobe Analytics :: Store the order details for KPIs on Confirmation page
            const _localStorageSupport = window.localStorage
            if (_localStorageSupport) {
              const submittedOrder = {
                orderId: data.orderId
              }
              const lineItems = []
              data?.lineItems?.forEach((item) => {
                const itemObj = {
                  sku: item.sku,
                  description: item.description,
                  skuType: item.skuType,
                  price: item.price,
                  quantity: item.quantity,
                  colorNumber: item.colorNumber,
                  totalTax: item.totalTax,
                  totalAdjustment: item.totalAdjustment
                }
                lineItems.push(itemObj)
              })
              submittedOrder.lineItems = lineItems
              window.localStorage.setItem(labels.localStorageKeyOrder, JSON.stringify(submittedOrder))
            }
            // End Adobe Analytics :: Store the order details for KPIs on Confirmation page
            history.replace(`${prependPath('confirmation')}?orderNumber=${orderId}`)
          })
      })
      .catch(({ errors, critical }) => {
        if (critical) dispatch(clearTokensAndIDs())
        dispatch(setCustomErrors(errors))
        resetRecaptcha()
        dispatch(setIsLoading(false))
      })
  }

  // populating the inputs if the user is returning back from the payment page
  useEffect(() => {
    if (user) {
      setValue('firstName', user.firstName)
      setValue('lastName', user.lastName)
      setValue('email', user.email)
      setValue('address1', user.address1)
      setValue('address2', user.address2)
      setValue('city', user.city)
      setValue('country', user.country || country.toUpperCase())
      setValue('postal', user.postal)
      setValue('state', user.state)
    }
  }, [user])

  useEffect(() => {
    if (autocompleteRef && autocompleteRef.current) {
      const autocompleteOptions = {
        componentRestrictions: { country },
        types: ['address']
      }

      const autocomplete = new google.maps.places.Autocomplete(autocompleteRef.current, autocompleteOptions)

      const handlePlaceChanged = () => {
        const place = autocomplete.getPlace()
        const streetNumber = getAddressComponent(place.address_components, 'street_number', 'long_name')
        const streetName = getAddressComponent(place.address_components, 'route', 'long_name')
        const city = getAddressComponent(place.address_components, 'locality', 'long_name')
        const state = getAddressComponent(place.address_components, 'administrative_area_level_1', 'short_name')
        const postalCode = getAddressComponent(place.address_components, 'postal_code', 'long_name')

        if (!isUS && state?.toLowerCase().trim() === states.ca.qc) {
          const qcError = intl.formatMessage({ id: 'ERROR_NO_QC' })
          setValue('address1', null)
          dispatch(addCustomError([qcError]))
          console.error(qcError)
          return
        }

        if (!streetNumber || !streetName) {
          setValue('address1', null)
          setValue('city', null)
          setValue('state', null)
          setValue('postal', null)
          handleSubmit()
          dispatch(addCustomError(BAD_ADDRESS_ERROR))
        } else {
          setValue('address1', `${streetNumber} ${streetName}`)
          setValue('city', city)
          setValue('state', state)
          setValue('postal', postalCode)
          removeCustomErrors([BAD_ADDRESS_ERROR])
        }

        triggerValidation([
          'address1',
          'city',
          'state',
          'postal'
        ])
      }

      autocomplete.setFields(['address_components'])

      const autocompleteLsr = google.maps.event.addListener(autocomplete, 'place_changed', handlePlaceChanged)

      return () => {
        google.maps.event.removeListener(autocompleteLsr)
        google.maps.event.clearInstanceListeners(autocomplete)
      }
    }
  }, [autocompleteRef, country])
  return (
    <div className='OrderForm'>
      <h1 className='subtitle'><FormattedMessage id='PAGE_CHECKOUT_YOUR_INFO_HEADING' /></h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* OVERALL ERROR MESSAGE */}
        {(customErrors?.length || recaptchaError || emptyCartError || cartErrors?.length || noOrderError)
          ? (
            <article className='message is-danger' aria-live='assertive' tabIndex='0' role='alert' ref={errorRef}>
              <header className='message-header'>
                <p><FormattedMessage id='CHECKOUT_ERRORS_HEADING' /></p>
              </header>
              <div className='message-body'>
                <div className='content'>
                  <ul className='mt-0'>
                    {noOrderError
                      ? <li>
                        <FormattedMessage id='CHECKOUT_NO_ORDER_MSG' values={{
                          cartCTA: chunk => <><br /><Link className='has-text-link' to={prependPath('cart')}>{chunk}</Link></>
                        }} />
                      </li>
                      : emptyCartError
                        ? <li>
                          <FormattedMessage id='CHECKOUT_EMPTY_CART_MSG' values={{
                            continueShoppingText: chunk => <p className='mb-4'>{chunk}</p>,
                            continueShoppingCTA: chunk => <Link className='button is-dark' to={prependPath()}>{chunk}</Link>
                          }} />
                        </li>
                        : null}
                    {customErrors?.map((err, i) => (
                      // TODO: These will need to be keys that correspond to translations
                      <li key={i}>{err}</li>
                    ))}
                    {cartErrors && cartErrors.map((err) => (
                      <li key={err}>{err}</li>
                    ))}
                    {recaptchaError && <li><FormattedMessage id='CHECKOUT_ERRORS_SOLVE_RECAPTCHA' /></li>}
                  </ul>
                </div>
              </div>
            </article>
            )
          : null}
        {/* END OVERALL ERROR MESSAGE */}

        <div className='columns'>
          <div className='column'>
            <FirstName errors={errors} register={register} />
          </div>
        </div>
        <div className='columns'>

          <div className='column'>
            <LastName errors={errors} register={register} />
          </div>
        </div>
        <div className='columns'>
          <div className='column'>
            <Email errors={errors} register={register} />
          </div>
        </div>
        <h1 className='subtitle mt-6'><FormattedMessage id='SHIPPING_ADDRESS' /></h1>
        <div className='columns'>
          <div className='column'>
            <Address
              registerAs='address1'
              errors={errors}
              register={registerAddressRef}
              placeholder={intl.formatMessage({ id: 'PLACEHOLDER_ADDRESS' })}
              onKeyDown={e => e.keyCode === 13 && e.preventDefault()} />
          </div>
          <div className='column is-one-third-tablet'>
            <Address2 registerAs='address2' errors={errors} register={register({
              pattern: {
                value: validationPatterns.ADDRESS2,
                message: intl.formatMessage({ id: 'CHECKOUT_FIELDS_ADDRESS2_ERROR_INVALID' })
              }
            })} />
          </div>
        </div>
        <div className='columns'>
          <div className='column'>
            <City registerAs='city' errors={errors} register={register({
              required: intl.formatMessage({ id: 'CHECKOUT_FIELDS_CITY_ERROR_REQUIRED' })
            })} />
          </div>
        </div>
        <div className='columns is-multiline'>
          <div className='column is-full is-one-third-widescreen'>
            <Country registerAs='country' errors={errors} register={register({ required: intl.formatMessage({ id: 'CHECKOUT_FIELDS_COUNTRY_ERROR_REQUIRED' }) })} />
          </div>
          <div className='column is-two-thirds-widescreen'>
            <div className='columns is-mobile'>
              <div className='column is-half-mobile'>
                <State registerAs='state' errors={errors} register={register({
                  required: intl.formatMessage({ id: 'CHECKOUT_FIELDS_STATE_ERROR_REQUIRED' }),
                  pattern: {
                    value: (country.toLowerCase() === countries.ca
                      ? validationPatterns.STATE_CA
                      : null
                    ),
                    message: intl.formatMessage({ id: 'CHECKOUT_FIELDS_STATE_ERROR_REQUIRED' })
                  }
                })} />
              </div>
              <div className='column is-half-mobile'>
                <Postal registerAs='postal' errors={errors} register={register({
                  required: intl.formatMessage({ id: 'CHECKOUT_FIELDS_POSTAL_ERROR_REQUIRED' }),
                  pattern: {
                    value: (country.toLowerCase() === countries.ca
                      ? validationPatterns.POSTAL_CA
                      : validationPatterns.POSTAL_US
                    ),
                    message: intl.formatMessage({ id: 'CHECKOUT_FIELDS_POSTAL_ERROR_INVALID' })
                  }
                })} />
              </div>
            </div>
          </div>
        </div>

        <div>
          {children}
        </div>

        <NewsletterOptin register={register} />
        {/* Render recaptcha for Canadian users and US users that have only free items */}
        {(isUS && !SHOULD_PROCEED_TO_PAYMENT) || !isUS
          ? <div className='field mb-5'>
          <Recaptcha ref={recaptchaRef} />
        </div>
          : null}
        <div className='field mb-5'>
          <div className='is-flex is-flex-direction-row is-flex-wrap-wrap-reverse is-justify-content-flex-end'>
            <span className='ml-3 mb-3'>
              {isLoading
                ? <button disabled aria-live='assertive' className='button is-static is-text'>
                  <span className='icon'><CircleLoader /></span>
                  <span>Processing...</span>
                </button>
                : <button disabled={submissionDisabled} className={`button is-dark is-uppercase ${cartLoading ? 'is-loading' : ''}`} type='submit'><FormattedMessage id={CHECKOUT_BUTTON_TEXT} /></button>}
            </span>
            <Link style={{ order: '-1' }} className='button ml-3 mb-3' to={prependPath('cart')}>
              <span className='icon is-small'>
                <SVG aria-hidden='true' className='svg-outline is-flip-x' src={ArrowIcon} />
              </span>
              <span className='is-uppercase'><FormattedMessage id='BACK_TO_CART' /></span>
            </Link>
          </div>
        </div>
      </form>
    </div>
  )
}

OrderForm.propTypes = {
  google: PropTypes.object
}

export default GoogleApiWrapper({ client: 'gme-sherwinwilliamscompany' })(OrderForm)
