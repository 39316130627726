import { VERSION } from 'app/constants/cart'
import isEmpty from 'lodash/isEmpty'

const SESSION_STORAGE = window.sessionStorage
const LOCAL_STORAGE = window.localStorage

const readPersistentWorker = (dataSource, key) => {
  try {
    const serializedObj = dataSource.getItem(key)

    if (isEmpty(serializedObj)) {
      return undefined
    }

    const { version, data } = JSON.parse(serializedObj)

    if (version !== VERSION) {
      writePersistentWorker(dataSource, key) // wipe out data, it's stale or invalid
      return undefined
    }

    return data
  } catch (err) {
    console.warn(`Reading from ${dataSource} failed.`, err)
    return undefined
  }
}

const writePersistentWorker = (dataDestination, key, data) => {
  try {
    const obj = {
      version: VERSION,
      data
    }

    const serializedObj = JSON.stringify(obj)

    dataDestination.setItem(key, serializedObj)
  } catch (err) {
    console.warn(`Saving to ${dataDestination} failed.`, err)
  }
}

// local storage
export const readPersistentLocal = (key) => readPersistentWorker(LOCAL_STORAGE, key)
export const writePersistentLocal = (key, data) => writePersistentWorker(LOCAL_STORAGE, key, data)

// session storage
export const readPersistentSession = (key) => readPersistentWorker(SESSION_STORAGE, key)
export const writePersistentSession = (key, data) => writePersistentWorker(SESSION_STORAGE, key, data)
