import React from 'react'
import 'app/components/Swatch/Swatch.scss'
import './DeepLinkCartModal.scss'

export default function Item (props) {
  const { description, title, color, size, isDark } = props

  return (
    <>
      <div
        style={size !== 'sm' ? { backgroundColor: color } : null}
        className='item'
      >
        <div className='color-preview' style={{ backgroundColor: color }} />
        <div className='text-wrapper' style={isDark && size !== 'sm' ? { color: '#ffffff' } : null}>
          <div className='item-title'>{title}</div>
          <div className='description'>{description}</div>
        </div>
      </div>
    </>
  )
}
