import {
  OPEN_SNACKBAR,
  CLOSE_SNACKBAR
} from '../actions/snackbar'

const initialState = {
  open: false,
  variant: undefined,
  message: ''
}

export const snackbar = (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_SNACKBAR: {
      const { variant, message, action, timeout } = payload

      return {
        ...state,
        open: true,
        variant,
        message,
        action,
        timeout
      }
    }
    case CLOSE_SNACKBAR: return { open: false, variant: state.variant }
    default: return state
  }
}
