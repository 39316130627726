import React from 'react'
import { FormattedMessage } from 'react-intl'

function ChooseSample (props) {
  const { colorName } = props

  return <FormattedMessage id='MODAL_OPTIONS_CHOOSE_SAMPLE' values={{
    span: () => {
      return <span className='has-text-weight-bold'>{` ${colorName}`}</span>
    }
  }} />
}

export default ChooseSample
