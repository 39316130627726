import React from 'react'
import './TextButton.scss'

export const BASE_CLASS = 'text-button'

function TextButton (props) {
  const { onClick, to, className, ...other } = props

  const _className = `${BASE_CLASS} ${className || ''}`

  return (
    onClick
      ? (
        <button type='button' className={_className} onClick={onClick} {...other}>
          {props.children}
        </button>
        )
      : (
        <div className={_className} {...other}>
          {props.children}
        </div>
        )
  )
}

export default React.memo(TextButton)
