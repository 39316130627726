import React from 'react'
import SnackbarCtx from 'app/contexts/snackbar/SnackbarCtx'
import Snackbar from 'components/Snackbar/Snackbar'
import { useDispatch, useSelector } from 'react-redux'
import {
  closeSnackbar as actionCloseSnackbar,
  openSnackbar as actionOpenSnackbar
} from 'app/Store/actions/snackbar'

export default function withSnackbar (WrappedComponent) {
  return (props) => {
    const dispatch = useDispatch()
    const { open, message, variant, action, timeout } = useSelector(state => state.snackbar)

    function openSnackbar ({ message, action, timeout, variant }) {
      dispatch(actionOpenSnackbar({
        message,
        action,
        timeout,
        variant
      }))
    }

    function closeSnackbar () {
      dispatch(actionCloseSnackbar())
    }

    return <SnackbarCtx.Provider value={{ openSnackbar, closeSnackbar }}>
      <WrappedComponent {...props} />
      <Snackbar
        open={open}
        message={message}
        handleClose={closeSnackbar}
        autoHideDuration={timeout}
        action={action}
        variant={variant}
      />
    </SnackbarCtx.Provider>
  }
}
