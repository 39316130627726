export const NAV_TO_PS = 'NAV_TO_PS'

export const setNavToPeelAndStick = (shouldNav) => {
  return {
    type: NAV_TO_PS,
    payload: shouldNav
  }
}

export const NAV_TO_TOP_50 = 'NAV_TO_TOP_50'

export const setNavToTop50 = (shouldNav) => {
  return {
    type: NAV_TO_TOP_50,
    payload: shouldNav
  }
}

export const NAV_TO_CHRISTIAN_SIRIANO = 'NAV_TO_CHRISTIAN_SIRIANO'
export const setNavToChristianSiriano = (shouldNav) => {
  return {
    type: NAV_TO_CHRISTIAN_SIRIANO,
    payload: shouldNav
  }
}
