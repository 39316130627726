import productIsSwatch from './productIsSwatch.util'
import { PS_BUNDLE_ITEM_TYPE } from 'app/constants/product'

export default function extractSwatches (items = []) {
  return items.filter(productIsSwatch)
}

export function extractSwatchesBundles (items = []) {
  return items.filter((item) => item.itemType === PS_BUNDLE_ITEM_TYPE)
}
