import React, { useEffect, useRef, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setRecaptchaResponse, setRecaptchaError, checkIfRecaptchaEnabled } from 'app/Store/slices/OrderForm'

const getRecaptchaKey = (hostname) => {
  const h = hostname || window.location.hostname

  const devKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
  const prodKey = '6Lf28-MUAAAAANHK-vm_h7ngzNx2z5wBPf2QXYVx'

  const prodEnvs = [
    'samples.sherwin-williams.ca',
    'samples.sherwin-williams.com',
    'colorchips.sherwin-williams.com',
    'colorchipscanada.ebus.swaws',
    'samples.ebus.swaws',
    'colorchips.ebus.swaws'
  ]

  const qaEnvs = [
    'qa-colorchipscanada.ebus.swaws',
    'qa-colorchips.ebus.swaws',
    'qa-samples.sherwin-williams.ca',
    'qa-samples.sherwin-williams.com'
  ]

  if (prodEnvs.indexOf(h) > -1 || qaEnvs.indexOf(h) > -1) {
    return prodKey
  }

  return devKey
}

const Recaptcha = forwardRef((props, ref) => {
  const recaptchaEmbedEl = useRef()
  const recaptchaRef = useRef()
  const dispatch = useDispatch()
  const { recaptchaEnabled } = useSelector(state => state.orderForm)

  useEffect(() => { dispatch(checkIfRecaptchaEnabled()) }, [])

  useEffect(() => {
    // don't proceed if captcha isn't enabled or recaptchaRef has already been set
    if (!recaptchaEnabled || recaptchaRef.current) return

    const i = setInterval(() => {
      if (window.grecaptcha && window.grecaptcha.render && window.grecaptcha.reset && !recaptchaRef.current && recaptchaEmbedEl.current) {
        clearInterval(i)
        recaptchaRef.current = window.grecaptcha

        recaptchaRef.current.render(recaptchaEmbedEl.current, {
          sitekey: getRecaptchaKey(),
          callback: (response) => {
            dispatch(setRecaptchaResponse(response))
            dispatch(setRecaptchaError(false))
          },
          'error-callback': () => {
            dispatch(setRecaptchaResponse())
            dispatch(setRecaptchaError(true))
          },
          'expired-callback': () => {
            dispatch(setRecaptchaResponse())
            dispatch(setRecaptchaError(true))
            // reset the recaptcha on expiration so that red text isn't inside
            // but only if we know we've communicated this to the outside
            const timeoutRef = setTimeout(() => recaptchaRef.current && recaptchaRef.current.reset(), 250)
            return () => clearTimeout(timeoutRef)
          }
        })
      }
    }, 500)

    return () => { clearInterval(i) } // clear interval
  }, [recaptchaEnabled])

  useEffect(() => {
    if (ref && recaptchaRef.current && recaptchaRef.current.reset) {
      ref.current = {
        reset: recaptchaRef.current.reset
      }
    }
  }, [ref, recaptchaRef.current])

  return recaptchaEnabled && <div ref={recaptchaEmbedEl} />
})

export default Recaptcha
