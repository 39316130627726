import './SwatchScroller.scss'
import React, { useCallback, useLayoutEffect, useRef, useState, useMemo, useEffect } from 'react'
import Swatch from 'components/Swatch/Swatch'
import ArrowBtn from 'components/ArrowBtn/ArrowBtn'
import { AutoSizer } from 'react-virtualized'
import { useSelector } from 'react-redux'
import useCart from 'app/hooks/useCart'
import HorizontalLoader from 'components/loaders/HorizonalLoader/HorizontalLoader'
import extractFullColorNumber from 'app/Utils/extractFullColorNumber.util'
import { FormattedMessage } from 'react-intl'

function isWide (w = 0) {
  return w >= 729
}

const listMainProps = {
  className: 'SwatchScroller'
}

const listScrollerProps = {
  className: 'SwatchScroller__list-scroller'
}

const listItemWrapProps = {
  className: 'SwatchScroller__list-scroller__item-wrap'
}

const listItemButtonProps = {
  className: 'SwatchScroller__list-scroller__item-wrap__btn'
}

const listItemProps = {
  className: 'SwatchScroller__list-scroller__item-wrap__item'
}

function SwatchScroller () {
  const scroller = useRef()
  const scrollerId = 'swatchScroller'
  const [showArrowBtns, setShowArrowBtns] = useState(false)
  const [arrowDisabledFwd, setArrowDisabledFwd] = useState(false)
  const [arrowDisabledRev, setArrowDisabledRev] = useState(false)
  const { availableColors, loadingColors } = useSelector(state => state.cart)
  const [isWideView, setIsWideView] = useState(true)

  const colors = useMemo(() => availableColors.length
    ? ['7029', '7015', '0055', '7008', '7005', '7036', '6204', '7004', '9109', '7016']
        .map(cNumber => availableColors.find(c => c.colorNumber === cNumber))
        .slice(0, 10)
        .filter(Boolean)
    : [], [availableColors])

  const setScroll = useCallback(function setScroll (move = 0) {
    const { scrollWidth = 0, offsetWidth = 0, scrollLeft = 0 } = scroller.current
    const pageWidth = offsetWidth
    const maxScroll = scrollWidth - offsetWidth
    const minScroll = 0
    const newScroll = Math.max(minScroll, Math.min(scrollLeft + (pageWidth * move), maxScroll))
    scroller.current.scrollTo({ top: 0, left: newScroll, behavior: 'smooth' })
  }, [scroller])

  const handleScroll = useCallback(function handleScroll () {
    if (scroller.current) {
      const { scrollWidth = 0, offsetWidth = 0, scrollLeft = 0 } = scroller.current
      const scrollPct = scrollLeft / (scrollWidth - offsetWidth)
      setArrowDisabledFwd(scrollPct === 1)
      setArrowDisabledRev(scrollPct === 0)
    }
  }, [])

  const handleResize = useCallback(function handleResize () {
    if (scroller.current) {
      const { scrollWidth = 0, offsetWidth = 0 } = scroller.current
      setIsWideView(isWide(offsetWidth))
      setShowArrowBtns(scrollWidth > offsetWidth)
      handleScroll()
    }
  }, [scroller.current, handleScroll])

  useLayoutEffect(handleResize, [])

  useEffect(() => {
    if (scroller.current) {
      scroller.current && scroller.current.addEventListener('scroll', handleScroll)
      setIsWideView(isWide(scroller.current.offsetWidth))
      return () => scroller.current && scroller.current.removeEventListener('scroll', handleScroll)
    }
  }, [scroller.current])

  const { showOptionsModalFor } = useCart()

  return (
    <section {...listMainProps}>
      { loadingColors
        ? <div className='section'><div className='container has-text-centered'><HorizontalLoader className='is-margin-auto-h' /></div></div>
        : !colors || colors.length === 0
            ? null
            : <>
            <header className='section pb-0'>
              <div className='container'>
                <div className='mb-5'>
                  <div className='level-left pr-4 is-flex-shrink-1'>
                    <div className='level-item is-flex-shrink-1'>
                      <h1 className='mb-0 has-text-weight-semibold is-size-4-mobile is-size-4-tablet is-size-3-widescreen'>
                        <FormattedMessage id='SWATCH_SCROLLER_SHOP_POP' />
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <AutoSizer disableHeight style={{ width: '100%', overflow: 'hidden' }} onResize={handleResize}>{() => (
              <>
                <div {...listScrollerProps} id={scrollerId} ref={scroller}>
                  <ol {...listItemWrapProps}>
                    {colors.map((color, i, arr) => {
                      const last = i === arr.length - 1

                      return (
                        <li key={i} {...listItemProps} className={`${listItemProps.className} ${last ? 'last' : ''}`}>
                          <button {...listItemButtonProps} onClick={() => showOptionsModalFor({ id: color.id })}>
                            <Swatch
                              color={color.hex}
                              size={isWideView ? Swatch.SIZES.DEFAULT : Swatch.SIZES.SMALL}
                              name={color.name}
                              number={extractFullColorNumber(color, true)}
                              inside={() => <>
                                <Swatch.Cta RenderEl={({ children, ...other }) => <div {...other}>{children}</div>} />
                              </>} />
                          </button>
                        </li>
                      )
                    })}
                  </ol>
                </div>
              </>
            )}</AutoSizer>
            {showArrowBtns && <div className='section pb-5 pt-0'>
              <div className='level is-mobile'>
                <nav className='level-right SwatchScroller__ctrls SwatchScroller__ctrls--screen-lg'>
                  <div className='level-item SwatchScroller__ctrls__ctrl'>
                    <ArrowBtn aria-controls={scrollerId} label='Back' onClick={() => setScroll(-1)} disabled={arrowDisabledRev} size={isWideView ? ArrowBtn.SIZES.LARGE : ArrowBtn.SIZES.DEFAULT} direction={ArrowBtn.DIRECTIONS.LEFT} />
                  </div>
                  <div className='level-item SwatchScroller__ctrls__ctrl'>
                    <ArrowBtn aria-controls={scrollerId} label='Forward' onClick={() => setScroll(1)} disabled={arrowDisabledFwd} size={isWideView ? ArrowBtn.SIZES.LARGE : ArrowBtn.SIZES.DEFAULT} />
                  </div>
                </nav>
              </div>
            </div>}
          </>}
    </section>
  )
}

export default SwatchScroller
