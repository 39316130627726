import { ColorSwatch } from '@prism/toolkit'
import React from 'react'
import archivedColors from './archived-colors'
import './ColorSwatch.scss'
import SwatchContentRenderer from './SwatchContentRenderer'

const SearchSwatch = ({ color, style }) => {
  const baseClass = 'color-search-swatch'
  const isUnavailable = archivedColors.has(`${color.colorNumber}`)

  return (
    <ColorSwatch
      key={color.id}
      aria-label={color?.name}
      color={color}
      flagged={isUnavailable}
      className={`${baseClass}`}
      style={style}
      active
      renderer={() => <SwatchContentRenderer color={color} />}
    />
  )
}

export default SearchSwatch
