import { ADD_TO_HOLD } from 'app/Store/actions/cart'

export function itemHold (state = [], action) {
  if (action.type === ADD_TO_HOLD) {
    return action.payload.map((item) => {
      return { ...item }
    })
  }

  return state
}
