import React from 'react'

import './Disclaimer.scss'

function Disclaimer ({
  className = '',
  heading = null,
  media = null,
  showHeading = false,
  showMediaWhenSmall = true,
  message = null,
  tertiary = null
}) {
  return (
    <section className={`Disclaimer ${className}`}>
      {media
        ? <div className={`Disclaimer__media ${showMediaWhenSmall ? 'Disclaimer__media--show-small' : ''}`}>
          {media}
        </div>
        : null}
      <div className='Disclaimer__content'>
        {showHeading && heading
          ? <h3 className='Disclaimer__title'>
            {heading}
          </h3>
          : null}
        {message || null}
      </div>
      {tertiary
        ? <div className='Disclaimer__tertiary'>
        {tertiary}
      </div>
        : null}
    </section>
  )
}

export default Disclaimer
