import './GenericModal.scss'

import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import useEffectAfterMount from 'app/hooks/useEffectAfterMount'
import withUniqueId from 'app/HOCs/withUniqueId'

function GenericModal ({ id, modalContent, onClose }) {
  const { title = null, content = null } = modalContent
  const closeBtnRef = useRef()
  const { location } = useHistory()

  useEffect(() => {
    if (closeBtnRef.current) closeBtnRef.current.focus()
  }, [closeBtnRef])

  useEffect(() => {
    const closeOnEsc = e => (e.keyCode === 27) ? onClose() : null
    document.addEventListener('keydown', closeOnEsc)
    return () => document.removeEventListener('keydown', closeOnEsc)
  }, [])

  useEffectAfterMount(onClose, [location])

  return (
    <section className='GenericModal modal is-active' aria-labelledby={`modalTitle_${id}`}>
      {/* eslint-disable-next-line */}
      <div className='GenericModal__bg modal-background' onMouseUp={onClose} />
      <button ref={closeBtnRef} onClick={onClose} className='OptionsModal__close-btn modal-close is-large' aria-label='close' />
      <div className='modal-content'>
        <div className='container'>
          <div className='GenericModal__content box'>
            <div className='GenericModal__content__piece GenericModal__content__piece--title'>
              <h1 id={`modalTitle_${id}`} className='title'>{title}</h1>
            </div>
            <div className='GenericModal__content__piece'>
              {content}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default withUniqueId(GenericModal)
