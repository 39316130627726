import addOrReplaceBy from 'app/Utils/addOrReplaceBy'
import findIndex from 'lodash/findIndex'
import omit from 'lodash/omit'
import uniq from 'lodash/uniq'
import extractUser from 'app/Utils/user.util'

import {
  ADD_TO_PENDING,
  ALIGN_CART_WITH_INVENTORY,
  CART_ADD,
  CART_EMPTY,
  CART_ERRORS_CLEAR,
  CART_ERRORS,
  CART_LOADING,
  CART_REMOVE,
  CLEAR_PENDING,
  HIDE_OPTIONS,
  SEE_OPTIONS,
  SET_AVAILABLE_COLORS,
  SET_CLIENT_TOKEN,
  SET_LOADING_COLORS,
  SET_ORDER_ID,
  SET_USER_INFO,
  SYNC_ITEMS,
  SET_DELIVERY_FEE, SET_AUTH_ID
} from '../actions/cart'
import { findProductByItemType } from 'app/Utils/general.utils'
import { cloneDeep } from 'lodash/lang'

const persistedStateKeys = {
  AVAILALABLE_COLORS: 'availableColors',
  LOADING_COLORS: 'loadingColors'
}

const initialState = {
  items: [], // { productId: <sku>, qty: <number>, color: <colorObj>, isChip: <bool>, isSwatch: <bool> }
  latestColor: null,
  [persistedStateKeys.AVAILALABLE_COLORS]: [], // this is a collection of SW color objects (formerly a dictionary by color number)
  [persistedStateKeys.LOADING_COLORS]: true,
  seeOptionsFor: null, // right now this is a color object
  loading: false,
  orderId: null,
  user: null,
  errors: [],
  pendingAdds: [],
  clientToken: null,
  swatchDiscountedPrice: 3.50,
  itemsRequiredForDiscount: 4,
  deliveryFee: 0
}

export const cart = (state = initialState, { type, payload }) => {
  switch (type) {
    case CLEAR_PENDING: {
      return {
        ...state,
        pendingAdds: initialState.pendingAdds
      }
    }
    case ADD_TO_PENDING: {
      return {
        ...state,
        pendingAdds: [
          ...state.pendingAdds,
          payload
        ]
      }
    }
    case ALIGN_CART_WITH_INVENTORY: {
      // updates front-end cart w/ current product/inventory/price data
      const { chips = [], swatches = [], kits =[] } = payload // eslint-disable-line
      const { items } = state // eslint-disable-line

      return {
        ...state,
        items: items.map((item) => {
          const productMatch = findProductByItemType(item, chips, swatches, kits)

          if (productMatch && productMatch.active) {
            return {
              ...cloneDeep(item),
              price: productMatch.basePrice // update price in cart to match response from server
            }
          }
        }).filter(Boolean)
      }
    }
    case CART_LOADING:
      return { ...state, loading: payload }
    case CART_ADD: {
      const cartAddState = {
        ...state,
        items: addOrReplaceBy(
          state.items,
          item => item.productId.toLowerCase() === payload.productId.toLowerCase(),
          item => item ? { ...item, qty: item.qty + payload.qty } : payload
        )
      }

      return cartAddState
    }
    case SYNC_ITEMS: {
      const { items, cleanUp } = payload
      const { items: originalItems } = state

      return {
        ...state,
        items: originalItems.map(originalItem => {
          const backendMatch = items.filter(item => originalItem.productId.toLowerCase() === item.sku.toLowerCase())[0]

          // backend does not return items with qty 0. Which could result in the match being undefined
          // to get around this for now, we will just use our local records of an item with qty 0
          if (backendMatch === undefined && originalItem.qty === 0) {
            return
          }

          if (cleanUp) {
            // remove items _not_ in the backend response
            if (!backendMatch) {
              return
            }
            // remove items in backend response with quantity of 0
            if (backendMatch.quantity === 0) {
              return
            }
          }

          const { price = originalItem.price, quantity = 0, totalAdjustment = 0, totalTax = 0, ...other } = backendMatch
          const _price = price / quantity
          const _discountedPrice = totalAdjustment ? (price - totalAdjustment) / quantity : undefined

          // converting an item from the backend to the type structure we use on the frontend
          return {
            ...originalItem,
            price: _price,
            discountedPrice: _discountedPrice,
            totalPrice: (_discountedPrice ?? _price) * quantity,
            totalTax,
            qty: quantity, // sync our cart quantity w/ backend quantity
            orderData: {
              ...other, // put majority of backend data into orderData property
              totalAdjustment
            }
          }
        }).filter(Boolean)
      }
    }
    case CART_REMOVE: {
      const { qty, productId } = payload
      const { items } = state

      const i = findIndex(items, item => item.productId.toLowerCase() === productId.toLowerCase())
      const item = items[i]

      if (typeof item === 'undefined') return state

      return {
        ...state,
        items: [
          ...items.slice(0, i),
          { ...item, qty: item.qty - qty },
          ...items.slice(i + 1)
        ].filter(item => item.qty > 0) // this cleans up no-quantity items
      }
    }
    case CART_ERRORS_CLEAR:
      return {
        ...state,
        errors: []
      }
    case CART_ERRORS:
      return {
        ...state,
        errors: uniq([
          ...(state.errors || []),
          ...payload.errors
        ])
      }
    case CART_EMPTY:
      return {
        ...state,
        ...omit(initialState, Object.values(persistedStateKeys))
      }
    case SEE_OPTIONS:
      return { ...state, seeOptionsFor: payload }
    case HIDE_OPTIONS:
      return { ...state, seeOptionsFor: null }
    case SET_USER_INFO:
      return { ...state, user: extractUser(payload.user) }
    case SET_ORDER_ID: {
      const { orderId } = payload
      return { ...state, orderId }
    }
    case SET_CLIENT_TOKEN: {
      const { clientToken } = payload
      return { ...state, clientToken }
    }
    case SET_AVAILABLE_COLORS:
      return { ...state, availableColors: payload.availableColors }
    case SET_LOADING_COLORS:
      return { ...state, loadingColors: payload }
    case SET_DELIVERY_FEE:
      return { ...state, deliveryFee: payload }
    default: return state
  }
}

export function authId (state = '', action) {
  if (action.type === SET_AUTH_ID) {
    return action.payload
  }
  return state
}
