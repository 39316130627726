import pick from 'lodash/pick'
import isObject from 'lodash/isObject'

export const requiredUserProps = [
  'address1',
  'city',
  'country',
  'email',
  'firstName',
  'lastName',
  'postal',
  'state',
  'userId'
]

export const optionalUserProps = [
  'address2',
  'company',
  'newsletterOptIn',
  'phone'
]

export function validateUser (user = {}) {
  if (!isObject(user)) return false
  const isValid = requiredUserProps.filter(v => !user[v] || (typeof user[v] === 'string' && user[v].trim() === '')).length === 0
  return isValid
}

function extractUser (user = {}) {
  if (Object.prototype.hasOwnProperty.call(user, 'address1')) {
    if (user.address1 !== null) {
      if (user.address1.includes(' Apt ')) {
        const address = user.address1.split(' Apt ')
        user.address1 = address[0]
        user.address2 = address[1]
      }
      if (user.address1.includes(' Ste ')) {
        const address = user.address1.split(' Ste ')
        user.address1 = address[0]
        user.address2 = address[1]
      }
    }
  }
  if (!isObject(user)) return {}

  return pick(user, [
    ...requiredUserProps,
    ...optionalUserProps
  ])
}

export default extractUser
