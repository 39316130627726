import React from 'react'
import RequiredAsterisk from '../RequiredAsterisk/RequiredAsterisk'
import { FormattedMessage, injectIntl } from 'react-intl'
import { ErrorMessage } from 'react-hook-form'
import { withIsLoading } from './withIsLoading'
import withUniqueId from 'app/HOCs/withUniqueId'

export const Email = withUniqueId(injectIntl(withIsLoading(({ isLoading, errors, register, intl, id }) => (
  <div className='field'>
    <label className='label has-text-weight-normal' htmlFor={id}>
      <FormattedMessage id='CHECKOUT_FIELDS_EMAIL' /> <RequiredAsterisk required />
      <div className={`control ${isLoading ? 'is-loading' : ''}`}>
        <input
          className={`input ${errors.email ? 'is-danger' : ''}`}
          type='text'
          readOnly={isLoading}
          id={id}
          name='email'
          ref={register({
            required: intl.formatMessage({ id: 'CHECKOUT_FIELDS_EMAIL_ERROR_REQUIRED' }),
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: intl.formatMessage({ id: 'CHECKOUT_FIELDS_EMAIL_ERROR_INVALID' })
            }
          })}
          autoComplete='email'
        />
        <ErrorMessage name='email' errors={errors}>
          {({ message }) => <p className='help is-danger'>{message}</p>}
        </ErrorMessage>
      </div>
    </label>
  </div>
))))
