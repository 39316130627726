import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import Footer from 'components/Footer/Footer'
import TopNav from 'components/TopNav/TopNav'
import Cart from 'app/components/Cart/Cart'
import useResetNavigationTo from 'app/hooks/useResetOnNavigateTo'
import useTitle from 'app/hooks/useTitle'
import CartPriceProceed from '../CartPriceProceed/CartPriceProceed'

import './PageCart.scss'
import LimitedOfferMessage from 'components/LimitedOfferMessage/LimitedOfferMessage'
import useCart from 'app/hooks/useCart'
import RemoteNotification from '../RemoteNotification/RemoteNotification'

function PageCart () {
  const { formatMessage } = useIntl()
  const { nonZeroItems } = useCart()

  useResetNavigationTo()
  useTitle(formatMessage({ id: 'PAGE_TITLE_CART' }))

  return (
    <>
      <RemoteNotification messageKey='REMOTE_MESSAGE_CONTENT' />
      <TopNav />
      <div className='PageCheckout has-text-dark'>
        <section className='hero'>
          <div className='hero-body pb-5'>
            <div className='container'>
              <div className='is-flex is-flex-wrap-wrap is-justify-content-space-between'>
                <div className='mr-5 mb-5'>
                  <h1 className='title is-size-4-mobile mb-3'><FormattedMessage id='PAGE_CART_HEADING' /></h1>
                  <h2 className='is-size-5 is-size-6-mobile mb-0'><FormattedMessage id='PAGE_CART_SUBHEADING' /></h2>
                </div>
                {nonZeroItems.length
                  ? <div className=''>
                    <LimitedOfferMessage />
                  </div>
                  : null}
              </div>
            </div>
          </div>
        </section>
        <section className='section pt-0'>
          <div className='container'>
            <Cart />
          </div>
        </section>
        {nonZeroItems.length
          ? <section className='section has-background-white'>
            <div className='container'>
              <CartPriceProceed />
            </div>
          </section>
          : null}
      </div>
      <Footer />
    </>
  )
}

export default PageCart
