import {
  SEE_MODAL,
  HIDE_MODAL,
  SIRIANO_UNAVAILABLE
} from '../actions/modal'

const initialState = {
  modalContent: {}
}

export const modal = (state = initialState, { type, payload }) => {
  switch (type) {
    case SEE_MODAL:
      return { ...state, modalContent: payload }
    case HIDE_MODAL:
      return { ...state, modalContent: {} }
    default: return state
  }
}

export const sirianoUnavailable = (state = false, action) => {
  if (action.type === SIRIANO_UNAVAILABLE) {
    return action.payload
  }

  return state
}
