/* eslint-disable */
import React, { useContext } from 'react'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import Media from 'components/Media/Media'
import Pill from 'components/Pill/Pill'
import PeelStickIcon from 'components/PeelStickIcon/PeelStickIcon'
import ChipsIcon from 'components/ChipsIcon/ChipsIcon'
import PackageIcon from 'src/svg/package.svg'
import SVG from 'react-inlinesvg'

import './HomepageHero.scss'
import countries from 'app/constants/countries'
import CountryLangCtx from 'app/contexts/countryLang/CountryLangCtx'

import SurveyModal from '../OptionsModal/SurveyModal';

export default function HomepageHero () {
  const intl = useIntl()
  const { country } = useContext(CountryLangCtx)
  const isUS = country === countries.us
  const heroSecBackground = isUS ? "#DCE7E8":"#DCE2DF"

  const heroImageSet = isUS ? `${require('src/images/hero1.jpg')} ${require('src/images/hero1.jpg')}` :
    `${require('src/images/hero-ca.jpg')} ${require('src/images/hero-ca@2.jpg')}`

  const heroImage = country === countries.us ? `${require('src/images/hero1.jpg')}` : `${require('src/images/hero-ca.jpg')}`

  return <>
    <section className='has-background-dirty-grey section p-3 px-6 has-text-centered is-size-7 is-size-6-widescreen'>
      <FormattedMessage
        id='HOMEPAGE_SHIPPING_MESSAGE'
        values={{
          icon: () => <SVG aria-hidden='true' className='svg-outline' style={{ fontSize: '1.5em', margin: '0 .25em -.25em 0' }} src={PackageIcon} />,
          emphasize: chunk => <span className='has-text-weight-bold is-uppercase'>{chunk}</span>,
          line: chunk => chunk
        }}
      />
    </section>
    {isUS ? <SurveyModal /> : null}
    <section className='HomepageHero section py-0 mb-5' style={{background : heroSecBackground}}>
      <div className='HomepageHero__container pt-6 is-justify-content-center container'>
        <div className='HomepageHero__container__piece HomepageHero__container__piece--content has-text-centered is-flex-widescreen is-flex-direction-column'>
          <h1 className='HomepageHero__title mb-4 mx-auto'><FormattedMessage id='HP_HERO_HEADLINE' /></h1>
          {isUS ? <h2 className='HomepageHero__title HomepageHero__title--sub mb-6 mx-auto'><FormattedMessage id='HP_HERO_SUBHEADLINE' /></h2> : null}
          <div className={`columns ${!isUS ? ' HomepageHero__color-chips-container' : ' is-not-big-mobile-or-small-desktop'}`}>
            {isUS ? <div className='column'>
              <Media className='has-text-left'>
                <Media.Graphic><PeelStickIcon /></Media.Graphic>
                <Media.Content><>
                  <h3 className='HomepageHero__title HomepageHero__title--sub mb-2'>
                    <FormattedMessage id='HOMEPAGE_HERO_P&S_TITLE' values={{
                      tag: chunk => null,
                      line: chunk => <span style={{ display: 'inline-block' }}>{chunk}</span>
                    }} />
                  </h3>
                  <p className='mb-4'>
                    <FormattedMessage id='PRICE_EACH_OR_4_PRICE' values={{
                      priceA: <FormattedNumber style='currency' value={3.95} currency='USD' />,
                      wrapPriceA: chunk => <span className='has-text-weight-bold'>{chunk}</span>,
                      priceB: <FormattedNumber style='currency' value={3.50} currency='USD' />,
                      wrapPriceB: chunk => <span className='has-text-weight-bold has-text-green'>{chunk}</span>,
                      line: chunk => <span style={{ display: 'inline-block' }}>{chunk}</span>
                    }} />
                  </p>
                  <ul className='list list--flush'>
                    <FormattedMessage id='HOMEPAGE_HERO_P&S_CONTENT' values={{ line: chunk => <li className='list__item'>{chunk}</li> }} />
                  </ul>
                </></Media.Content>
              </Media>
            </div> : null}
            <div className={`column${!isUS ? ' HomepageHero__color-chips-item' : ''}`}>
              <Media className='has-text-left'>
                <Media.Graphic><ChipsIcon /></Media.Graphic>
                <Media.Content><>
                  <h3 className={`HomepageHero__title HomepageHero__title${isUS ? '--sub' : '--sub-ca'} mb-2`}>
                    <FormattedMessage id='HOMEPAGE_HERO_CHIP_TITLE' values={{ div: chunks => {
                      return <div>{chunks}</div>
                      }}}/>
                  </h3>
                  {isUS ? <p className='mb-4 has-text-weight-bold'>
                    <FormattedMessage id='HOMEPAGE_HERO_CHIP_PRICE' />
                  </p> : null}
                  <ul className='list list--flush'>
                    <FormattedMessage id='HOMEPAGE_HERO_CHIP_CONTENT' values={{ line: chunk => <li className='list__item'>{chunk}</li> }} />
                  </ul>
                </></Media.Content>
              </Media>
            </div>
          </div>
          {!isUS ? <p className='has-text-centered has-text-right-widescreen pt-3'><FormattedMessage id='HOMEPAGE_DISCLAIMER' /></p> : null}
        </div>
        <div className='HomepageHero__container__piece HomepageHero__container__piece--graphic'>
          <img
            srcSet={heroImageSet}
            src={heroImage}
            alt={intl.formatMessage({ id: 'HERO_IMAGE_ALT'})}
          />
        </div>
      </div>
    </section>
  </>
}
