import OptionsModal from 'app/components/OptionsModal/OptionsModal'
import useCart from 'app/hooks/useCart'
import React, { useState, useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'

function withCartOverlay (WrappedComponent) {
  return (props) => {
    const { optionsModalFor, hideOptionsModal } = useCart()
    const [showOptionsModalFor, setShowOptionsModalFor] = useState()

    useEffect(() => {
      if (optionsModalFor) {
        setShowOptionsModalFor(optionsModalFor)
        hideOptionsModal()
      }
    }, [optionsModalFor])

    useEffect(() => {
      if (isEmpty(optionsModalFor) && isEmpty(showOptionsModalFor)) {
        document.querySelector('html').style.overflow = 'visible'
      } else {
        document.querySelector('html').style.overflow = 'hidden'
      }
    }, [optionsModalFor, showOptionsModalFor])

    return <>
      <WrappedComponent {...props} />
      {showOptionsModalFor
        ? <OptionsModal color={showOptionsModalFor} onClose={() => setShowOptionsModalFor()} />
        : null}
    </>
  }
}

export default withCartOverlay
