import React, { useContext } from 'react'
import RequiredAsterisk from '../RequiredAsterisk/RequiredAsterisk'
import { FormattedMessage, injectIntl } from 'react-intl'
import { ErrorMessage } from 'react-hook-form'
import { withIsLoading } from './withIsLoading'
import { states } from '../OrderFormConstants'
import CountryLangCtx from 'app/contexts/countryLang/CountryLangCtx'
import withUniqueId from 'app/HOCs/withUniqueId'

export const State = withUniqueId(injectIntl(withIsLoading(({ registerAs, isLoading, errors, register, intl, id }) => {
  const { country } = useContext(CountryLangCtx)

  return (
    <div className='field'>
      <label className='label has-text-weight-normal' htmlFor={id}>
        <FormattedMessage id='CHECKOUT_FIELDS_STATE' /> <RequiredAsterisk required />
        <div className='control'>
          {
            country.toUpperCase() === 'US'
              ? (
                <div className={`select ${errors.state ? 'is-danger' : ''} ${isLoading ? 'is-loading' : ''}`} style={{ width: '100%' }}>
                  <select readOnly={isLoading} style={{ width: '100%' }} id={id} name={registerAs} ref={register} autoComplete='new-password'>
                    <option value=''>{intl.formatMessage({ id: 'CHECKOUT_FIELDS_STATE_SELECT' })}</option>
                    {states.map(state => <option key={state.key} value={state.key}>{state.key}</option>)}
                  </select>
                </div>
                )
              : (
                <input
                  readOnly={isLoading}
                  style={{ width: '100%' }}
                  className={`input ${errors.state ? 'is-danger' : ''}`}
                  type='text'
                  id={id}
                  name={registerAs}
                  ref={register}
                  autoComplete='new-password'
                />
                )
          }
          <ErrorMessage name={registerAs} errors={errors}>
            {({ message }) => <p className='help is-danger'>{message}</p>}
          </ErrorMessage>
        </div>
      </label>
    </div>
  )
})))
