import { useCallback, useEffect, useState, useRef } from 'react'
import {
  addToCart,
  clearCartErrors,
  // @todo i thinkI can remove -RS I think this is limited to payeezy -RS
  clearTokensAndIDs,
  emptyCart,
  loadCart,
  removeFromCart,
  setCartErrors,
  setCartLoading,
  setClientToken,
  setDeliveryFee,
  setHideOptions,
  setOrderId,
  setSeeOptions,
  setUserId,
  setUserInfo,
  synchronizeCart
} from 'app/Store/actions/cart'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { LIMIT_CART_CHIP } from 'app/constants/product'
import debounce from 'lodash/debounce'
import sync, { getColorNumberFromProduct } from 'app/Utils/cart.util'
import noop from 'lodash/noop'
import extractUser from 'app/Utils/user.util'
import { cloneDeep } from 'lodash/lang'
import { getBrandColorNumber } from 'app/Utils/general.utils'

const localizeProduct = (product, colors, intl) => {
  const newProduct = cloneDeep(product)

  if (!newProduct.orderData) {
    return newProduct
  }

  let desc = ''
  let productColors = []
  // Chips and P&S have 1 color
  // There are two paths here A) add to cart in which
  // the colors are just id and B) on sync when colors are actual objects
  if (product.colors.length === 1) {
    const colorNumber = getColorNumberFromProduct(product)
    const color = cloneDeep(colors.find((cn) => getBrandColorNumber(cn).toLowerCase() === colorNumber))
    productColors = [color]
    desc = color?.description ?? ''
  } else if (product.colors.length > 1) { // c. siriano has many colors (1 sku many colors)
    // Since this is a one off right now, we will keep the localized description for C. Siriano in the app language files
    desc = intl.formatMessage({ id: 'CHRISTIAN_SIRIANO_KIT_DESC' }) ?? ''
    productColors = product.colors.map((cn) => {
      if (typeof cn === 'string') {
        return cloneDeep(colors.find((c) => c.toLowerCase() === cn))
      }

      return cloneDeep(cn)
    })
  }

  newProduct.orderData.colors = productColors
  newProduct.orderData.description = desc
  return newProduct
}

function getLocalizedItems (ogItems, colors, intl) {
  return ogItems.map((item) => {
    return localizeProduct(item, colors, intl)
  })
}

export default function useCart () {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { items, availableColors, seeOptionsFor, orderId, loading, user, errors, clientToken, itemsRequiredForDiscount, swatchDiscountedPrice, deliveryFee } = useSelector(state => state.cart)
  const useCartSync = useRef(noop)
  const { formatMessage } = useIntl()
  const showOptionsModalFor = useCallback(({ id, color }) => {
    if (id) {
      // match product data based on color id, since colorId is the seeOptionsFor value
      const matchedColor = availableColors.filter(color => color.id === id)[0]
      if (matchedColor) {
        dispatch(setSeeOptions(matchedColor))
      }
    } else if (color) {
      dispatch(setSeeOptions(color))
    }
  }, [availableColors])

  const [itemsSwatches, setItemsSwatches] = useState([])
  const [itemsSwatchesNonZero, setItemsSwatchesNonZero] = useState([])
  const [itemsChips, setItemsChips] = useState([])
  const [itemsChipsNonZero, setItemsChipsNonZero] = useState([])
  const [countSwatches, setCountSwatches] = useState(0)
  const [countChips, setCountChips] = useState(0)
  const [count, setCount] = useState(0)
  const [grandTotal, setGrandTotal] = useState(0)
  const [subtotal, setSubtotal] = useState(0)
  const [totalTax, setTotalTax] = useState(0)
  const [nonZeroItems, setNonZeroItems] = useState([])
  const [itemsKits, setItemsKits] = useState([])
  const [countKits, setCountKits] = useState(0)
  const [itemsKitsNonZero, setItemsKitsNonZero] = useState([])

  useCartSync.current = function useCartSync (cleanUp = false, cancelToken = null, onSuccess = noop, onFail = noop) {
    dispatch(setCartLoading(true))
    dispatch(clearCartErrors())

    const hasPreexistingOrderId = !!orderId
    const hasPreexistingClientToken = !!clientToken

    function doSync (preexistingOrderId, preexistingClientToken) {
      return sync({
        items,
        orderId: preexistingOrderId,
        user,
        clientToken: preexistingClientToken,
        cancelToken
      })
        .then(data => {
          dispatch(setCartLoading(false))
          if (!data) {
            onFail()
            return // indicates user-cancelled operation
          }

          const { orderId, lineItems, clientToken, deliveryFee } = data

          const payload = {
            cleanUp,
            orderId,
            items: lineItems,
            user: extractUser(data),
            clientToken
          }

          // clear errors
          dispatch(clearCartErrors())
          dispatch(synchronizeCart(payload))

          if (deliveryFee) {
            dispatch(setDeliveryFee(deliveryFee))
          } else {
            dispatch(setDeliveryFee(0))
          }

          onSuccess()

          return true
        })
    }

    doSync(orderId, clientToken)
      .catch(err => {
        // if we have a clientToken and have encountered an error...
        if (hasPreexistingClientToken) {
          // ... clear the client token and order ID we have, and proceed to throw the error
          dispatch(clearTokensAndIDs())
          throw err
        }

        // if we had ONLY a preexisting order ID, try to re-sync without it to create a new order
        if (hasPreexistingOrderId) {
          console.info(`Order ID ${orderId} failed to sync. Attempting to establish new order ID and resync.`)
          // clear existing redux order ID before attempting a resync
          dispatch(setOrderId(null))
          dispatch(setUserId(null))
          // attempt a new initial sync WITHOUT order ID or client token in order to generate them anew
          return doSync()
        }

        // otherwise, re-throw the error because it's legitimate
        throw err
      })
      .catch(err => {
        // this catch will handle all real errors from doSync()
        dispatch(setCartErrors([formatMessage({ id: 'CART_ERROR_SYNC' })]))
        onFail(err)
        dispatch(setCartLoading(false))
      })
  }

  useEffect(() => {
    const localizedItems = getLocalizedItems(items, availableColors, intl)
    const nonZeroItems = localizedItems.filter(({ qty }) => qty > 0)
    // kits are c siriano related only, safe to remove when promo done
    const itemsKits = localizedItems.filter(item => cloneDeep(item.isKit))
    const itemsChips = localizedItems.filter(item => cloneDeep(item.isChip))
    const itemsChipsNonZero = nonZeroItems.filter(item => cloneDeep(item.isChip))
    const itemsSwatches = localizedItems.filter(item => cloneDeep(item.isSwatch))
    const itemsSwatchesNonZero = nonZeroItems.filter(item => cloneDeep(item.isSwatch))
    const itemsKitsNonZero = nonZeroItems.filter(item => cloneDeep(item.isKit))
    const countSwatches = itemsSwatches.map(item => item.qty).reduce((accum, qty) => accum + qty, 0)
    const countChips = itemsChips.map(item => item.qty).reduce((accum, qty) => accum + qty, 0)
    const countKits = itemsKits.map((item) => item.qty).reduce((accum, qty) => accum + qty, 0)
    const subtotal = nonZeroItems.map(item => item.totalPrice).reduce((accum, item) => accum + item, 0)
    const totalTax = nonZeroItems.map(item => item?.totalTax ?? 0).reduce((accum, item) => accum + item, 0)
    const grandTotal = subtotal + totalTax + deliveryFee

    setItemsSwatches(itemsSwatches)
    setItemsSwatchesNonZero(itemsSwatchesNonZero)
    setItemsChips(itemsChips)
    setItemsChipsNonZero(itemsChipsNonZero)
    setNonZeroItems(nonZeroItems)
    setCountSwatches(countSwatches)
    setCountChips(countChips)
    setCount(countSwatches + countChips + countKits)
    setSubtotal(subtotal)
    setGrandTotal(grandTotal)
    setTotalTax(totalTax)
    setItemsKits(itemsKits)
    setCountKits(countKits)
    setItemsKitsNonZero(itemsKitsNonZero)
  }, [items, availableColors, deliveryFee])

  const cartObject = {
    add: (productId, qty = 1) => dispatch(addToCart({ productId, qty })),
    canAddChips: countChips < LIMIT_CART_CHIP,
    count,
    countChips,
    countSwatches,
    countKits,
    empty: (orderId) => dispatch(emptyCart(orderId)),
    errors,
    grandTotal,
    hideOptionsModal: () => dispatch(setHideOptions()),
    items,
    itemsChips,
    itemsKits,
    itemsChipsNonZero,
    itemsSwatches,
    itemsSwatchesNonZero,
    itemsKitsNonZero,
    load: (intl) => dispatch(loadCart(intl)),
    loading,
    nonZeroItems,
    optionsModalFor: seeOptionsFor,
    orderId,
    remove: (productId, qty = Infinity) => dispatch(removeFromCart({ productId, qty })),
    setClientToken: (token) => dispatch(setClientToken(token)),
    setUser: (user) => dispatch(setUserInfo(user)),
    showOptionsModalFor,
    slowSync: debounce((cleanUp, cancelToken, onSuccess, onFail) => useCartSync.current(cleanUp, cancelToken, onSuccess, onFail), 1500), // sync w/ a 2s debounce
    subtotal,
    sync: (cleanUp, cancelToken, onSuccess, onFail) => useCartSync.current(cleanUp, cancelToken, onSuccess, onFail),
    totalTax,
    user,
    itemsRequiredForDiscount,
    swatchDiscountedPrice,
    deliveryFee
  }

  return cartObject
}
