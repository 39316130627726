import React from 'react'
import { FormattedMessage } from 'react-intl'

const Confirm = (props) => {
  const { addToCartHandler, closeHandler } = props
  return (
    <div className='confirm-wrapper'>
      <div className='confirm-content'>
        <button className='confirmYesBtn' onClick={addToCartHandler}><FormattedMessage id='YES' /></button>
        <button className='confirmNoBtn' onClick={closeHandler}><FormattedMessage id='NO' /></button>
      </div>
    </div>
  )
}

export default Confirm
