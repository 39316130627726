import { NAV_TO_PS, NAV_TO_TOP_50, NAV_TO_CHRISTIAN_SIRIANO } from 'app/Store/actions/colorWall'

export const navToPeelAndStick = (state = false, action) => {
  if (action.type === NAV_TO_PS) {
    console.table(action)
    return action.payload
  }
  return state
}

export const navToTop50 = (state = false, action) => {
  if (action.type === NAV_TO_TOP_50) {
    return action.payload
  }

  return state
}

export const navToChristianSiriano = (state = false, action) => {
  if (action.type === NAV_TO_CHRISTIAN_SIRIANO) {
    return action.payload
  }

  return state
}
