import './ProductSection.scss'
import { FormattedMessage } from 'react-intl'
// import Callout from 'components/Callout/Callout'
import CartBlock from 'components/Cart/CartBlock'
import React, { useContext } from 'react'
import CountryLangCtx from 'app/contexts/countryLang/CountryLangCtx'
import countries from 'app/constants/countries'
// import PriceView from 'components/Cart/PriceView'
import ProductListView from 'components/Cart/ProductListView'
import PeelStickIcon from 'components/PeelStickIcon/PeelStickIcon'
import '../Cart/Cart.scss'

// use to determine
const localeAllowed = (usOnly = false, isUS) => !(usOnly && !isUS)

export default function ProductSection (props) {
  const {
    usOnly,
    // price,
    // discountPrice,
    products,
    cartBlockId,
    // Used for
    // productCount,
    displayMode,
    handleQtyChange,
    // itemsRequiredForDiscount,
    rowMax
  } = props

  const { country } = useContext(CountryLangCtx)
  const isUS = country === countries.us

  if (!products?.length || !localeAllowed(usOnly, isUS)) {
    return null
  }

  return (<article>
    <header className='mb-5' id={cartBlockId}>
      <h2 className='title is-size-6 is-size-5-tablet has-text-weight-semibold level is-mobile mb-0 '>
        <span className='level-test' style={{ flexWrap: 'wrap', width: '100%' }}>
          <span className='level-item us-nonzeroitems is-narrow ProductSection__icon-wrapper'><PeelStickIcon isOnWhiteBG /></span>
          <span className='level-item us-nonzeroitems is-narrow'><FormattedMessage id='CHRISTIAN_SIRIANO_CART_HEADER' /></span>
          {/* <PriceView price={price} discountPrice={discountPrice} /> */}
        </span>
      </h2>
      {/* @todo Add discount logic and callout comp back to make resuable for all sections in refactor -RS */}
      <h3 className='is-size-6 is-size-5-tablet mt-3'>
        <FormattedMessage id='CHRISTIAN_SIRIANO_CART_SUB_HEADING' />
      </h3>
    </header>
    <CartBlock aria-labelledby={cartBlockId} tabIndex='0' className='mb-6'>
      <ProductListView products={products} qtyChangeHandler={handleQtyChange} displayMode={displayMode} />
      {(new Array(Math.max(rowMax, rowMax % products.length)).fill(null)).map((item, i) => <CartBlock.Blank displayMode={displayMode} big key={i} />)}
    </CartBlock>
  </article>)
}
