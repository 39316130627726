import React from 'react'

import './HorizontalLoader.scss'

function HorizontalLoader ({ className = '', ...other }) {
  return (
    <svg {...other} className={`${className} HorizontalLoader`} xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' version='1.0' width='99px' height='27px' viewBox='0 0 128 35' xmlSpace='preserve'>
      <g>
        <circle fillOpacity='1' cx='17.5' cy='17.5' r='17.5' />
        <animate attributeName='opacity' dur='1500ms' begin='0s' repeatCount='indefinite' keyTimes='0;0.167;0.5;0.668;1' values='0.3;1;1;0.3;0.3' />
      </g>
      <g>
        <circle fillOpacity='1' cx='110.5' cy='17.5' r='17.5' />
        <animate attributeName='opacity' dur='1500ms' begin='0s' repeatCount='indefinite' keyTimes='0;0.334;0.5;0.835;1' values='0.3;0.3;1;1;0.3' />
      </g>
      <g>
        <circle fillOpacity='1' cx='64' cy='17.5' r='17.5' />
        <animate attributeName='opacity' dur='1500ms' begin='0s' repeatCount='indefinite' keyTimes='0;0.167;0.334;0.668;0.835;1' values='0.3;0.3;1;1;0.3;0.3' />
      </g>
    </svg>
  )
}

export default HorizontalLoader
