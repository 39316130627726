import React, { useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import countries from 'app/constants/countries'
import externalLinks from 'app/constants/externalLinks'
import Confirmation from 'components/Confirmation/Confirmation'
import ClosestStores from 'components/ClosestStores/ClosestStores'
import NextSteps from 'components/NextSteps/NextSteps'
import TopNav from 'components/TopNav/TopNav'
import Footer from 'components/Footer/Footer'
import CountryLangCtx from 'app/contexts/countryLang/CountryLangCtx'
import useResetNavigationTo from 'app/hooks/useResetOnNavigateTo'
import useTitle from 'app/hooks/useTitle'
import DisclaimerFAQCombo from '../DisclaimerFAQCombo/DisclaimerFAQCombo'
import RemoteNotification from 'components/RemoteNotification/RemoteNotification'

import './PageConfirmation.scss'

function PageConfirmation () {
  const { formatMessage } = useIntl()

  useResetNavigationTo()
  useTitle(formatMessage({ id: 'PAGE_TITLE_CONFIRMATION' }))

  const { country } = useContext(CountryLangCtx)
  const isUS = country === countries.us

  return (
    <>
      <RemoteNotification messageKey='REMOTE_MESSAGE_CONTENT' />
      <TopNav />
      <div className='has-text-dark'>
        <section className='section'>
          <div className='container'>
            <Confirmation />
          </div>
        </section>
        {country === countries.us && <NextSteps />}
        {country === countries.us && <section className='section'>
          <div className='container'>
            <ClosestStores />
          </div>
        </section>}
        <hr className='mx-auto' style={{ width: '70%' }} />
        {isUS
          ? <section className='is-flex is-flex-direction-column is-justify-content-center is-align-items-center mx-auto my-5 mb-6 pt-5'>
          <h2 className='title is-4 has-text-weight-bold'>
            <FormattedMessage id='HOW_WAS_YOUR_EXPERIENCE' />
          </h2>
          <div className='is-flex mb-6'>
            <button id='swcso-thumbs-up' className='has-background-white-bis'>
              <svg width='60' viewBox='0 0 63 57' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <title>{formatMessage({ id: 'THUMBS_UP' })}</title>
                <path
                  d='M17.9336 24.9688H3.87109C3.24949 24.9688 2.65335 25.2157 2.21381 25.6552C1.77427 26.0948 1.52734 26.6909 1.52734 27.3125V53.0938C1.52734 53.7154 1.77427 54.3115 2.21381 54.751C2.65335 55.1906 3.24949 55.4375 3.87109 55.4375H17.9336M17.9336 24.9688V55.4375M17.9336 24.9688L29.6523 1.53125C30.8835 1.53125 32.1026 1.77374 33.24 2.24488C34.3774 2.71602 35.4109 3.40657 36.2815 4.27712C37.152 5.14767 37.8426 6.18117 38.3137 7.31859C38.7849 8.45602 39.0273 9.67511 39.0273 10.9062V17.9375H57.1549C57.8196 17.9375 58.4766 18.0788 59.0825 18.3522C59.6884 18.6255 60.2292 19.0245 60.669 19.5228C61.1089 20.0211 61.4378 20.6072 61.6338 21.2423C61.8299 21.8774 61.8887 22.5469 61.8062 23.2064L58.2906 51.3314C58.1489 52.4651 57.598 53.508 56.7415 54.2641C55.885 55.0202 54.7818 55.4375 53.6393 55.4375H17.9336'
                  stroke='#2F2F30'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </button>
            <button id='swcso-thumbs-down' className='has-background-white-bis'>
              <svg width='60' viewBox='0 0 63 57' fill='none' stroke='#2F2F30' xmlns='http://www.w3.org/2000/svg'>
                <title>{formatMessage({ id: 'THUMBS_DOWN' })}</title>
                <path
                  d='M17.9375 32.0312H3.875C3.2534 32.0312 2.65726 31.7843 2.21772 31.3448C1.77818 30.9052 1.53125 30.3091 1.53125 29.6875V3.90625C1.53125 3.28465 1.77818 2.68851 2.21772 2.24897C2.65726 1.80943 3.2534 1.5625 3.875 1.5625H17.9375M17.9375 32.0312V1.5625M17.9375 32.0312L29.6562 55.4688C30.8874 55.4688 32.1065 55.2263 33.2439 54.7551C34.3813 54.284 35.4148 53.5934 36.2854 52.7229C37.1559 51.8523 37.8465 50.8188 38.3176 49.6814C38.7888 48.544 39.0312 47.3249 39.0312 46.0938V39.0625H57.1588C57.8235 39.0625 58.4805 38.9212 59.0864 38.6478C59.6923 38.3745 60.2331 37.9755 60.6729 37.4772C61.1128 36.9789 61.4417 36.3928 61.6378 35.7577C61.8338 35.1226 61.8926 34.4531 61.8101 33.7936L58.2945 5.66859C58.1528 4.5349 57.6019 3.492 56.7454 2.73588C55.8889 1.97977 54.7857 1.5625 53.6432 1.5625H17.9375'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </button>
          </div>
        </section>
          : null}
        <section className='section has-background-white'>
          <div className='max-width-desktop is-margin-auto-h has-text-centered-mobile'>
            <div className='columns'>
              <div className='column is-flex is-align-items-center'>
                <h1 className='title is-size-4 is-size-3-desktop'><FormattedMessage id='PAGE_CONFIRMATION_TOOLS_AND_PRODUCTS_HEADING' /></h1>
              </div>
              <div className='column'>
                <div className='card PageConfirmation__card'>
                  <div className='card-image PageConfirmation__card__img'>
                    <figure className='image is-16by9 is-cover'>
                      <img src={require('src/images/ToolsImage_Visualizer.jpg')} alt={formatMessage({ id: 'PAGE_CONFIRMATION_COLORSNAP_VISUALIZER_HEADING' })} />
                    </figure>
                  </div>
                  <div className='card-content PageConfirmation__card__content'>
                    <div className='content'>
                      <h2 className='is-size-5 has-text-weight-semibold'>
                        <a className='text-link' href='https://www.sherwin-williams.com/homeowners/color/try-on-colors'><FormattedMessage id='PAGE_CONFIRMATION_COLORSNAP_VISUALIZER_HEADING' /></a>
                      </h2>
                      <p className='is-size-6'><FormattedMessage id='PAGE_CONFIRMATION_COLORSNAP_VISUALIZER_CONTENT' /></p>
                    </div>
                  </div>
                </div>
              </div>
              {isUS && <div className='column'>
                <div className='card PageConfirmation__card'>
                  <div className='card-image PageConfirmation__card__img PageConfirmation__card__img--shadowed'>
                    <figure className='image is-16by9 is-cover'>
                      <img src={require('src/images/Virtual Color Consultation Img.jpg')} alt={`${formatMessage({ id: 'PAGE_CONFIRMATION_TINTED_HEADING' })}`} />
                    </figure>
                  </div>
                  <div className='card-content PageConfirmation__card__content'>
                    <div className='content'>
                      <h2 className='is-size-5 has-text-weight-semibold'>
                        <a className='text-link' target='_blank' href={externalLinks.expertColorAdvice} rel="noreferrer"><FormattedMessage id='PAGE_CONFIRMATION_GET_EXPERT_COLOR_ADVICE_HEADING' /></a>
                      </h2>
                      <p className='is-size-6'><FormattedMessage id='PAGE_CONFIRMATION_GET_EXPERT_COLOR_ADVICE_CONTENT' values={{
                        strong: (chunks) => {
                          return <strong>{chunks}</strong>
                        }
                      }} /></p>
                    </div>
                  </div>
                </div>
              </div>}
              {!isUS && <div className='column'>
                <div className='card PageConfirmation__card'>
                  <div className='card-image PageConfirmation__card__img'>
                    <figure className='image is-16by9 is-cover'>
                      <img src={require('src/images/Virtual Color Consultation Img.jpg')} alt={`${formatMessage({ id: 'PAGE_CONFIRMATION_GET_EXPERT_COLOR_ADVICE' })}`} />
                    </figure>
                  </div>
                  <div className='card-content PageConfirmation__card__content'>
                    <div className='content'>
                      <h2 className='is-size-5 has-text-weight-semibold'>
                        <a className='text-link' href={externalLinks.expertColorAdvice}><FormattedMessage id='PAGE_CONFIRMATION_GET_EXPERT_COLOR_ADVICE_HEADING' /></a>
                      </h2>
                      <p className='is-size-6'><FormattedMessage id='PAGE_CONFIRMATION_GET_EXPERT_COLOR_ADVICE_CONTENT' values={{
                        strong: (chunks) => {
                          return <strong>{chunks}</strong>
                        }
                      }} /></p>
                    </div>
                  </div>
                </div>
              </div>}

            </div>
          </div>
        </section>
        {country === countries.ca && <section className='section'>
          <div className='container'>
            <ClosestStores />
          </div>
        </section>}
        <section className='section pb-0'>
          <div className='container'>
            <DisclaimerFAQCombo />
          </div>
        </section>
      </div>
      <Footer />
    </>
  )
}

export default PageConfirmation
