// import all supported languages
import enUS from './en-US.json'
import enCA from './en-CA.json'
import frCA from './fr-CA.json'

export default {
  'en-US': enUS,
  'en-CA': enCA,
  'fr-CA': frCA
}
