import React from 'react'
import RequiredAsterisk from '../RequiredAsterisk/RequiredAsterisk'
import { FormattedMessage, injectIntl } from 'react-intl'
import { withIsLoading } from './withIsLoading'
import withUniqueId from 'app/HOCs/withUniqueId'
import { ErrorMessage } from 'react-hook-form'

export const Address2 = withUniqueId(injectIntl(withIsLoading(({ registerAs, isLoading, register, errors, intl, id }) => (
  <div className='field'>
    <label className='label has-text-weight-normal' htmlFor={id}>
      <FormattedMessage id='CHECKOUT_FIELDS_ADDRESS2' /> <RequiredAsterisk />
      <div className={`control ${isLoading ? 'is-loading' : ''}`}>
        <input id={id} readOnly={isLoading} className='input' type='text' name={registerAs} ref={register} autoComplete='new-password' />
          <ErrorMessage name={registerAs} errors={errors}>
              {({ message }) => <p className='help is-danger'>{message}</p>}
          </ErrorMessage>
      </div>
    </label>
  </div>
))))
