import React, { useCallback, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import CloseImage from 'src/svg/close.svg'
import SVG from 'react-inlinesvg'
import { FormattedMessage } from 'react-intl'

import { getServiceBaseUrl } from '../../Utils/serviceUtils'
import useLocalization from 'app/hooks/useLocalization'
import externalLinks from 'app/constants/externalLinks'

import './RemoteNotification.scss'
import 'styles/utils/nowrap.scss'
import stringToBool from 'app/Utils/stringToBool'
import CountryLangCtx from 'app/contexts/countryLang/CountryLangCtx'

const NORMAL = 'RemoteNotification__notification--normal'

const RemoteNotification = (props) => {
  const { messageKey, children } = props
  const [notificationClass, setNotificationClass] = useState('')
  const [show, setShow] = useState(false)
  const [remoteMessage, setRemoteMessage] = useState('')
  const { parseLink } = useLocalization()
  const { country, language } = useContext(CountryLangCtx)

  useEffect(() => {
    // @todo do we need to localize requests by searching for axios.get? -RS
    axios
      .all([
        axios.get(`${getServiceBaseUrl}/flags/showMessage`),
        axios.get(`${getServiceBaseUrl}/messages/all`, { headers: { 'Accept-Language': `${language}-${country.toUpperCase()}` } })
      ])
      .then(([flags, messages]) => {
        const { status } = flags.data
        const [firstMessage] = messages.data
        const messageContent = firstMessage?.content

        if (stringToBool(status)) {
          setShow(true)
          setRemoteMessage(messageContent)
          setNotificationClass(NORMAL) // DANGER and NORMAL are other options
        }
      })
      .catch(err => {
        console.warn('Error fetching showMessage flag', err)
      })
  }, [messageKey, language, country])

  const dismissMessage = useCallback(() => {
    setShow(false)
  }, [])

  if (!show) {
    return null
  }

  const output = (
    <div className='RemoteNotification'>
      <div className={`notification ${notificationClass} RemoteNotification__notification`} aria-live='polite'>
        <div className='section is-small py-3'>
          <div className='container'>
            <div className='level is-mobile'>
              <div className='level-left is-flex-shrink-1'>
                <div className='level-item is-flex-shrink-1'>
                  <p>
                    { remoteMessage }
                  </p>
                </div>
                <div className='level-item is-hidden-mobile'>
                  <a className='RemoteNotification__cta RemoteNotification__cta--store button is-white is-small is-uppercase' href={parseLink(externalLinks.storeLocator)} rel="noopener noreferrer" target='_blank'><FormattedMessage id='TEXT_FIND_A_STORE' /></a>
                </div>
                <div className='level-item is-hidden-tablet'>
                  <button className='button is-warning is-large' onClick={dismissMessage}><SVG aria-hidden='true' className='svg' src={CloseImage} /></button>
                </div>
              </div>
              <div className='RemoteNotification__cta RemoteNotification__cta--close level-right is-hidden-mobile pl-4'>
                <div className='level-item'>
                  <button onClick={dismissMessage} className='button is-warning is-small is-uppercase'>
                    <span><FormattedMessage id='REMOTE_NOTIFICATION_CLOSE' /></span>
                    <span className='icon'>
                      <SVG aria-hidden='true' className='svg' src={CloseImage} />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  if (typeof children === 'function') {
    return children(output)
  }

  return output
}

RemoteNotification.propTypes = {
  children: PropTypes.func,
  messageKey: PropTypes.string.isRequired
}

export default RemoteNotification
