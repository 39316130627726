import './ArrowBtn.scss'

import React from 'react'
import ArrowIcon from 'src/svg/arrow-right.svg'
import SVG from 'react-inlinesvg'

const BASE_CLASS = 'ArrowBtn'

const DIRECTIONS = {
  LEFT: 'l',
  RIGHT: 'r'
}

const SIZES = {
  SMALL: 'small',
  NORMAL: 'normal',
  LARGE: 'large'
}

const directionMap = {
  [DIRECTIONS.LEFT]: `${BASE_CLASS}__icon--dir-l`,
  [DIRECTIONS.RIGHT]: `${BASE_CLASS}__icon--dir-r`
}

const sizeMap = {
  [SIZES.SMALL]: `${BASE_CLASS}--sz-sm`,
  [SIZES.NORMAL]: `${BASE_CLASS}--sz-md`,
  [SIZES.LARGE]: `${BASE_CLASS}--sz-lg`
}

function ArrowBtn ({ direction = DIRECTIONS.RIGHT, size = SIZES.NORMAL, label = '', ...other }) {
  return (
    <button {...other} className={`${BASE_CLASS} ${sizeMap[size]}`}>
      {label && <span className='is-sr-only'>{label}</span>}
      <SVG aria-hidden='true' className={`svg ${BASE_CLASS}__icon ${directionMap[direction]}`} src={ArrowIcon} />
    </button>
  )
}

ArrowBtn.DIRECTIONS = DIRECTIONS
ArrowBtn.SIZES = SIZES

export default ArrowBtn
