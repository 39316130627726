import React, { forwardRef } from 'react'
import './RecaptchaModal.scss'
import { FormattedMessage } from 'react-intl'
import Recaptcha from 'components/Recaptcha/Recaptcha'

const baseName = 'recaptcha-modal'
const modalBgClassName = `${baseName}__bg`
const modalContentClassName = `${baseName}__content`
const modalErrorClassName = `${modalContentClassName}__error`
const RecaptchaModalView = forwardRef(function RecaptchaModal (props, ref) {
  const { showModal } = props
  return <div style={{ visibility: showModal ? 'visible' : 'hidden' }} className={`${baseName} modal is-active`}>
    <div className={`${modalBgClassName} modal-background`} />
    <div className={`${modalContentClassName} has-background-white`}>
      <div className={`${modalErrorClassName} message is-danger`}><FormattedMessage id='CHECKOUT_ERRORS_SOLVE_RECAPTCHA' /></div>
      <div><Recaptcha ref={ref} /></div>
    </div>
  </div>
})

export default RecaptchaModalView
