import React from 'react'
import colors from 'app/constants/colors'

export default function PeelStickIcon ({ isOnWhiteBG }) {
  const darker = colors.black
  const dark = colors.tricornBlack
  const strokeColor = isOnWhiteBG ? dark : darker

  return (
    <svg aria-hidden='true' className={'svg-inline PeelStickIcon'} viewBox='0 0 24 24' fill='none' width='100%' xmlns='http://www.w3.org/2000/svg'>
      <path opacity='0.33' fillRule='evenodd' clipRule='evenodd' d='M 4.5 3.75 H 19.5 C 19.6989 3.75 19.8897 3.829 20.0303 3.9697 C 20.171 4.1103 20.25 4.3011 20.25 4.5 L 20.25 15 H 15.0003 L 15.0003 20.25 L 4.5 20.25 C 4.3011 20.25 4.1103 20.171 3.9697 20.0303 C 3.829 19.8897 3.75 19.6989 3.75 19.5 V 4.5 C 3.75 4.3011 3.829 4.1103 3.9697 3.9697 C 4.1103 3.829 4.3011 3.75 4.5 3.75 Z Z' fill = {strokeColor} />
      <path d='M19.5 3.75L4.49996 3.75C4.30105 3.75 4.1103 3.82902 3.96965 3.96968C3.829 4.11033 3.74999 4.30109 3.75 4.5L3.75 19.5C3.75 19.6989 3.82901 19.8897 3.96965 20.0303C4.1103 20.171 4.30106 20.25 4.49996 20.25L15.0003 20.25L15.0003 15L20.25 15L20.25 4.5C20.25 4.30109 20.171 4.11033 20.0303 3.96968C19.8897 3.82902 19.6989 3.75 19.5 3.75Z' stroke = {strokeColor} strokeWidth = '1' strokeLinecap = 'round' />
      <path d='M15 20.25L20.2507 15' stroke = {strokeColor} strokeWidth = '1' strokeLinecap = 'round' />
      <line x1='6.91406' y1='6.61609' x2='8.41406' y2='6.61609' stroke ={strokeColor} strokeWidth = '1' strokeLinecap = 'round' />
    </svg>
  )
}
