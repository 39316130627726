import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useEffectAfterMount from 'app/hooks/useEffectAfterMount'
import uniqueId from 'lodash/uniqueId'
import Samples from 'components/DeepLinkCartModal/Samples'
import UnavailableView from 'components/DeepLinkCartModal/UnavailableView'

function DeepLinkCartModal (props) {
  // addToCart is a flag to show the christian siriana add to cart
  const { closeHandler, addToCartHandler, itemData, addToCart, unavailable, goToPeelAndStick, goToTop50 } = props
  const [modalId] = useState(uniqueId())

  const closeBtnRef = useRef()

  useEffect(() => {
    if (closeBtnRef.current) closeBtnRef.current.focus()
  }, [closeBtnRef.current])

  useEffect(() => {
    const closeOnEsc = e => (e.keyCode === 27) ? closeHandler() : null
    document.addEventListener('keydown', closeOnEsc)
    return () => document.removeEventListener('keydown', closeOnEsc)
  }, [])

  const { location } = useHistory()

  useEffectAfterMount(() => {
    closeHandler()
  }, [location])

  return (
    <section className='OptionsModal modal is-active' id='cartModal' aria-labelledby={`modalTitle_${modalId}`}>
      {/* eslint-disable-next-line */}
        <div className='OptionsModal__bg modal-background' onMouseUp={closeHandler} />
      <button ref={closeBtnRef} onClick={closeHandler} className='OptionsModal__close-btn modal-close is-large' aria-label='close' />
      <div className='modal-content has-background-white dl-modal-content' >
        {unavailable
          ? <UnavailableView
          goToPeelAndStick={goToPeelAndStick}
          goToTop50={goToTop50} />
          : <Samples
            addToCart={addToCart}
            data={itemData}
            closeHandler={closeHandler}
            addToCartHandler={addToCartHandler} />}
      </div>
    </section>
  )
}

export default DeepLinkCartModal
