import React from 'react'
import { FormattedMessage } from 'react-intl'
import phone from 'app/constants/phone'
import externalLinks from 'app/constants/externalLinks'
import useLocalization from 'app/hooks/useLocalization'

export default (props) => {
  const { parseLink } = useLocalization()
  return (
    <p {...props}>
      <FormattedMessage id='CONTACT_QUICK_LINKS_HEADING' />
      <br />
      <FormattedMessage id='CUSTOMER_SERVICE_PHONE_NUMBER' values={{
        customerServicePhoneFormatted: phone.customerServiceFormatted,
        customerServicePhoneLink: chunk => <a href={`tel:${phone.customerService}`} target='_blank' rel="noreferrer">{chunk}</a>,
        customerServicePhonePretty: phone.customerServicePretty
      }} />
      <br />
      <a href={parseLink(externalLinks.contactUs)}><FormattedMessage id='CONTACT_QUICK_LINKS_FEEDBACK' /></a>
    </p>
  )
}
