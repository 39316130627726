function makeFamilyFilter (family, exclusive = false) {
  return function (color = {}) {
    const { colorFamilyNames = [] } = color
    const matches = colorFamilyNames.filter((name) => name === family)
    const isMatch = matches.length === 1
    const isOnly = matches.length === colorFamilyNames.length

    return exclusive ? isMatch && isOnly : isMatch
  }
}

// @todo right now these values do not change by language, if they ever do, these will break -RS  (Amended by TF after adding Historic and Timeless)
export const isColorEde = makeFamilyFilter('Designer Color Collection')
export const isColorOnlyEde = makeFamilyFilter('Designer Color Collection', true)
export const isColorHistoric = makeFamilyFilter('Historic Color')
export const isColorTimeless = makeFamilyFilter('Timeless Color')
