/* eslint-disable jsx-a11y/no-onchange */
import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { ErrorMessage } from 'react-hook-form'
import { withIsLoading } from './withIsLoading'
import { countries as formCountries } from '../OrderFormConstants'
import withUniqueId from 'app/HOCs/withUniqueId'
import { sortCountries } from 'app/Utils/helpers'
import CountryLangCtx from 'app/contexts/countryLang/CountryLangCtx'
import countries from 'app/constants/countries'

export const Country = withUniqueId(injectIntl(withIsLoading(({ registerAs, isLoading, errors, register, intl, id }) => {
  const { country } = useContext(CountryLangCtx)
  const defaultCountry = country === countries.us ? countries.us : countries.ca
  const [sortedCountries, setSortedCountries] = useState([])

  useEffect(() => {
    setSortedCountries(sortCountries(formCountries, defaultCountry))
  }, [])

  return (
    <div className='field'>
      <label className='label has-text-weight-normal' htmlFor={id}>
        <FormattedMessage id='CHECKOUT_FIELDS_COUNTRY' />
        <div className='control'>
          <div className={`select ${errors.country ? 'is-danger' : ''} ${isLoading ? 'is-loading' : ''}`} style={{ width: '100%' }}>
            <select
              disabled
              readOnly={isLoading}
              style={{ width: '100%' }}
              id={id}
              name={registerAs}
              value={sortedCountries?.[0]?.value}
              ref={register}
              autoComplete='country'>
              {sortedCountries.filter(ctry => ctry.value.toLowerCase() === country)
                .map(ctry => <option key={ctry.value} value={ctry.value}>{ctry.label}</option>)}
            </select>
          </div>
          <ErrorMessage name={registerAs} errors={errors}>
            {({ message }) => <p className='help is-danger'>{message}</p>}
          </ErrorMessage>
        </div>
      </label>
    </div>
  )
})))
