import './Toggle.scss'
import React from 'react'

function Toggle ({ isOn = false }) {
  return (
    <div aria-hidden='true' className={`Toggle ${isOn ? 'Toggle--on' : ''}`} />
  )
}

export default Toggle
