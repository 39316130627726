import axios from 'axios'
import store from '../Store/Store'
import { isIntentionalCancellation, makeErrorResponse } from './ajax.util'

import { getServiceBaseUrl } from './serviceUtils'
import { getBackendErrorTranslation } from 'app/Utils/languageUtil'

axios.defaults.withCredentials = true

function updateOrderByOrderId (orderId, formData, cancelToken = null, intl) {
  const method = 'POST'
  const url = `${getServiceBaseUrl}/orders/${orderId}`
  const newsletterOptIn = (formData.newsletterOptIn) ? 1 : 0

  return new Promise((resolve, reject) => {
    axios({
      method,
      url,
      data: {
        ...formData,
        newsletterOptIn
      },
      cancelToken
    })
      .then(({ data }) => resolve(data))
      .catch(({ response, message }) => {
        const errors = []
        if (isIntentionalCancellation(message)) return resolve() // user cancellation
        if (!response && message) {
          errors.push(message)
          return reject(makeErrorResponse(true, false, errors))
        }

        const { status, data } = response

        if (status === 400 && data.length) {
          data.map(msg => getBackendErrorTranslation(msg, intl)).forEach(msg => errors.push([msg.title, msg.detail].filter(Boolean).join(' ')))
        } else {
          errors.push(intl.formatMessage({ id: 'ERROR_ORDER' }))
        }

        reject(makeErrorResponse(false, false, errors))
      })
  })
}

function SubmitDataBuilder () {
  const data = {}
  // eslint-disable-next-line no-return-assign
  this.setAuthId = (authId) => data.authId = authId
  // eslint-disable-next-line no-return-assign
  this.setGrecaptchaResponse = (resp) => data.grecaptchaResponse = resp
  this.getData = () => {
    return {
      ...data
    }
  }
}

function getGenericOrderSubmissionError (isFromPaymentPage, intl) {
  if (isFromPaymentPage) {
    return `${intl.formatMessage({ id: 'ERROR_ORDER_RETRY_TITLE' })} ${intl.formatMessage({ id: 'ERROR_ORDER_RETRY_DETAIL' })}`
  }

  return intl.formatMessage({ id: 'ERROR_ORDER' })
}

function submitOrder (orderId, cancelToken = null, authId = '', intl, isFromPaymentPage = false) {
  const { recaptchaResponse: grecaptchaResponse, recaptchaEnabled } = store.getState().orderForm
  const dataBuilder = new SubmitDataBuilder()
  dataBuilder.setGrecaptchaResponse(recaptchaEnabled ? grecaptchaResponse : '')
  dataBuilder.setAuthId(authId)
  const data = dataBuilder.getData()

  return new Promise((resolve, reject) => {
    axios
      .post(`${getServiceBaseUrl}/orders/${orderId}/submit`, data, { cancelToken })
      .then(({ data }) => resolve(data))
      .catch(({ response, message }) => {
        const errors = []
        if (isIntentionalCancellation(message)) return resolve() // user cancellation
        if (!response && message) {
          errors.push(message)
          return reject(makeErrorResponse(true, false, errors))
        }

        const { status, data } = response

        if (status === 400 && data.length) {
          data.map(msg => getBackendErrorTranslation(msg, intl)).forEach(msg => errors.push([msg.title, msg.detail].filter(Boolean).join(' ')))
        } else {
          // payment page has a retry button; there is a different flow for non-pay orders
          const genericError = getGenericOrderSubmissionError(isFromPaymentPage, intl)
          errors.push(genericError)
        }

        reject(makeErrorResponse(false, false, errors))
      })
  })
}

export {
  updateOrderByOrderId,
  submitOrder
}
