export const SEE_MODAL = 'SEE_MODAL'
export const setSeeModal = ({ title, content }) => ({ type: SEE_MODAL, payload: { title, content } })

export const HIDE_MODAL = 'HIDE_MODAL'
export const setHideModal = () => ({ type: HIDE_MODAL })

export const SIRIANO_UNAVAILABLE = 'SIRIANO_UNAVAILABLE'
export const setSirianoUnavailable = (isUnAvailable = false) => {
  return { type: SIRIANO_UNAVAILABLE, payload: isUnAvailable }
}
