import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import React from 'react'
import usePrependPath from 'app/hooks/usePrependPath'

function VisitFaq () {
  const prependPath = usePrependPath()

  return <FormattedMessage id='VISIT_FAQ' values={{
    a: (chunks) => {
      return <Link className='text-link has-text-weight-semibold' to={prependPath('faq')}>{chunks}</Link>
    },
    span: (chunks) => {
      return <span className='mr-3'>{chunks}</span>
    }
  }} />
}

export default VisitFaq
