// this stays here (vs going into prism) since it is unique to this property
import {
  AnalyticsEvents,
  AnalyticsInteractionTypes,
  AnalyticsToolNames,
  GTMDataBuilder, pushToDataLayer
} from '@prism/toolkit'
import labels from 'app/constants/adobeAnalytics'
import { getDevelopmentEnvFromHostname, getPageInfoFromURL } from 'app/Utils/url.util'

export const ANALYTICS_FILTER_TYPES = Object.freeze({
  PEEL_AND_STICK: 'peel_and_stick'
})

function _trackColorFamilyOrCollection (label, isColorFamily = true) {
  const gtmDataBuilder = new GTMDataBuilder()
  gtmDataBuilder.setEvent(AnalyticsEvents.colorTool)
  gtmDataBuilder.setToolName(AnalyticsToolNames.colorWall)
  if (isColorFamily) {
    gtmDataBuilder.setColorFamily(label)
  } else {
    gtmDataBuilder.setColorCollection(label)
  }
  gtmDataBuilder.setInteractionType(AnalyticsInteractionTypes.filter)

  pushToDataLayer(gtmDataBuilder.getData())
}

// Adobe colorwall_familyselection and colorwall_collection event
function _trackAdobeAnayticsColorFamilyOrCollection (label, isColorFamily = true) {
  // Adobe page info
  const environment = getDevelopmentEnvFromHostname(window.location.hostname)
  const pageInfo = getPageInfoFromURL()
  const _localStorageSupport = window.localStorage
  let adobeLanguage = 'en-US'
  if (_localStorageSupport) {
    adobeLanguage = window.localStorage.getItem('ADOBE_LANGUAGE')
  }
  // End Adobe page info

  const adobeEvent = {
    event: isColorFamily ? labels.evPrefix + 'familyselection' : labels.evPrefix + 'collection',
    eventinfo: {
      eventName: isColorFamily ? labels.evNamePrefix + 'Family Selection' : labels.evNamePrefix + 'Collection',
      toolName: labels.toolName
    },
    pageInfo: {
      pageName: pageInfo.pageName,
      pageURL: pageInfo.pageURL,
      previousPageName: pageInfo.previousPageName,
      environment,
      businessCountryLanguage: adobeLanguage
    },
    applicationInfo: {
      applicationName: labels.applicationName,
      applicationType: labels.applicationType,
      applicationOS: labels.applicationOS
    }
  }

  if (isColorFamily) {
    adobeEvent.eventinfo.colorFamily = label
  } else {
    adobeEvent.eventinfo.collectionsName = label
  }
  window.adobeDataLayer.push(adobeEvent)
}
// Adobe Adobe colorwall_familyselection and colorwall_collection event

export function trackColorFamily (label) {
  _trackColorFamilyOrCollection(label, true)
  _trackAdobeAnayticsColorFamilyOrCollection(label, true)
}

export function trackColorCollection (label) {
  _trackColorFamilyOrCollection(label, false)
  _trackAdobeAnayticsColorFamilyOrCollection(label, false)
}
