import './OptionsModalOption.scss'
import React, { useEffect, useState, useCallback } from 'react'
import CartIcon from 'src/svg/cart.svg'
import CheckIcon from 'src/svg/check.svg'
import SVG from 'react-inlinesvg'
import PeelStickIcon from 'components/PeelStickIcon/PeelStickIcon'
import ChipsIcon from 'components/ChipsIcon/ChipsIcon'
import useSwatch from 'app/hooks/products/useSwatch'
import useChip from 'app/hooks/products/useChip'
import useCart from 'app/hooks/useCart'
import MorphingRenderer from 'app/components/MorphingRenderer/MorphingRenderer'
import { FormattedNumber, FormattedMessage } from 'react-intl'
import { LIMIT_ITEM_PEEL_STICK } from 'app/constants/product'
import extractFullColorNameAndNumber from 'app/Utils/extractFullColorNameAndNumber.util'
import withUniqueId from 'app/HOCs/withUniqueId'
import noop from 'lodash/noop'

const TYPES = { CHIP: 'CHIP', SWATCH: 'SWATCH' }

const ERROR_CLASS = 'has-text-left-tablet has-text-center-mobile has-text-weight-semibold has-text-danger'

const OptionsModalOption = withUniqueId(function OptionsModalOption ({ id, type, color, className = '', onAdd = noop, ...other }) {
  const isChip = type === TYPES.CHIP
  const isSwatch = type === TYPES.SWATCH

  const swatchData = useSwatch(color)
  const chipData = useChip(color)
  const { add, canAddChips, itemsSwatches } = useCart()
  const { inStock, error, price, productId, loading } = isChip ? chipData : swatchData
  const [limitReachedMessageKey, setLimitReachedMessageKey] = useState()
  const [hasAdded, setHasAdded] = useState(false)

  useEffect(() => {
    if (hasAdded) return
    if (isChip) return setLimitReachedMessageKey(!canAddChips ? 'OPTIONS_MODAL_LIMIT_CHIPS' : undefined)
    const { qty } = itemsSwatches.filter((item) => {
      return item.productId.toLowerCase() === productId?.toLowerCase()
    })[0] || {}
    setLimitReachedMessageKey(qty >= LIMIT_ITEM_PEEL_STICK ? 'OPTIONS_MODAL_LIMIT_SWATCHES' : undefined)
  }, [itemsSwatches, canAddChips, isChip, productId, hasAdded])

  const handleAdd = useCallback(() => {
    if (hasAdded) return

    setHasAdded(true)
    add(productId)
    onAdd()
  }, [productId, hasAdded])

  const displayPrice = price === 0
    ? <span className='has-text-weight-bold is-uppercase'><FormattedMessage id='PRICE_FREE' /></span>
    : <FormattedMessage id='PRICE_EACH_OR_4_PRICE' values={{
      priceA: <FormattedNumber style='currency' value={price} currency='USD' />,
      wrapPriceA: chunk => <span className='has-text-weight-bold'>{chunk}</span>,
      priceB: <FormattedNumber style='currency' value={3.50} currency='USD' />,
      wrapPriceB: chunk => <span className='has-text-weight-bold has-text-green'>{chunk}</span>,
      line: chunk => chunk
    }} />

  const displayTitle = isChip
    ? <FormattedMessage id='2X3_CHIPS' values={{ count: 1 }} />
    : <FormattedMessage id='8X8_PEEL_AND_STICK' />

  const srTitle = <FormattedMessage id='X_SAMPLE_OF_Y' values={{
    x: displayTitle,
    y: extractFullColorNameAndNumber(color)
  }} />

  const getCTAButton = (isLoading, isInStock, hasLimit) => {
    if (!isLoading && error) {
      return <p id={`${id}_description`} className={ERROR_CLASS}><FormattedMessage id='ERROR_NOT_YET' /></p>
    }

    if (!isInStock) {
      return <p id={`${id}_description`} className={ERROR_CLASS}><FormattedMessage id='ERROR_OUT_OF_STOCK' /></p>
    }

    if (hasLimit) {
      return <p id={`${id}_description`} className={ERROR_CLASS}><FormattedMessage id={limitReachedMessageKey} /></p>
    }

    return (
      <button onClick={handleAdd} className={!hasAdded ? 'button is-dark is-uppercase' : 'button is-text is-static added-to-cart'}>
        <MorphingRenderer
          states={[CartIcon, CheckIcon]}
          play={hasAdded}
          initialState={0}
          durationBetweenStates={1000}
          stateRenderer={(iconSrc, i) => <>
            <span className='is-sr-only'><FormattedMessage id='X_ADDED_TO_CART' values={{ x: srTitle }} /></span>
            <span key={i} className={`icon added-to-cart__icon added-to-cart__icon--${i}`}><SVG aria-hidden='true' src={iconSrc} /></span>
            <span aria-hidden='true'><FormattedMessage id='ADDED_TO_CART' /></span>
          </>}
        >
          <span className='is-sr-only'><FormattedMessage id='ADD_X_TO_CART_FOR_Y' values={{
            x: srTitle,
            y: displayPrice
          }} /></span>
          <span aria-hidden='true'><FormattedMessage id='ADD_TO_CART' /></span>
        </MorphingRenderer>
      </button>
    )
  }

  return (
    <section aria-labelledby={`#${id}`} aria-describedby={`${id}_description`} className={`${className} has-text-centered-mobile`} {...other}>
      <div className='mb-5'>
        <div className='OptionsModalOption__icon-wrapper'>
          <div className='OptionsModalOption__icon-wrapper__inner'>{isSwatch ? <PeelStickIcon isOnWhiteBG /> : <ChipsIcon isOnWhiteBG />}</div>
        </div>
        <h3 className='mb-0 is-size-7 is-size-5-tablet has-text-weight-semibold' id={id}>
          <span aria-hidden='true'>{displayTitle}</span>
          <span className='is-sr-only'>{srTitle}</span>
        </h3>
        <p style={{ minHeight: '1.5em' }}>
          {/* don't show prices if there's an error with the product */}
          {!error ? displayPrice : <>&nbsp;</>}
        </p>
      </div>
      {getCTAButton(loading, inStock, limitReachedMessageKey)}
    </section>
  )
})

OptionsModalOption.TYPES = TYPES

export default OptionsModalOption
