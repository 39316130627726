import React, { useContext } from 'react'
import PackageIcon from 'src/svg/package.svg'
import SVG from 'react-inlinesvg'

import './LimitedOfferMessage.scss'
import { FormattedMessage } from 'react-intl'
import CountryLangCtx from 'app/contexts/countryLang/CountryLangCtx'
import countries from 'app/constants/countries'

export default function LimitedOfferMessage () {
  const { country } = useContext(CountryLangCtx)
  const isUS = country === countries.us

  return (
    <article className='media LimitedOfferMessage'>
      <figure className='media-left'>
        <SVG aria-hidden='true' className='image is-48x48 svg-outline' src={PackageIcon} />
      </figure>
      <div className='media-content'>
        <div className='content has-text-weight-semibold'>
          {isUS ? <div className='mb-0 is-uppercase is-size-7 has-text-weight-bold'><FormattedMessage id='LTO_LTO' /></div> : null}
          <div className='mb-0'><FormattedMessage id='LTO_FREE_SHIPPING' values={{
            strong: chunks => (<strong>{chunks}</strong>),
            div: chunks => (<div>{chunks}</div>)
          }} /></div>
          <p className='mb-0' />
        </div>
      </div>
    </article>
  )
}
