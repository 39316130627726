import React, { useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import countries from 'app/constants/countries'
import TopNav from 'components/TopNav/TopNav'
import NextSteps from 'components/NextSteps/NextSteps'
import RemoteNotification from 'components/RemoteNotification/RemoteNotification'
import FAQ from 'components/FAQ/FAQ'
import Footer from 'components/Footer/Footer'
import ContactUs from 'components/CTAs/ContactUs/ContactUs'
import externalLinks from 'app/constants/externalLinks'
import phone from 'app/constants/phone'
import CountryLangCtx from 'app/contexts/countryLang/CountryLangCtx'
import useResetNavigationTo from 'app/hooks/useResetOnNavigateTo'
import useTitle from 'app/hooks/useTitle'
import useLocalization from 'app/hooks/useLocalization'
import './PageFAQ.scss'

function PageFAQ () {
  const { parseLink } = useLocalization()
  const { country } = useContext(CountryLangCtx)
  const { formatMessage } = useIntl()

  useResetNavigationTo()
  useTitle(formatMessage({ id: 'PAGE_TITLE_FAQ' }))

  return (
    <>
      <RemoteNotification messageKey='REMOTE_MESSAGE_CONTENT' />
      <TopNav />
      <div className='has-text-dark'>
        <section className='hero'>
          <div className='hero-body pb-0'>
            <div className='container has-text-centered'>
              <h1 className='title is-size-4-mobile mb-3'><FormattedMessage id='PAGE_FAQ_HEADING' /></h1>
              <h2 className='is-size-5 is-size-6-mobile mb-5'><FormattedMessage id='PAGE_FAQ_SUBHEADING' /></h2>
              <FormattedMessage id='CUSTOMER_SERVICE_INFO' values={{
                contactLink: chunk => <a href={parseLink(externalLinks.contactUs)} target='_blank' rel="noreferrer">{chunk}</a>,
                customerServicePhoneLink: chunk => <a href={`tel:${phone.customerService}`} target='_blank' rel="noreferrer">{chunk}</a>,
                customerServicePhoneNumber: phone.customerServiceFormatted,
                line: chunk => <p>{chunk}</p>
              }} />
            </div>
          </div>
        </section>

        <section className='section'>
          <div className='container'>
            <FAQ />
          </div>
        </section>
        {country === countries.us && <NextSteps />}
        <section className='section pb-0'>
          <div className='container has-text-centered'>
            <ContactUs />
          </div>
        </section>
      </div>
      <Footer />
    </>
  )
}

export default PageFAQ
