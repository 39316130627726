import React from 'react'
import PropTypes from 'prop-types'
import './Callout.scss'

function Callout ({ positive = false, negative = false, children = null }) {
  return <div className='Callout'>
    <div className={`Callout__contents ${positive ? 'Callout__contents--positive' : ''} ${negative ? 'Callout__contents--negative' : ''}`}>
      {children}
    </div>
  </div>
}

Callout.propTypes = {
  negative: PropTypes.bool,
  positive: PropTypes.bool
}

export default Callout
