import React from 'react'
import './Media.scss'

function Media ({ className = '', children }) {
  return <div className={`${className} Media`}>
    <div className='Media__inner media'>
      {children}
    </div>
  </div>
}

function Graphic ({ className = '', children }) {
  return <div className={`${className} Media__image media-left`}>
    <p className='image is-48x48'>{children}</p>
  </div>
}

function Content ({ className = '', children }) {
  return <div className={`${className} Media__content media-content`}>
    {children}
  </div>
}

Media.Graphic = Graphic
Media.Content = Content

export default Media
