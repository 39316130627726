// This function reuses the old objects type to simply replace the title and description with proper translations
import { isArray } from 'lodash/lang'

export function getBackendErrorTranslation (msg, intl) {
  // copy message, since we have to try to flatten the details for fallback
  const msgKey = 'messageId'
  const message = {
    title: msg.title,
    detail: flattenMessageDetails(msg.detail),
    status: msg.status
  }

  message[msgKey] = msg[msgKey]

  if (!message[msgKey]) {
    console.warn('MALFORMED MESSAGE: missing ID:', msg)
    return message
  }
  // trim these since we need to store an empty string for some that have no value.
  message.title = intl.formatMessage({ id: `${msg[msgKey]}_TITLE` }).trim()
  message.detail = intl.formatMessage({ id: `${msg[msgKey]}_DETAIL` }).trim()

  return message
}

// some backend messages can be string[] so check and then flatten
function flattenMessageDetails (msg) {
  if (isArray(msg)) {
    return msg.join(' ')
  }
  return msg
}
