import React from 'react'
import { useSelector } from 'react-redux'

export const withIsLoading = WrappedComponent => props => {
  const formLoading = useSelector(state => state.orderForm.isLoading)
  const cartLoading = useSelector(state => state.cart.loading)
  return (
    <WrappedComponent {...props} isLoading={cartLoading || formLoading} />
  )
}
