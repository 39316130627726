// !Keep these at the top to place earlier in style compilation
// local Bulma style overrides
import 'src/layout/bulma.scss'
import '@prism/toolkit/dist/index.css'
// site layout styles
import 'src/layout/fonts.scss'
import 'src/layout/autosizer.scss'
import 'src/layout/spacing.scss'
import 'src/layout/svg.scss'
import 'src/layout/general.scss'

import React from 'react'
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import PageFAQ from 'components/PageFAQ/PageFAQ'
import PageIndex from 'components/PageIndex/PageIndex'
import PageCheckout from 'components/PageCheckout/PageCheckout'
import PagePayment from 'components/PagePayment/PagePayment'
import PageCart from 'components/PageCart/PageCart'
import PageConfirmation from 'components/PageConfirmation/PageConfirmation'

import countries from 'app/constants/countries'
import languages from 'app/constants/languages'
import CountryLangCtx from 'app/contexts/countryLang/CountryLangCtx'
import withReduxAndIntl from 'app/HOCs/withReduxAndIntl'
import { fetchEverything } from 'app/Store/actions/products'
import withCartOverlay from 'app/HOCs/withCartOverlay'
import withGenericOverlay from 'app/HOCs/withGenericOverlay'
import withSnackbar from 'app/HOCs/withSnackbar'

const Page = withReduxAndIntl(withSnackbar(withCartOverlay(withGenericOverlay(function Page ({ page = '', ...props } = {}) {
  const dispatch = useDispatch()
  dispatch(fetchEverything())

  switch (page) {
    case 'faq': {
      return <PageFAQ {...props} />
    }
    case 'confirmation': {
      return <PageConfirmation {...props} />
    }
    case 'checkout': {
      return <PageCheckout {...props} />
    }
    case 'payment': {
      return <PagePayment {...props} />
    }
    case 'cart': {
      return <PageCart {...props} />
    }
    default: {
      return <PageIndex {...props} />
    }
  }
}))))

export default function SwColorChips ({ country, lang, ...passThruProps }) {
  /**
   * US prefix dev with country and nothing for prod
   * CA prefix dev with country/lang and just lang for prod
   */

  if (US_ONLY || country === countries.us) {
    return <BrowserRouter>
      <Switch>
        <Route exact path={['/view/:collection/', '/:page/', '/']} render={({ match: { params: { collection, page } } }) => (
          <CountryLangCtx.Provider value={{ language: lang, country }}>
            <Page page={page} {...passThruProps} initialCollection={collection} />
          </CountryLangCtx.Provider>
        )} />
        <Redirect to='/' />
      </Switch>
    </BrowserRouter>
  } else if (country === countries.ca) {
    // Canada routing
    return <BrowserRouter>
      <Switch>
        <Route exact path={`/${languages.en}`} render={() => (
          <CountryLangCtx.Provider value={{ language: languages.en, country }}>
            <Page {...passThruProps} />
          </CountryLangCtx.Provider>
        )} />
        <Route exact path={`/${languages.fr}`} render={() => (
          <CountryLangCtx.Provider value={{ language: languages.fr, country }}>
            <Page {...passThruProps} />
          </CountryLangCtx.Provider>
        )} />
        <Route path='/:lang/view/:collection/' render={({ match: { params: { lang, collection } } }) => {
          return <CountryLangCtx.Provider value={{ language: lang, country }}>
            <Page {...passThruProps} initialCollection={collection} />
          </CountryLangCtx.Provider>
        }} />
        <Route path='/:lang/:page/' render={({ match: { params: { lang, page } } }) => {
          return <CountryLangCtx.Provider value={{ language: lang, country }}>
            <Page page={page} {...passThruProps} />
          </CountryLangCtx.Provider>
        }} />
        <Redirect to={`/${languages.en}`} />
      </Switch>
    </BrowserRouter>
  }
}
