import React from 'react'
import PropTypes from 'prop-types'
import './RequiredAsterisk.scss'
import { FormattedMessage } from 'react-intl'

const RequiredAsterisk = (props) => {
  const { required = false } = props

  return (
    <span className={`RequiredAsterisk ${required ? 'RequiredAsterisk--required' : ''}`}>
      {required
        ? (
        <>
          <FormattedMessage id='CHECKOUT_FORM_REQUIRED_FIELD' />
        </>
          )
        : <FormattedMessage id='REQ_ASTERISK_OPTIONAL' values={{
          span: (chunks) => {
            return <span className='is-sr-only'>{chunks}</span>
          }
        }} />}
    </span>
  )
}

RequiredAsterisk.propTypes = {
  required: PropTypes.bool
}

export default RequiredAsterisk
