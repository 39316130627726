import React from 'react'
import defaultKitImage from 'src/images/kit-image.png'

import './KitImage.scss'

export default function (props) {
  const { imgUrl, product } = props
  const imgSrc = imgUrl ?? defaultKitImage

  return <div className='kit-image__wrapper'>
    <img src={imgSrc} alt={product.description} />
  </div>
}
