import React from 'react'
import RequiredAsterisk from '../RequiredAsterisk/RequiredAsterisk'
import { FormattedMessage, injectIntl } from 'react-intl'
import { ErrorMessage } from 'react-hook-form'
import { withIsLoading } from './withIsLoading'
import withUniqueId from 'app/HOCs/withUniqueId'

export const FirstName = withUniqueId(injectIntl(withIsLoading(({ isLoading, errors, register, intl, id }) => (
  <div className='field'>
    <label className='label has-text-weight-normal' htmlFor={id}>
      <FormattedMessage id='CHECKOUT_FIELDS_FIRST_NAME' /> <RequiredAsterisk required />
      <div className={`control ${isLoading ? 'is-loading' : ''}`}>
        <input
          id={id}
          readOnly={isLoading}
          className={`input ${errors.firstName ? 'is-danger' : ''}`}
          type='text'
          name='firstName'
          ref={register({
            required: intl.formatMessage({ id: 'CHECKOUT_FIELDS_FIRST_NAME_ERROR_REQUIRED' }),
            pattern: {
              message: intl.formatMessage({ id: 'CHECKOUT_FIELDS_FIRST_NAME_ERROR_PATTERN' }),
              value: /^[a-zA-Z-]*$/
            }
          })}
          autoComplete='given-name' />
        <ErrorMessage name='firstName' errors={errors}>
          {({ message }) => <p className='help is-danger'>{message}</p>}
        </ErrorMessage>
      </div>
    </label>
  </div>
))))
