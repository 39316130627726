import React, { useEffect, useState } from 'react'
import { getChunkKey } from 'app/Utils/general.utils'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import ItemsRow from 'components/DeepLinkCartModal/ItemsRow'
import SVG from 'react-inlinesvg'
import PeelStickIcon from 'components/PeelStickIcon/PeelStickIcon'
import flatten from 'lodash/flatten'

import './ChristianSirianoSamplesView.scss'
import CartIcon from 'src/svg/cart.svg'
import CheckIcon from 'src/svg/check.svg'
import MorphingRenderer from 'components/MorphingRenderer/MorphingRenderer'
import useCart from 'app/hooks/useCart'
import { useDispatch } from 'react-redux'
import { alignCartWithInventory } from 'app/Store/actions/cart'
import { formatDash } from 'components/DeepLinkCartModal/Samples'
import { formatNBSP } from 'components/ChristianSirianoBanner/ChristianSirianoBanner'

const baseName = 'csiriano'
const headerName = `${baseName}__header`
const subheading = `${baseName}__sub-heading`
const confirmBtn = `${baseName}__confirm-btn`
const content = `${baseName}__content`
const ctaBox = `${content}__cta-box`
const ctaDesc = `${ctaBox}__desc`
const psIcon = `${ctaDesc}__icon`
const ctaDescText = `${ctaDesc}__text`
const priceBox = `${ctaDesc}__price`
const buttonBox = `${ctaDesc}__btn`
const VIEWPORT_SM = 'sm'

export function getPriceAndProductId (items) {
  const product = flatten(items).find((item) => {
    return !item.isPseudoProduct
  })

  return {
    price: product.price,
    productId: product.productId,
    inStock: product.inStock
  }
}

export default function (props) {
  const { chunks, viewportSize, closeHandler } = props
  const { price, productId, inStock } = chunks.length ? getPriceAndProductId(chunks) : {}
  const { add } = useCart()
  const [hasAdded, setHasAdded] = useState(false)
  const [triggerClose, setTriggerClose] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (triggerClose) {
      setTimeout(() => {
        closeHandler()
      }, 1500)
    }
  }, [triggerClose])

  const addToCart = (e) => {
    e.preventDefault()
    setHasAdded(true)
    add(productId)
    // @todo did this to match what is happening to similar addToCart logic in PageIndex.jsx
    dispatch(alignCartWithInventory())
    setTriggerClose(true)
  }

  return (<div className={baseName}>
    <div>
      <div className={headerName}><FormattedMessage id='CHRISTIAN_SIRIANO_MODAL_HEADER' values={{ nbd: formatDash, nbsp: formatNBSP }} /></div>
      <div className={subheading}><FormattedMessage id='CHRISTIAN_SIRIANO_MODAL_SUB_HEADING' /></div>
    </div>
    <div className={content}>
      <div className='items'>
        {chunks.map((chunk, i) => {
          return <ItemsRow
            size={viewportSize}
            key={getChunkKey(chunk)}
            data={chunk}
          />
        })}
      </div>
      <div className={ctaBox}>
        <div className={ctaDesc}>
          {/* @todo Added boolean, isOnWhiteBG, to slightly lighten the fill and stroke color */}
          <div className={psIcon}><PeelStickIcon isOnWhiteBG /></div>
          <div>
            <div className={ctaDescText}>
              <FormattedMessage id='CHRISTIAN_SIRIANO_MODAL_DESC'
                values={{
                  break: (chunks) => {
                    return viewportSize !== VIEWPORT_SM ? <br /> : ' '
                  }
                }} />
            </div>
            <div className={priceBox}><FormattedNumber style='currency' value={price} currency='USD' /></div>
          </div>
        </div>
        {/* <div className={buttonBox}><button className={confirmBtn} onClick={addToCartHandler}><FormattedMessage id='CHRISTIAN_SIRIANO_MODAL_ADD' /></button></div> */}
        <div className={buttonBox}>
          {inStock
            ? <button className={`${confirmBtn}${hasAdded ? '--alt' : ''}`} onClick={addToCart}>
            <MorphingRenderer
              states={[CartIcon, CheckIcon]}
              play={hasAdded}
              initialState={0}
              durationBetweenStates={1000}
              stateRenderer={(iconSrc, i) => <>
                <span className='is-sr-only'><FormattedMessage id='ADDED_TO_CART' /></span>
                <span key={i} className={`icon ${confirmBtn}${hasAdded ? '--alt' : ''}__icon--${i}`}><SVG aria-hidden='true' src={psIcon} /></span>
                <span aria-hidden='true'><FormattedMessage id='ADDED_TO_CART' /></span>
              </>}
            >
              <span aria-hidden='true'><FormattedMessage id='ADD_TO_CART' /></span>
            </MorphingRenderer>
          </button>
            : <p className='has-text-left-tablet has-text-center-mobile has-text-weight-semibold has-text-danger'><FormattedMessage id='ERROR_OUT_OF_STOCK' /></p>}
        </div>
      </div>
    </div>
  </div>)
}
