import React from 'react'
import RequiredAsterisk from '../RequiredAsterisk/RequiredAsterisk'
import { FormattedMessage } from 'react-intl'
import { ErrorMessage } from 'react-hook-form'
import { withIsLoading } from './withIsLoading'
import withUniqueId from 'app/HOCs/withUniqueId'
export const Address = withUniqueId(withIsLoading(({ registerAs, isLoading, errors, register, id, ...other }) => {
  return (
    <div className='field'>
      <label className='label has-text-weight-normal' htmlFor={id}>
        <FormattedMessage id='CHECKOUT_FIELDS_ADDRESS' /> <RequiredAsterisk required />
        <div className={`control ${isLoading ? 'is-loading' : ''}`}>
          <input
            {...other}
            id={id}
            readOnly={isLoading}
            className={`input ${errors.address1 ? 'is-danger' : ''}`}
            type='text'
            ref={register}
            name={registerAs} />
          <ErrorMessage name={registerAs} errors={errors}>
            {({ message }) => <p className='help is-danger'>{message}</p>}
          </ErrorMessage>
        </div>
      </label>
    </div>
  )
}))
