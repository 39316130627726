import extractChips from 'app/Utils/extractChips.util'
import extractSwatches, { extractSwatchesBundles } from 'app/Utils/extractSwatches.util'
import {
  SET_ERROR,
  SET_LOADING,
  SET_PRODUCTS
} from '../actions/products'

const initialState = {
  error: false,
  inventoryEnabled: [],
  loading: true, // default to loading
  skusChips: [],
  skusSwatches: []
}

export const products = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING: {
      return {
        ...state,
        loading: payload
      }
    }
    case SET_ERROR: {
      return {
        ...state,
        error: payload
      }
    }
    case SET_PRODUCTS: {
      const { products } = payload
      const chips = extractChips(products)
      const swatches = extractSwatches(products)
      const skuSwatchesBundles = extractSwatchesBundles(products)

      return {
        ...state,
        skusChips: chips,
        skusSwatches: swatches,
        skuSwatchesBundles
      }
    }
    default: return state
  }
}
