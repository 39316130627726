import React from 'react'
import ItemSwatch from './ItemSwatch'

export default function ItemsRow (props) {
  const { data, size } = props

  return (
    <div className='items-row'>
      {data.map((item, i) => {
        return <ItemSwatch
          size={size}
          item={item}
          key={`${item.uid}-${i}`}
        />
      })}
    </div>
  )
}
