import React, { useContext, useEffect, useState } from 'react'
import kebabCase from 'lodash/kebabCase'
import axios from 'axios'
import './ClosestStores.scss'
import useQueryParamOrderNumber from 'app/hooks/useQueryParamOrderNumber'
import useDelay from 'app/hooks/useDelay'
import HorizontalLoader from 'components/loaders/HorizonalLoader/HorizontalLoader'
import { FormattedMessage } from 'react-intl'
import { getServiceBaseUrl } from 'app/Utils/serviceUtils'
import CountryLangCtx from 'app/contexts/countryLang/CountryLangCtx'
import countries from 'app/constants/countries'
import languages from 'app/constants/languages'

const classBase = 'ClosestStores'

function ClosestStores () {
  const [orderNumber, hasNoOrderNumber] = useQueryParamOrderNumber()
  const [closestStoresError, setClosestStoresError] = useState(false)
  const [closestStores, setClosestStores] = useState()
  const [showLoader, setShowLoader] = useState(false)

  const { country, language } = useContext(CountryLangCtx)
  const isUS = country === countries.us
  const canadianStores = language === languages.en ? 'https://www.sherwin-williams.ca/en/find-a-store/' : 'https://www.sherwin-williams.ca/fr/trouver-un-magasin/'
  const baseLocatorLink = isUS ? 'https://www.sherwin-williams.com/store-locator/' : canadianStores

  useEffect(() => {
    // if we have an order number...
    if (orderNumber) {
      // ... look for stores closest to it
      axios
        .get(`${getServiceBaseUrl}/stores/${orderNumber}`)
        .then(r => r.data)
        .then(storeData => {
          if (storeData && storeData.length) {
            setClosestStoresError(false)
            setClosestStores(storeData.slice(0, 3))
          } else {
            setClosestStoresError(true)
            setClosestStores()
          }
        })
        .catch(r => {
          setClosestStoresError(true)
          setClosestStores()
        })
    }
  }, [orderNumber])

  useDelay(() => setShowLoader(true), 750)

  if (hasNoOrderNumber || closestStoresError) {
    return (
      <section className='has-text-centered'>
        <h2 className='title is-5'>
          <FormattedMessage id='VISIT_OUR_STORE_LOCATOR_TO_FIND' values={{
            cta: chunk => <a className='has-text-weight-semibold text-link' href={baseLocatorLink}>{chunk}</a>
          }} />
        </h2>
      </section>
    )
  }

  if (closestStores && closestStores.length) {
    return (
      <section className='has-text-centered'>
        <h2 className='title is-5'><FormattedMessage id='STORES_NEAREST_YOU' /></h2>
        <ol className={`${classBase}`}>
          {closestStores.filter(storeInfo => {
            return storeInfo.city && storeInfo.state && storeInfo.storeNum
          }).map((storeInfo, i) => {
            const { city, state, zip, name, storeNum, address } = storeInfo
            const link = `${baseLocatorLink}paint-store/${kebabCase(city)}/${state?.toLowerCase()}/${storeNum}`
            const shortZip = zip?.split('-')[0] ?? ''
            return country === countries.ca
              ? (<li key={i} className={`${classBase}__store`}>
              <b>{name} #{storeNum}</b><br />
              {address}<br />
              {city}, {state} {shortZip}
            </li>)
              : (<li key={i} className={`${classBase}__store`}>
              <a className='text-link has-text-weight-bold' href={link}>{name} #{storeNum}</a><br />
              {address}<br />
              {city}, {state} {shortZip}
            </li>
                )
          })}
        </ol>
        <p><a className='button is-uppercase' href={baseLocatorLink}><FormattedMessage id='GO_TO_STORE_LOCATOR' /></a></p>
      </section>
    )
  }

  if (showLoader) {
    return (
      <div className='ClosestStores__loader'>
        <HorizontalLoader />
      </div>
    )
  }

  return null
}

export default ClosestStores
