import React, { useCallback, useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import CloseImage from 'src/svg/close.svg'
import SVG from 'react-inlinesvg'
import './Snackbar.scss'

function Snackbar ({
  open,
  key,
  autoHideDuration = 5000, // time in ms
  handleClose,
  message,
  action: passedAction,
  classNames = '',
  variant = Snackbar.VARIANT_DEFAULT
}) {
  const onClose = () => handleClose && handleClose()
  const [timeoutId, setTimeoutId] = useState()

  const onEnter = useCallback(() => {
    const autoHide = !isNaN(autoHideDuration) && autoHideDuration > 0

    if (autoHide) {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }

      setTimeoutId(setTimeout(onClose, autoHideDuration))
    }
  }, [autoHideDuration])

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [timeoutId])

  const defaultAction = () => (
    <React.Fragment>
      <button aria-label='close' onClick={onClose}>
        <SVG aria-hidden='true' className='svg' src={CloseImage} />
      </button>
    </React.Fragment>
  )

  const variantClass = VARIANT_CSS_MAP[variant]

  return (
    <CSSTransition
      in={open}
      appear
      key={key}
      timeout={500}
      mountOnEnter
      unmountOnExit
      onEnter={onEnter}
      className={`snackbar_wrapper${classNames ? ' ' + classNames : ''}`}
      classNames='snackbar_wrapper-'>
      {/* This div will be rendered with CSSTransition classNames */}
      <div>
        <div className={`snackbar${variantClass ? ' ' + variantClass : ''}`}>
          <div className='snackbar__message'>{ message }</div>
          <div className='snackbar__action'>{ passedAction ?? defaultAction() }</div>
        </div>
      </div>
    </CSSTransition>
  )
}

Snackbar.VARIANT_INFO = 'INFO'
Snackbar.VARIANT_DANGER = 'DANGER'
Snackbar.VARIANT_DEFAULT = Snackbar.VARIANT_INFO

const VARIANT_CSS_MAP = {
  [Snackbar.VARIANT_INFO]: 'snackbar--info',
  [Snackbar.VARIANT_DANGER]: 'snackbar--danger'
}

export default Snackbar
