import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { ColorSwatch, pushToDataLayer, GTMDataBuilder, AnalyticsEvents, AnalyticsInteractionTypes, AnalyticsToolNames } from '@prism/toolkit'
import { setSeeOptions } from 'app/Store/actions/cart'
import archivedColors from './archived-colors'
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import labels from 'app/constants/adobeAnalytics'
import { getDevelopmentEnvFromHostname, getPageInfoFromURL } from 'app/Utils/url.util'
import CountryLangCtx from 'app/contexts/countryLang/CountryLangCtx'

const SwatchContentRenderer = ({ color }) => {
  const dispatch = useDispatch()
  const isUnavailable = archivedColors.has(`${color.colorNumber}`)
  // Adobe page info
  const { country, language } = useContext(CountryLangCtx)
  const businessCountryLanguage = `${language}-${country.toUpperCase()}`
  const environment = getDevelopmentEnvFromHostname(window.location.hostname)
  const pageInfo = getPageInfoFromURL()
  // End Adobe page info
  return <>
    <ColorSwatch.Title name={color.name} number={`${color.brandKey} ${color.colorNumber}`} />
    <div style={{ marginTop: 'auto' }}>
      {isUnavailable
        ? <ColorSwatch.Message><FormattedMessage id='COLOR_CHIP_NOT_AVAILABLE' /></ColorSwatch.Message>
        : <button
          className='flex items-center ring-primary focus:outline-none focus:ring-2'
          onClick={() => {
            const gtmBuilder = new GTMDataBuilder()
            gtmBuilder.setEvent(AnalyticsEvents.cta)
            gtmBuilder.setToolName(AnalyticsToolNames.colorWall)
            gtmBuilder.setColorId(`${color.brandKey}${color.colorNumber}`)
            gtmBuilder.setColorName(color.name)
            gtmBuilder.setLinkText('order samples')
            gtmBuilder.setInteractionType(AnalyticsInteractionTypes.cta)
            const gtmData = gtmBuilder.getData()

            pushToDataLayer(gtmData)
            dispatch(setSeeOptions(color))

            // Adobe order_samples event
            const colorId = `${color.brandKey}${color.colorNumber}`
            const adobeEvent = {
              event: labels.evOrderSamples,
              eventinfo: {
                eventName: labels.evName,
                colorID: colorId,
                colorName: color.name
              },
              pageInfo: {
                pageName: pageInfo.pageName,
                pageURL: pageInfo.pageURL,
                previousPageName: pageInfo.previousPageName,
                environment,
                businessCountryLanguage
              },
              applicationInfo: {
                applicationName: labels.applicationName,
                applicationType: labels.applicationType,
                applicationOS: labels.applicationOS
              }
            }
            window.adobeDataLayer.push(adobeEvent)
            // End Adobe order_samples event
          }
          }>
          <FontAwesomeIcon icon={faPlusCircle} />
          <div className='text-xs rounded p-1 leading-none'>
            <FormattedMessage id='TEXT_SEE_OPTIONS' className='text-xs opacity-90 ml-2' />
          </div>
        </button>}
    </div>
  </>
}

export default SwatchContentRenderer
