import React from 'react'
import 'app/components/Swatch/Swatch.scss'
import './DeepLinkCartModal.scss'
import Item from 'components/DeepLinkCartModal/Item'

export default function ItemSwatch (props) {
  const { item: { brandKey, colorNumber, isPeelAndStick, isBundledItem, name, hex: color, isDark }, size } = props
  const title = `${brandKey} ${colorNumber}`.toUpperCase()
  const description = name

  return size !== 'sm' && (isPeelAndStick || isBundledItem)
    ? (
    <div className='item-swatch-wrapper'>
      <svg width='180' height='180' viewBox='0 0 180 180' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <mask maskUnits='userSpaceOnUse' x='158' y='158' width='21' height='21'>
          <path d='M160.312 174.375V162.311C160.312 161.206 161.206 160.312 162.311 160.312H174.375C177.187 160.312 178.183 159.317 178.594 158.906L158.906 178.594C159.317 178.183 160.312 177.187 160.312 174.375Z' fill='#D5B176' />
        </mask>
        <g>
          <path d='M0 2.8125C0 1.2592 1.2592 0 2.8125 0H177.188C178.741 0 180 1.16474 180 2.71804C180 23.2647 180 153.392 180 154.688C180 156.094 180 157.5 178.594 158.906C177.188 160.312 160.312 177.188 158.906 178.594C157.5 180 156.094 180 154.688 180C153.392 180 23.2647 180 2.71804 180C1.16474 180 0 178.741 0 177.188V2.8125Z' fill={color} />
        </g>
        <g>
          <path d='M160.312 174.375V162.311C160.312 161.206 161.206 160.312 162.311 160.312H174.375C177.187 160.312 178.183 159.317 178.594 158.906L158.906 178.594C159.317 178.183 160.312 177.187 160.312 174.375Z' fill='white' />
        </g>
        <g>
          <line x1='158.729' y1='178.417' x2='178.417' y2='158.729' stroke='#AEAEAE' strokeWidth='0.5' />
        </g>
      </svg>
      <div className='item-swatch-desc-wrapper' style={isDark ? { color: '#ffffff' } : null}>
        <div className='description'>{description}</div>
        <div className='item-title'>{title}</div>
      </div>
    </div>
      )
    : (
    <Item title={title} description={description} color={color} size={size} isDark={isDark} />
      )
}
