export const INTENTIONAL_CANCELLATION = 'INTENTIONAL_CANCELLATION'
export const isIntentionalCancellation = msg => msg === INTENTIONAL_CANCELLATION

export const makeErrorResponse = function makeErrorResponse (critical = false, intentional = false, errors = []) {
  return {
    critical,
    intentional,
    errors
  }
}
