import countries from 'app/constants/countries'

export default function makePath (country, language) {
  if (country !== countries.us) {
    return `/${language}`
  }

  return ''
}

/**
 * Removes a specified param to prevent clean up logic
 * @param paramName
 */
export function removeDeepLink (paramName) {
  const searchString = window.location.search
  const encodedQM = encodeURIComponent('?')
  let urlFirstCharFlag = searchString.indexOf('?') === 0 ? 1 : 0 // / no qm found = 0, found = 1
  urlFirstCharFlag = searchString.indexOf(encodedQM) === 0 ? 2 : urlFirstCharFlag // encoded qm found = 2

  let paramString = searchString
  paramString = urlFirstCharFlag === 1 ? searchString.slice(1) : paramString
  paramString = urlFirstCharFlag === 2 ? searchString.replace(encodedQM, '') : paramString

  const urlParams = new URLSearchParams(paramString)
  urlParams.delete(paramName)
  const newParams = urlParams.toString() ? `?${urlParams}` : ''
  // Url will not rewrite if the param being deleted is the only param, thus the slash
  const newUrl = `${newParams}${window.location.hash}` || '/'

  window.history.replaceState(null, '', newUrl)
}

export function getSkusFromParam (_loc, param) {
  const loc = _loc ?? window.location.search
  const params = new URLSearchParams(loc)

  return (params.get(param) ?? '').split(',').filter(Boolean)
}

export function getPSBundleSkus (_loc) {
  return getSkusFromParam(_loc, 'psbundles')
}

export function getEnrichedPSBundleSkus (_loc) {
  return getSkusFromParam(_loc, 'epsb')
}

export function getSkusFromUrl (paramName, params) {
  // this is intentional loose equality
  const searchParams = params != null ? params : window.location.search
  const urlColors = searchParams.split('&').filter(v => v.indexOf(`${paramName}=`) > -1)
    .map(v => v.substring(v.indexOf(`${paramName}=`) + `${paramName}=`.length))
    .reduce((accum, last) => last, null)

  return urlColors?.length ? urlColors : null
}

// Adobe utility methods for Page Information from URL
export function getDevelopmentEnvFromHostname (hostname) {
  const subdomainRegexPattern = /(\S*)-(\S*)\.(\S*)\.(\S*)/
  if (subdomainRegexPattern.test(hostname)) {
    const hostnameParts = hostname.match(subdomainRegexPattern) ?? []
    const subdomain = hostnameParts[1]
    return subdomain[0]?.toUpperCase() + subdomain?.slice(1)
  } else {
    if (hostname === 'localhost') return 'Local'
    return 'Prod'
  }
}

export function getPageInfoFromURL () {
  const url = new URL(window.location.href)
  const pathSegments = url.pathname?.split('/')?.filter((segment) => segment.length > 0)
  const path = pathSegments.length ? 'samples:' + pathSegments.join(':') : 'samples:Home page'
  const _localStorageSupport = window.localStorage
  let previousPage = ''
  if (_localStorageSupport) {
    previousPage = window.localStorage.getItem('PREVIOUS_PAGE') ? window.localStorage.getItem('PREVIOUS_PAGE') : ''
  }
  const pageInfo = {
    pageName: path,
    pageURL: url.origin + url.pathname,
    siteSection: pathSegments[1] || null,
    siteSubSection1: pathSegments[2] || null,
    siteSubSection2: pathSegments[3] || null,
    previousPageName: previousPage
  }
  return pageInfo
}
// End Adobe utility methods for Page Information from URL
