import React from 'react'
import usePrependPath from 'app/hooks/usePrependPath'
import { Link } from 'react-router-dom'
import CartTotals from '../CartTotals/CartTotals'
import useCart from 'app/hooks/useCart'
import { FormattedMessage } from 'react-intl'

function CartPriceProceed () {
  const prependPath = usePrependPath()
  const { nonZeroItems, subtotal, loading, errors } = useCart()
  const disableProceedBtn = nonZeroItems.length === 0 || loading || errors.length

  return (
    <section className='CartPriceProceed'>
      {(subtotal > 0) && (
        <div className='mb-5'>
          <FormattedMessage id='CART_DISCLAIMER' values={{
            line: chunk => <p>{chunk}</p>
          }} />
        </div>
      )}
      <CartTotals isEstimate>
        <div className='is-flex is-flex-direction-row is-flex-wrap-wrap-reverse is-justify-content-flex-end'>
          <Link disabled={disableProceedBtn} className={`button is-dark is-uppercase ml-3 mb-3 ${loading ? 'is-loading' : ''}`} to={prependPath('checkout')}><FormattedMessage id='CART_CTA_PROCEED_TO_CHECKOUT' /></Link>
          <Link style={{ order: '-1' }} className='button is-uppercase ml-3 mb-3' to={prependPath()}><FormattedMessage id='CONTINUE_SHOPPING' /></Link>
        </div>
      </CartTotals>
    </section>
  )
}

export default CartPriceProceed
