import './Swatch.scss'
import React, { useMemo, useLayoutEffect, useRef } from 'react'
import PlusCircle from 'src/svg/plus-circle.svg'
import SVG from 'react-inlinesvg'
import { TinyColor } from '@ctrl/tinycolor'
import { FormattedMessage } from 'react-intl'
import noop from 'lodash/noop'
import withUniqueId from 'app/HOCs/withUniqueId'

const STYLES = { DEFAULT: 'default', SQUARE: 'square' }

const SIZES = { DEFAULT: 'default', SMALL: 'small', XSMALL: 'xsmall' }

const Swatch = withUniqueId(function Swatch ({
  color = 'white',
  name,
  number,
  children,
  swatchStyle = STYLES.DEFAULT,
  size = SIZES.DEFAULT,
  inside = noop,
  outside = noop,
  contentOutside = false,
  hasOverlay = false,
  hasShadow = true,
  className = '',
  ghost = false,
  onChangeSize = noop,
  onClick,
  id,
  ...other
}) {
  const hideTitles = false
  const ref = useRef()
  const isDark = useMemo(() => new TinyColor(color).isDark(), [color])

  useLayoutEffect(() => onChangeSize(ref.current.offsetWidth), [size, ref])

  const classes = [
    'Swatch',
    onClick ? 'Swatch--is-clickable' : '',
    swatchStyle === STYLES.SQUARE ? 'Swatch--style-square' : '',
    size === SIZES.SMALL ? 'Swatch--sz-sm' : '',
    size === SIZES.XSMALL ? 'Swatch--sz-xs' : '',
    contentOutside ? 'Swatch--content-outside' : '',
    hasOverlay ? 'Swatch--has-overlay' : '',
    className
  ].join(' ')

  const swatchGraphicClasses = [
    'Swatch__swatch',
    swatchStyle === STYLES.SQUARE ? 'Swatch__swatch--style-square' : '',
    hasShadow ? 'Swatch__swatch--has-shadow' : ''
  ].join(' ')

  return (
    <section className={classes} ref={ref} aria-labelledby={id} onClick={onClick ?? undefined} {...other}>
      <div className={`Swatch__insides ${isDark ? 'Swatch__insides--color-is-dark' : ''}`}>
        {swatchStyle === STYLES.SQUARE
          ? <PeelAndStickSvg className={swatchGraphicClasses} color={color} size={size} />
          : <RegularSvg className={swatchGraphicClasses} color={color} squat={size === SIZES.XSMALL} ghost={ghost} size={size} />}
        {inside()}
      </div>
      <hgroup id={id} className={`Swatch__titles ${hideTitles ? 'is-sr-only' : ''} ${isDark ? 'Swatch__titles--color-is-dark' : ''}`}>
        <h1 className='Swatch__titles__title Swatch__titles__title--name'>{name}</h1>
        <h2 className='Swatch__titles__title Swatch__titles__title--number'>{number}</h2>
      </hgroup>
      <div className='Swatch__outsides'>
        {outside()}
      </div>
    </section>
  )
})

function SwatchCta ({ className = '', RenderEl = (children, ...other) => <button {...other}>{children}</button>, ...other }) {
  return (
    <div {...other} className={`Swatch__cta ${className}`}>
      <RenderEl className='Swatch__cta__btn'>
        <SVG aria-hidden='true' className='Swatch__cta__btn__icon' src={PlusCircle} />
        <span><FormattedMessage id='TEXT_SEE_OPTIONS' /></span>
      </RenderEl>
    </div>
  )
}

function SwatchTag ({ className = '', children, ...other }) {
  return (
    <div {...other} className={`Swatch__tag ${className}`}>
      <div className='Swatch__tag__inner'>
        {children}
      </div>
    </div>
  )
}

function SwatchOverlay ({ className = '', children, ...other }) {
  return <div tabIndex='0' {...other} className={`Swatch__overlay is-overlay ${className}`}>
    <div>{ children }</div>
  </div>
}

const maskProps = {
  maskType: 'alpha',
  opacity: 0.9
}
const PeelAndStickSvg = withUniqueId(function PeelAndStickSvg ({ color = '#fff', id, size = SIZES.DEFAULT, ...other }) {
  return (
    <div {...other}>
      { size === SIZES.DEFAULT
        ? (
          <svg width='180' height='180' viewBox='0 0 180 180' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <mask id={`mask0_${id}`} maskUnits='userSpaceOnUse' x='158' y='158' width='21' height='21'>
              <path d='M160.312 174.375V162.311C160.312 161.206 161.206 160.312 162.311 160.312H174.375C177.187 160.312 178.183 159.317 178.594 158.906L158.906 178.594C159.317 178.183 160.312 177.187 160.312 174.375Z' fill='#D5B176' />
            </mask>
            <g>
              <path d='M0 2.8125C0 1.2592 1.2592 0 2.8125 0H177.188C178.741 0 180 1.16474 180 2.71804C180 23.2647 180 153.392 180 154.688C180 156.094 180 157.5 178.594 158.906C177.188 160.312 160.312 177.188 158.906 178.594C157.5 180 156.094 180 154.688 180C153.392 180 23.2647 180 2.71804 180C1.16474 180 0 178.741 0 177.188V2.8125Z' fill={color} />
            </g>
            <g mask={`url(#mask0_${id})`} style={maskProps}>
              <g>
                <path d='M160.312 174.375V162.311C160.312 161.206 161.206 160.312 162.311 160.312H174.375C177.187 160.312 178.183 159.317 178.594 158.906L158.906 178.594C159.317 178.183 160.312 177.187 160.312 174.375Z' fill='white' />
              </g>
              <g>
                <line x1='158.729' y1='178.417' x2='178.417' y2='158.729' stroke='#AEAEAE' strokeWidth='0.5' />
              </g>
            </g>
          </svg>

          )
        : size === SIZES.SMALL
          ? (
            <svg width='135' height='135' viewBox='0 0 135 135' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <mask id={`mask0_${id}`} maskUnits='userSpaceOnUse' x='119' y='119' width='16' height='16'>
                <path d='M120.109 130.864V122.078C120.109 120.974 121.004 120.079 122.108 120.079H130.894C133.051 120.079 133.815 119.315 134.13 119L119.03 134.099C119.345 133.785 120.109 133.021 120.109 130.864Z' fill='#D5B176' />
              </mask>
              <g>
                <path d='M0 2.10938C0 0.944399 0.944399 0 2.10938 0H132.891C134.056 0 135 0.873556 135 2.03853C135 17.4485 135 115.044 135 116.016C135 117.07 135 118.125 133.945 119.18C132.891 120.234 120.234 132.891 119.18 133.945C118.125 135 117.07 135 116.016 135C115.044 135 17.4485 135 2.03853 135C0.873556 135 0 134.056 0 132.891V2.10938Z' fill={color} />
              </g>
              <g mask={`url(#mask0_${id})`} style={maskProps}>
                <g>
                  <path d='M120.109 130.864V122.078C120.109 120.974 121.004 120.079 122.108 120.079H130.894C133.051 120.079 133.815 119.315 134.13 119L119.03 134.099C119.345 133.785 120.109 133.021 120.109 130.864Z' fill='white' />
                </g>
                <g>
                  <line x1='118.853' y1='133.923' x2='133.953' y2='118.823' stroke='#AEAEAE' strokeWidth='0.5' />
                </g>
              </g>
            </svg>
            )
          : size === SIZES.XSMALL
            ? (
              <svg width='103' height='103' viewBox='0 0 103 103' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <mask id={`mask0_${id}`} maskUnits='userSpaceOnUse' x='90' y='90' width='13' height='13'>
                  <path d='M91.6383 99.8444V93.1411C91.6383 92.2984 92.3212 91.6155 93.1639 91.6155H99.8672C101.513 91.6155 102.096 91.0326 102.336 90.7926L90.8154 102.313C91.0554 102.073 91.6383 101.49 91.6383 99.8444Z' fill='#D5B176' />
                </mask>
                <g>
                  <path d='M0 1.60938C0 0.720542 0.720542 0 1.60938 0H101.391C102.279 0 103 0.66649 103 1.55532C103 13.3126 103 87.7745 103 88.5156C103 89.3203 103 90.125 102.195 90.9297C101.391 91.7344 91.7344 101.391 90.9297 102.195C90.125 103 89.3203 103 88.5156 103C87.7745 103 13.3126 103 1.55532 103C0.666491 103 0 102.279 0 101.391V1.60938Z' fill={color} />
                </g>
                <g mask={`url(#mask0_${id})`} style={maskProps}>
                  <g>
                    <path d='M91.6383 99.8444V93.1411C91.6383 92.2984 92.3212 91.6155 93.1639 91.6155H99.8672C101.513 91.6155 102.096 91.0326 102.336 90.7926L90.8154 102.313C91.0554 102.073 91.6383 101.49 91.6383 99.8444Z' fill='white' />
                  </g>
                  <g>
                    <line x1='90.6806' y1='102.178' x2='102.201' y2='90.6577' stroke='#AEAEAE' strokeWidth='0.381481' />
                  </g>
                </g>
              </svg>
              )
            : null }
    </div>
  )
})

const boxWidth = 600
const radiusReference = {
  [SIZES.XSMALL]: boxWidth / 82,
  [SIZES.SMALL]: boxWidth / 130,
  [SIZES.DEFAULT]: boxWidth / 180
}
function RegularSvg ({ color = '#fff', squat = false, size = SIZES.DEFAULT, ghost = false, ...other }) {
  const ratio = squat ? 1.25 : 1.5
  const fillColor = ghost ? 'transparent' : color
  const strokeColor = !ghost ? 'transparent' : color
  const ghostOffset = 10
  const strokeWidth = ghost ? ghostOffset / 2 : 0
  const boxW = boxWidth
  const boxH = Math.round(boxW * ratio)
  const w = boxW - (ghost ? ghostOffset : 0)
  const h = boxH - (ghost ? ghostOffset : 0)
  const x = ghost ? (ghostOffset / 2) : 0
  const y = ghost ? (ghostOffset / 2) : 0
  const radius = 2 * radiusReference[size]

  return (
    <div {...other}>
      <svg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' viewBox={`0 0 ${boxW} ${boxH}`}>
        <rect x={x} y={y} rx={radius} ry={radius} width={w} height={h} fill={fillColor} strokeMiterlimit='10' stroke={strokeColor} strokeWidth={strokeWidth} />
      </svg>
    </div>
  )
}

Swatch.Cta = SwatchCta
Swatch.Tag = SwatchTag
Swatch.Overlay = SwatchOverlay
Swatch.STYLES = STYLES
Swatch.SIZES = SIZES

export default Swatch
