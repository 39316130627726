import React from 'react'
import './Pill.scss'

export default function Pill ({ children }) {
  return (
    <span className='Pill'>
      {children}
    </span>
  )
}
