/* eslint-disable */
import React, { useEffect, useContext }  from 'react'

import './Confirmation.scss'
import useQueryParamOrderNumber from 'src/app/hooks/useQueryParamOrderNumber'
import { FormattedMessage } from 'react-intl'
import labels from 'app/constants/adobeAnalytics'
import { getDevelopmentEnvFromHostname, getPageInfoFromURL } from 'app/Utils/url.util'
import CountryLangCtx from 'app/contexts/countryLang/CountryLangCtx'
import countries from 'app/constants/countries'

const classBase = 'confirm'

function Confirmation () {
  const [orderNumber, hasNoOrderNumber] = useQueryParamOrderNumber()

  // Adobe page info
  const { country, language } = useContext(CountryLangCtx)
  const businessCountryLanguage = `${language}-${country.toUpperCase()}`
  const isUS = country === countries.us
  const currency = isUS ? 'USD' : 'CAD'
  const environment = getDevelopmentEnvFromHostname(window.location.hostname)
  const pageInfo = getPageInfoFromURL()
  // End Adobe page info

  // Adobe purchase event
  useEffect(() => {
    const _localStorageSupport = window.localStorage
    let orderDetails
    if (_localStorageSupport) {
      orderDetails = window.localStorage.getItem(labels.localStorageKeyOrder)
    }
    let lineItems = []
    let priceTotal = 0
    let taxTotal = 0
    if (orderDetails) {
      JSON.parse(orderDetails).lineItems?.forEach((item) => {
        const adjustment = item.totalAdjustment ? item.totalAdjustment : 0
        priceTotal = parseFloat(priceTotal) + (parseFloat(item.price) - adjustment)
        taxTotal = parseFloat(taxTotal) + parseFloat(item.totalTax)
        const itemObj = {
          SKU: item.sku,
          name: item.description,
          _id: item.skuType,
          productPrice: (item.price - adjustment) / item.quantity,
          productQuantity: item.quantity,
          productVariant: item.skuType,
          colorID: item.colorNumber,
          colorName: item.description
        }
        lineItems.push(itemObj)
      })
    }
    priceTotal = priceTotal + taxTotal
    const adobePurchaseEvent= {
      event: labels.evPurchase,
      orderInfo: {
        purchaseID: (orderDetails) ? JSON.parse(orderDetails).orderId : '',
        currencyCode: currency,
        priceTotal: priceTotal ? priceTotal : 0,
        taxAmount: taxTotal ? taxTotal : 0,
        purchaseType: labels.purchaseType
      },
      productInfo: {
        eventName: labels.evNamePurchase,
        productPurchaseList: lineItems
      },
      pageInfo: {
        pageName: pageInfo.pageName,
        pageURL: pageInfo.pageURL,
        previousPageName: pageInfo.previousPageName,
        environment,
        businessCountryLanguage
      },
      applicationInfo: {
        applicationName: labels.applicationName,
        applicationType: labels.applicationType,
        applicationOS: labels.applicationOS
      }
    }
    adobeDataLayer.push(adobePurchaseEvent)
  }, [])
  // End Adobe purchase event

  if (hasNoOrderNumber) {
    return (
      <section className={`${classBase}__message`}>
        <h1 className='title is-size-4-touch'><FormattedMessage id='ORDER_CANT_FIND_HEADING' /></h1>
        <h2 className='is-size-6 mb-5'><FormattedMessage id='ORDER_CANT_FIND_MESSAGE' /></h2>
      </section>
    )
  }

  return (
    <>
      <section className={`${classBase}__message`}>
        <h1 className='title is-size-4-touch'><FormattedMessage id='ORDER_CONFIRMATION_HEADING' /></h1>
        <h2 className='is-size-6 mb-5'><FormattedMessage id='ORDER_CONFIRMATION_MSG' /></h2>
        <p className='has-text-weight-bold'>
          <FormattedMessage id='ORDER_REF_NUMBER' values={{ orderNumber }} />
        </p>
      </section>
    </>
  )
}

export default Confirmation
