// @todo refactor, can simplify to seaprate process (loading from data)
// This will work with util functions and pure data to make data pipeline more testable/maybe readable
// Would be nice to preprocess all products and uses lookup strategy where we only need ID.

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import productIsSwatch from 'app/Utils/productIsSwatch.util'
import extractFullColorNumber from 'app/Utils/extractFullColorNumber.util'

export const TYPES = { CHIP: 'CHIP', SWATCH: 'SWATCH' }

const PROCESSING_STATE = { loading: true, error: false }

export default function makeUseProduct (itemType = TYPES.CHIP) {
  return function useProduct (color) {
    const { error: requestError, loading, skusChips, skusSwatches } = useSelector(state => state.products)
    const { availableColors } = useSelector(state => state.cart)
    const productsInfo = itemType === TYPES.CHIP ? skusChips : itemType === TYPES.SWATCH ? skusSwatches : undefined
    const [processing, setProcessing] = useState(true)
    const [error, setError] = useState(false)
    const [colorObj, setColorObj] = useState(typeof color === 'object' ? color : undefined)
    const [productId, setProductId] = useState()
    const [price, setPrice] = useState()
    const [inventoryEnabled, setInventoryEnabled] = useState(true)
    const [productResponse, setProductResponse] = useState(PROCESSING_STATE)

    useEffect(() => {
      if (loading) {
        return
      }
      if (!color) {
        console.error('No color found.')
        return setError(true)
      }

      if (typeof color === 'object') {
        return setColorObj(color)
      }

      if (!isEmpty(availableColors) && (typeof color === 'string' || typeof color === 'number')) {
        return setColorObj(availableColors.filter(color => {
          return color.colorNumber.toLowerCase() === (color).toString().toLowerCase()
        })[0])
      }
    }, [loading, color, availableColors])

    useEffect(() => {
      if (loading) return
      if (!isEmpty(productsInfo) && colorObj) {
        const obj = productsInfo.filter(obj => {
          return obj.colors[0].toLowerCase() === extractFullColorNumber(colorObj).toLowerCase()
        })[0]

        if (!obj) {
          console.warn('Color not found, could not match color number.')
          return setError(true)
        }

        if (typeof obj.sku !== 'undefined') {
          setProductId(obj.sku)
        } else {
          console.warn('Color missing sku.')
          return setError(true)
        }

        if (typeof obj.basePrice !== 'undefined') {
          setPrice(obj.basePrice)
        } else {
          console.warn('Color missing price.')
          return setError(true)
        }

        // determine if out of stock for P&S
        if (productIsSwatch(obj) && obj.active === false) {
          setInventoryEnabled(false)
        }
      }
    }, [loading, productsInfo, colorObj])

    useEffect(() => {
      setProcessing([colorObj, price, productId, inventoryEnabled].filter(v => typeof v === 'undefined').length > 0)
    }, [colorObj, price, productId, inventoryEnabled])

    useEffect(() => {
      if (requestError || error) {
        return setProductResponse({
          error: true,
          loading: false
        })
      }

      if (processing || loading) {
        return setProductResponse(PROCESSING_STATE)
      }

      return setProductResponse({
        color: colorObj,
        error: false,
        loading: false,
        price,
        productId,
        inStock: inventoryEnabled,
        isChip: itemType === TYPES.CHIP,
        isSwatch: itemType === TYPES.SWATCH
      })
    }, [requestError, error, loading, processing, colorObj, price, productId, inventoryEnabled])

    return productResponse
  }
}
