/**
 * This comment is here to shed light on how th christian siriano stuff works.
 * C siriano has 2 modals: add to cart and deep link.  The app has the older wrapped modals and the newer "global modals"
 * Why 2 types? Well, in short time. A unified modal approach requires a significant refactor and consequentially testing.
 * ChristianSirianoSamplesView is the addToCart layout, the deeplink layout is the same as the current deeplink w/ a
 * differnt header
 * Also the c siriano stuff will be removed in a year.
 */

import chunk from 'lodash/chunk'
import React, { useState, useEffect } from 'react'

// Parts
import Header from 'components/DeepLinkCartModal/DeepLinkCartModalHeader'
import Confirm from './DeepLinkCartModalConfirm'
import ItemsRow from './ItemsRow'
import { getChunkKey } from 'app/Utils/general.utils'
import ChristianSirianoSamplesView from 'components/DeepLinkCartModal/ChristianSirianoSamplesView'

import './DeepLinkCartModal.scss'
import { FormattedMessage } from 'react-intl'

const getChunkSize = (forBundles, vpSize) => {
  if (forBundles) {
    return vpSize === 'lg' ? 3 : 2
  } else {
    return vpSize === 'lg' ? 5 : 4
  }
}

const getViewportSize = () => {
  const windowSize = window.visualViewport.width
  if (windowSize < 768) {
    return 'sm'
  }
  if (windowSize > 1023) {
    return 'lg'
  }
  return 'md'
}

export const formatDash = (chunks) => {
  return <span>&#8209;</span>
}

export default function Samples (props) {
  const { data, addToCartHandler, closeHandler, addToCart } = props
  const [viewportSize, setViewportSize] = useState(getViewportSize())
  // if any item is bundles they all are since this is a show either bundles of P&S and chips kinda deal
  const [showBundles] = useState(data.find((item) => item.isBundledItem))
  const [chunks, setChunks] = useState([])

  useEffect(() => {
    const lgHandler = (e) => {
      if (e.matches) {
        setViewportSize('lg')
      }
    }
    const lgMql = window.matchMedia('(min-width: 1024px)')
    lgMql.addEventListener('change', lgHandler)

    const mdHandler = (e) => {
      if (e.matches) {
        setViewportSize('md')
      }
    }
    const mdMql = window.matchMedia('(min-width: 768px) and (max-width:1023px')
    mdMql.addEventListener('change', mdHandler)

    const smHandler = (e) => {
      if (e.matches) {
        setViewportSize('sm')
      }
    }
    const smMql = window.matchMedia('(max-width: 767px)')
    smMql.addEventListener('change', smHandler)

    return () => {
      lgMql.removeEventListener('change', lgHandler)
      mdMql.removeEventListener('change', mdHandler)
      smMql.removeEventListener('change', smHandler)
    }
  }, [])

  useEffect(() => {
    const chunkSize = getChunkSize(showBundles, viewportSize)
    setChunks(chunk(data, chunkSize))
  }, [viewportSize])

  const sirianoHeaderFormatter = (chunks) => {
    return <span className='heavier'>{chunks}</span>
  }

  return (
    <>{addToCart
      ? <ChristianSirianoSamplesView
      addToCart
      viewportSize={viewportSize}
      chunks={chunks}
      closeHandler={closeHandler}
    />
      : <div className='orderedSamples'>
        {!showBundles
          ? <Header countOfItems={data.length} />
          : <div className='siriano-header'><FormattedMessage id='CHRISTIAN_SIRIANO_MODAL_HEADER_ADD_TO_CART' values={{ span: sirianoHeaderFormatter, nbd: formatDash }} /></div>}
        <div className='items'>
          {chunks.map((chunk, i) => {
            return <ItemsRow
              size={viewportSize}
              key={getChunkKey(chunk)}
              data={chunk}
            />
          })}
        </div>
        <Confirm addToCartHandler={addToCartHandler} closeHandler={closeHandler} />
      </div>}</>
  )
}
