import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import CartTotals from 'components/CartTotals/CartTotals'
import CountryLangCtx from 'app/contexts/countryLang/CountryLangCtx'
import countries from 'app/constants/countries'
import languages from 'app/constants/languages'

export default function CheckoutStructure ({ children, showEstimate = true }) {
  return (
    <div className='columns'>
      <section className='column is-three-fifths-tablet'>
        { children }
      </section>
      <section className='column is-hidden-mobile'>
        <CheckoutSidebar showEstimate={showEstimate} />
      </section>
    </div>
  )
}

export function CheckoutSidebar ({ showEstimate = true, className = '' }) {
  // this is the summary and description
  // Structure will include it in the side, but hide it for small screens
  // it should also be included manually in the flow of the implementing component
  const { country, language } = useContext(CountryLangCtx)
  const isUS = country === countries.us

  const termsandconditionsurl = language === languages.en ? 'https://www.sherwin-williams.com/terms-and-conditions' : 'https://www.sherwin-williams.com/terms-and-conditions/french'
  const privacypolicyurl = language === languages.en ? 'https://privacy.sherwin-williams.com/privacy-policy' : 'https://privacy.sherwin-williams.com/ca/fr/privacy-policy'

  return (
    <div className={className}>
      <h1 className='subtitle is-size-5-mobile'><FormattedMessage id='PAGE_CHECKOUT_YOUR_ORDER_HEADING' /></h1>
      <div className='order-column mb-5'>
        <CartTotals isEstimate={showEstimate} />
      </div>
      <div>
        {!isUS ? <p className='is-size-7'><FormattedMessage id='QUEBEC_DISCLAIMER' /></p> : null}
        <FormattedMessage id='CHECKOUT_DISCLAIMERS' values={{
          line: chunk => <div className='field is-size-7'><p>{chunk}</p></div>,
          ctaTerms: chunk => <a href={termsandconditionsurl} rel="external noreferrer" target='_blank'>{chunk}</a>,
          ctaPrivacy: chunk => <a rel="external noreferrer" target='_blank' href={privacypolicyurl}>{chunk}</a>
        }} />
      </div>
    </div>
  )
}
