import React, { forwardRef, useContext, useState } from 'react'
import languages from 'app/constants/languages'
import countries from 'app/constants/countries'
import { useParams, Link } from 'react-router-dom'
import makePath from 'app/Utils/url.util'
import usePrependPath from 'app/hooks/usePrependPath'
import CountryLangCtx from 'app/contexts/countryLang/CountryLangCtx'
import CartBadge from 'components/CartBadge/CartBadge'
import { FormattedMessage, useIntl } from 'react-intl'

import './TopNav.scss'

const TopNav = forwardRef((props, ref) => {
  const { showCart = false } = props
  const { country, language } = useContext(CountryLangCtx)
  const [showdropdown, setShowdropdown] = useState(false)
  const { page = '' } = useParams()
  const prependPath = usePrependPath()
  const intl = useIntl()

  const handleClickLogo = (e) => {
    // if the user clicks the logo while on the homepage, reload the page
    if (!page) {
      e.preventDefault()
      window.location?.reload() // eslint-disable-line no-unused-expressions
    }
  }

  const toggleDropdown = () => {
    setShowdropdown(!showdropdown)
  }

  const selectLanguage = (e, lang, cntry, pg) => {
    e.preventDefault()
    const queryParams = window.location.href.match(/\?.+$/)?.reduce((prev, curr, i) => {
      // reduce this array to only the first element
      if (i === 0) {
        return curr
      }
      return ''
    }) ?? ''
    const url = `${makePath(cntry, lang)}/${pg}${queryParams}`
    // We use window.open since the browserrouter is an all or none for full page refresh.
    window.open(url, '_self')
  }

  return (
    <div className='TopNav has-background-white section py-0'>
      <nav className='navbar is-white' role='navigation' aria-label='main navigation' id='global-header' ref={ref}>
        <div className='container'>
          <div className='logo-wrapper'>
            <Link to={prependPath()} onClick={handleClickLogo} className='navbar-item'>
              <img
                className='TopNav__logo'
                srcSet={`${require('src/images/logo-color.png')} ${require('src/images/logo-color@2x.png')}`}
                src={`${require('src/images/logo-color.png')}`}
                alt={intl.formatMessage({ id: 'SITE_LOGO' })}
              />
            </Link>
          </div>
          <div className='nav-button-wrapper'>
            {showCart
              ? <div className='cart-wrapper'>
              <ul className='navbar-menu'>
                <li className='navbar-item TopNav__cart-link'>
                  <Link className='cart-link' to={prependPath('cart')}><CartBadge count={4} isSmall /></Link>
                </li>
              </ul>
            </div>
              : null}
            <div className='dropdown-wrapper'>
              {country === countries.ca && <ul className='nav-languages navbar-menu webview'>
                <li className='navbar-item webview'>
                  <button onClick={(e) => selectLanguage(e, languages.en, country, page)} className={`nav-btn nav-link ${language === languages.en ? 'is-active' : ''}`}><FormattedMessage id='TOP_NAV_ENGLISH' /></button>
                </li>
                <li className='navbar-item webview englishNavbar-item'>
                  <></>
                </li>
                <li className='navbar-item webview'>
                  <button onClick={(e) => selectLanguage(e, languages.fr, country, page)} className={`nav-btn nav-link ${language === languages.fr ? 'is-active' : ''}`}><FormattedMessage id='TOP_NAV_FRENCH' /></button>
                </li>
              </ul>}
              {country === countries.ca && <ul className='nav-languages navbar-menu navbar-nav mobileview'>
                <li className='navbar-item dropdown '>
                  {language === languages.en ? <button type='button' onClick={toggleDropdown} className={`nav-btn nav-link dropdownbuttonmain dropdownbtn ${language === languages.en ? 'is-active' : ''}`}>EN <img className='dropdownImg' alt='dropdownimg' src='https://img.icons8.com/ios/50/000000/expand-arrow--v2.png' /></button> : <button type='button' onClick={toggleDropdown} className={`nav-btn dropdownbuttonmain dropdownbtn nav-link ${language === languages.fr ? 'is-active' : ''}`}>FR <img className='dropdownImg' alt='dropdownimg' src='https://img.icons8.com/ios/50/000000/expand-arrow--v2.png' /></button>}
                  {showdropdown && <div className='dropdownOptions'>
                    <button onClick={(e) => selectLanguage(e, languages.en, country, page)} className={`nav-btn nav-link dropdownbtn ${language === languages.en ? 'is-active' : ''}`}><FormattedMessage id='TOP_NAV_ENGLISH' /></button>
                    <button onClick={(e) => selectLanguage(e, languages.fr, country, page)} className={`nav-btn nav-link dropdownbtn ${language === languages.fr ? 'is-active' : ''}`}><FormattedMessage id='TOP_NAV_FRENCH' /></button>
                  </div>}
                </li>
              </ul>}
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
})

export default TopNav
