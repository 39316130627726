import React, { useContext, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import CountryLangCtx from 'app/contexts/countryLang/CountryLangCtx'
import countries from 'app/constants/countries'
import usePrependPath from 'app/hooks/usePrependPath'
import { Link } from 'react-router-dom'
import Logo from 'src/svg/logo.svg'
import LogoFacebook from 'src/svg/logo-facebook.svg'
import LogoTwitter from 'src/svg/logo-twitter.svg'
import LogoInstagram from 'src/svg/logo-instagram.svg'
import LogoPinterest from 'src/svg/logo-pinterest.svg'
import LogoYoutube from 'src/svg/logo-youtube.svg'
import SVG from 'react-inlinesvg'

import './Footer.scss'
import languages from 'app/constants/languages'
import labels from 'app/constants/adobeAnalytics'
import { getDevelopmentEnvFromHostname, getPageInfoFromURL } from 'app/Utils/url.util'

function Footer () {
  const { country, language } = useContext(CountryLangCtx)
  const prependPath = usePrependPath()
  const isUS = country === countries.us
  const canadianPrivacy = `https://privacy.sherwin-williams.com/${language === languages.fr ? 'ca/fr/' : ''}privacy-policy`
  const usPrivacy = 'https://privacy.sherwin-williams.com/privacy-policy'
  const usTOS = 'https://www.sherwin-williams.com/terms-of-use/'
  const canadianTOS = `https://www.sherwin-williams.com/terms-of-use/${language === languages.fr ? 'french' : ''}`
  const swLogoUrl = isUS ? 'https://www.sherwin-williams.com/' : language === languages.fr ? 'https://www.sherwin-williams.ca/fr/ ' : 'https://www.sherwin-williams.ca/en/'

  // Adobe page_view event
  const environment = getDevelopmentEnvFromHostname(window.location.hostname)
  const pageInfo = getPageInfoFromURL()
  const businessCountryLanguage = `${language}-${country.toUpperCase()}`

  useEffect(() => {
    const _localStorageSupport = window.localStorage
    const adobePageViewEvent = {
      event: labels.evPageView,
      pageInfo: {
        pageName: pageInfo.pageName,
        pageURL: pageInfo.pageURL,
        previousPageName: pageInfo.previousPageName,
        environment,
        businessCountryLanguage
      },
      applicationInfo: {
        applicationName: labels.applicationName,
        applicationType: labels.applicationType,
        applicationOS: labels.applicationOS
      }
    }
    if (_localStorageSupport) {
      window.localStorage.setItem('PREVIOUS_PAGE', adobePageViewEvent.pageInfo.pageName)
      window.localStorage.setItem('ADOBE_LANGUAGE', businessCountryLanguage)
    }
    window.adobeDataLayer.push(adobePageViewEvent)
  }, [])
  // End Adobe page_view event

  return (
    <footer className='global-footer footer has-text-dark'>
      <div className='container'>
        <a rel="external noreferrer" target='_blank' href={swLogoUrl}>
          <SVG aria-hidden='true' className='svg global-footer__logo' src={Logo} />
        </a>
        <nav className='global-footer__links'>
          <ul className='global-footer__links__list global-footer__links__list--nav'>
            <li className='global-footer__links__list__el'><Link to={prependPath('faq')} className='text-link'><FormattedMessage id='FOOTER_NAV_FAQ_CONTACT' /></Link></li>
            <li className='global-footer__links__list__el'><a className='text-link' rel="external noreferrer" target='_blank' href={isUS ? usPrivacy : canadianPrivacy}><FormattedMessage id='FOOTER_NAV_FAQ_PRIVACY' /></a></li>
            <li className='global-footer__links__list__el'><a className='text-link' rel="external noreferrer" target='_blank' href={isUS ? usTOS : canadianTOS}><FormattedMessage id='FOOTER_NAV_FAQ_TERMS_OF_USE' /></a></li>
            {isUS ? <li className='global-footer__links__list__el'><a className='text-link' rel="external noreferrer" target='_blank' href='https://sherwin-williams.com/terms-and-conditions'><FormattedMessage id='FOOTER_NAV_FAQ_TERMS_AND_CONDITIONS' /></a></li> : null}
            <li className='global-footer__links__list__el'><a className='text-link' rel="external noreferrer" target='_blank' href='https://accessibility.sherwin-williams.com'><FormattedMessage id='FOOTER_NAV_FAQ_ACCESSIBILITY' /></a></li>
          </ul>
          {isUS && <>
            <ul className='global-footer__links__list global-footer__links__list--social'>
              <li className='global-footer__links__list__el'><a rel="external noreferrer" target='_blank' href='https://www.facebook.com/SherwinWilliamsforYourHome'>
                <SVG aria-hidden='true' className='svg global-footer__social-logo' src={LogoFacebook} />
              </a></li>
              <li className='global-footer__links__list__el'><a rel="external noreferrer" target='_blank' href='https://twitter.com/SherwinWilliams'>
                <SVG aria-hidden='true' className='svg global-footer__social-logo' src={LogoTwitter} />
              </a></li>
              <li className='global-footer__links__list__el'><a rel="external noreferrer" target='_blank' href='https://www.instagram.com/sherwinwilliams'>
                <SVG aria-hidden='true' className='svg global-footer__social-logo' src={LogoInstagram} />
              </a></li>
              <li className='global-footer__links__list__el'><a rel="external noreferrer" target='_blank' href='https://www.pinterest.com/SherwinWilliams/'>
                <SVG aria-hidden='true' className='svg global-footer__social-logo' src={LogoPinterest} />
              </a></li>
              <li className='global-footer__links__list__el'><a rel="external noreferrer" target='_blank' href='https://www.youtube.com/user/sherwinwilliams'>
                <SVG aria-hidden='true' className='svg global-footer__social-logo' src={LogoYoutube} />
              </a></li>
            </ul>
          </>}
        </nav>
      </div>
    </footer>
  )
}

export default Footer
