import { FormattedMessage } from 'react-intl'
import { formatDash } from 'components/DeepLinkCartModal/Samples'
import CountryLangCtx from 'app/contexts/countryLang/CountryLangCtx'
import countries from 'app/constants/countries'
import React, { useContext } from 'react'
import './ChristianSirianoBanner.scss'
import csBanner from 'src/images/sue-wadden-and-christian-siriano.jpg'
import { useDispatch } from 'react-redux'
import { setNavToChristianSiriano } from 'app/Store/actions/colorWall'

export const formatNBSP = (chunks) => {
  return <span>&nbsp;</span>
}
function ChristianSirianoBanner (props) {
  const { country } = useContext(CountryLangCtx)
  const isUS = country === countries.us
  const { openSirianoAddToCart } = props
  const dispatch = useDispatch()

  // @todo this is the function to use with the c. siriano banner anchor link -SG
  const goToCollection = (e) => {
    e.preventDefault()
    dispatch(setNavToChristianSiriano(true))
  }

  return (
    isUS
      ? (
      <div className='siriano-wrapper section'>
        <div className='siriano-callout'>
          <section className='limited-tag'>
            <FormattedMessage id='LIMITED_EDITION' />
          </section>
          <div className='content-wrapper'>
            <div className='content-container'>
              <h1 className='banner-title'><FormattedMessage id='CHRISTIAN_SIRIANO_MODAL_HEADER' values={{ nbd: formatDash, nbsp: formatNBSP }} /></h1>
              <p className='banner-text'><FormattedMessage id='CHRISTIAN_SIRIANO_BANNER_TEXT' /></p>
            </div>
            <section className='button-container'>
              <button className='button is-dark is-uppercase mb-4' onClick={openSirianoAddToCart}><FormattedMessage id='ORDER_KIT' /></button>
              <button className='button is-uppercase' onClick={goToCollection}><FormattedMessage id='EXPLORE_COLLECTION' /></button>
            </section>
          </div>
        </div>
        <img className='banner-image' src={csBanner} alt='' />
      </div>)
      : null
  )
}

export default ChristianSirianoBanner
