// Generic Utils

import { cloneDeep } from 'lodash/lang'
import uniqueId from 'lodash/uniqueId'

export function getDataForDeeplinkModal (cn, productId, colors, isPeelAndStick = false, isPseudoProduct = false, isBundledItem = false) {
  if (!cn) {
    return
  }
  // Remove the sw prefix
  const colorNumber = cn.slice(2)
  const color = colors.find((color) => {
    return color.colorNumber === colorNumber
  })

  if (color) {
    const newColor = cloneDeep(color)
    newColor.isPeelAndStick = isPeelAndStick
    newColor.productId = productId
    newColor.isPseudoProduct = isPseudoProduct
    newColor.isBundledItem = isBundledItem
    newColor.uid = uniqueId(`prod-${productId}`)

    return newColor
  }
}

export function DeeplinkProductBuilder (cn, colors) {
  if (!cn || !colors) {
    this.color = {
      // flag as an incomplete object if no color data
      isInvalid: true
    }
  }

  const colorNumber = cn.slice(2)
  this.color = cloneDeep(colors.find((color) => {
    return color.colorNumber === colorNumber
  }))

  this.color.uid = uniqueId('prod-')

  return {
    get: () => cloneDeep(this.color),
    setIsPeelAnStick: (isPeelAndStick) => {
      this.color.isPeelAndStick = isPeelAndStick
    },
    setProductId: (productId) => {
      // all internal comparisons should be lowercased!!!!
      this.color.productId = productId.toLowerCase()
    },
    setIsPseudoProduct: (isPseudoProduct) => {
      this.color.isPseudoProduct = isPseudoProduct
    },
    setIsBundledItem: (isBundledItem) => {
      this.color.isBundledItem = isBundledItem
    },
    setPrice: (price) => {
      this.color.price = price
    },
    setAvailability: (inStock) => {
      this.color.inStock = inStock
    }
  }
}

// used for deep link modal
export function getChunkKey (data) {
  const chunkKey = data.map((item, i) => `${item.uid}-${i}`).join('--')
  return chunkKey
}

export function getBundledProducts (product, colors) {
  const products = product.colors.map((item, i) => {
    const productBuilder = new DeeplinkProductBuilder(item, colors)
    productBuilder.setProductId(product.sku)
    productBuilder.setIsPeelAnStick(false)
    productBuilder.setIsPseudoProduct(i !== 0)
    productBuilder.setIsBundledItem(true)
    productBuilder.setPrice(product.basePrice)
    productBuilder.setAvailability(product.active)

    return productBuilder.get()
    // return getDataForDeeplinkModal(item, product.productId.toLowerCase(), availableColors, false, i === 0, true)
  })
  // put a reference to all the product's colors on the non-pseudo element
  products[0].colors = [...product.colors]

  return products
}

export function findProductByItemType (item, chips = [], swatches = [], kits = []) {
  let lookup = []
  if (item.isChip) {
    lookup = chips
  }
  if (item.isSwatch) {
    lookup = swatches
  }

  if (item.isKit) {
    lookup = kits
  }

  return cloneDeep(lookup.find((x) => x.sku.toLowerCase() === item.productId.toLowerCase()))
}

export function getBrandColorNumber (color) {
  return `${color.brandKey}${color.colorNumber}`
}
