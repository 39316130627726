import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import RemoteNotification from 'components/RemoteNotification/RemoteNotification'
import PaymentForm from 'components/PaymentForm/PaymentForm'
import Footer from 'components/Footer/Footer'
import TopNav from 'components/TopNav/TopNav'
import CheckoutStructure, { CheckoutSidebar } from 'components/CheckoutStructure/CheckoutStructure'
import useResetNavigationTo from 'app/hooks/useResetOnNavigateTo'
import useTitle from 'app/hooks/useTitle'

import './PagePayment.scss'

function PagePayment () {
  const { formatMessage } = useIntl()

  useResetNavigationTo()
  useTitle(formatMessage({ id: 'PAGE_TITLE_CHECKOUT' }))

  return (
    <>
      <RemoteNotification messageKey='REMOTE_MESSAGE_CONTENT' />
      <TopNav />
      <div className='PageCheckout has-text-dark'>
        <section className='hero'>
          <div className='hero-body pb-0'>
            <div className='container has-text-centered'>
              <h1 className='title is-size-4-mobile mb-3'><FormattedMessage id='PAGE_CHECKOUT_HEADING' /></h1>
              <h2 className='is-size-5 is-size-6-mobile mb-0'><FormattedMessage id='PAGE_PAYMENT_SUBHEADING' /></h2>
            </div>
          </div>
        </section>
        <section className='section'>
          <div className='container'>
            <CheckoutStructure showEstimate={false}>
              <PaymentForm>
                <CheckoutSidebar showEstimate={false} className='is-hidden-tablet' />
              </PaymentForm>
            </CheckoutStructure>
          </div>
        </section>
      </div>
      <Footer />
    </>
  )
}

export default PagePayment
