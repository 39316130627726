import React, { useContext } from 'react'
import { Provider } from 'react-redux'
import { IntlProvider } from 'react-intl'
import store from 'app/Store/Store'
import translations from 'src/translations/translations'
import CountryLangCtx from 'app/contexts/countryLang/CountryLangCtx'

function ReduxWrapper ({ children }) {
  return <Provider store={store}>{children}</Provider>
}

function IntlWrapper ({ children }) {
  const { language, country } = useContext(CountryLangCtx)
  const locale = `${language}-${country.toUpperCase()}`
  return (
    <IntlProvider
      locale={locale}
      messages={translations[locale]}
    >
      {children}
    </IntlProvider>
  )
}

function withReduxAndIntl (WrappedComponent) {
  return (props) => {
    return <ReduxWrapper><IntlWrapper><WrappedComponent {...props} /></IntlWrapper></ReduxWrapper>
  }
}

export default withReduxAndIntl
