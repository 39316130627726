import React from 'react'
import colors from 'app/constants/colors'

export default function ChipsIcon ({ isOnWhiteBG }) {
  const darker = colors.black
  const dark = colors.tricornBlack
  const strokeColor = isOnWhiteBG ? dark : darker

  return (
    <svg aria-hidden='true' className={'svg-inline ChipsIcon'} viewBox='0 0 24 24' fill='none' width='100%' xmlns='http://www.w3.org/2000/svg'>
        <line x1='11.5' y1='4.5' x2='13' y2='4.5' stroke={strokeColor} strokeWidth='1' strokeLinecap='round'/>
        <path opacity='0.33' d='M14.5455 7H5.45455C5.20351 7 5 7.30526 5 7.68182V21.3182C5 21.6947 5.20351 22 5.45455 22H14.5455C14.7965 22 15 21.6947 15 21.3182V7.68182C15 7.30526 14.7965 7 14.5455 7Z' fill={strokeColor}/>
        <line x1='7.5' y1='9.5' x2='9' y2='9.5' stroke={strokeColor} strokeWidth='1' strokeLinecap='round'/>
        <path d='M9 7V2.68182C9 2.30526 9.20351 2 9.45455 2H18.5455C18.7965 2 19 2.30526 19 2.68182V16.3182C19 16.6947 18.7965 17 18.5455 17H15M5.45455 7H14.5455C14.7965 7 15 7.30526 15 7.68182V21.3182C15 21.6947 14.7965 22 14.5455 22H5.45455C5.20351 22 5 21.6947 5 21.3182V7.68182C5 7.30526 5.20351 7 5.45455 7Z' stroke={strokeColor} strokeWidth='1' strokeLinejoin='round'/>
      </svg>
  )
}
