import { useEffect, useState } from 'react'
import queryString from 'query-string'

export default function useQueryParamOrderNumber () {
  const [orderNumber, setOrderNumber] = useState(null)
  const [noOrderNumber, setNoOrderNumber] = useState(false)

  useEffect(() => {
    const orderNumberFromQueryString = queryString.parse(window.location.search).orderNumber

    if (orderNumberFromQueryString) {
      setOrderNumber(orderNumberFromQueryString)
      setNoOrderNumber(false)
    } else {
      setNoOrderNumber(true)
    }
  }, [])

  return [orderNumber, noOrderNumber]
}
