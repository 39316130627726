import GenericModal from 'app/components/GenericModal/GenericModal'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { setHideModal } from 'app/Store/actions/modal'

function withGenericOverlay (WrappedComponent) {
  return (props) => {
    const { modalContent: hydratingModalContent = {} } = useSelector(state => state.modal)
    const dispatch = useDispatch()
    const [modalContent, setModalContent] = useState()

    useEffect(() => {
      if (!isEmpty(hydratingModalContent)) {
        setModalContent(hydratingModalContent)
        dispatch(setHideModal())
      }
    }, [hydratingModalContent])

    useEffect(() => {
      if (isEmpty(modalContent) && isEmpty(hydratingModalContent)) {
        document.querySelector('html').style.overflow = 'visible'
      } else {
        document.querySelector('html').style.overflow = 'hidden'
      }
    }, [hydratingModalContent, modalContent])

    return <>
      <WrappedComponent {...props} />
      {!isEmpty(modalContent)
        ? <GenericModal modalContent={modalContent} onClose={() => setModalContent()} />
        : null}
    </>
  }
}

export default withGenericOverlay
