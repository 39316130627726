export default {
  evPageView: 'page_view',
  evOrderSamples: 'order_samples_cta',
  evName: 'Order Samples - CTA',
  evPurchase: 'purchase',
  evNamePurchase: 'Purchase',
  evPrefix: 'colorwall_',
  evNamePrefix: 'Color Wall – ',
  purchaseType: 'Samples',
  toolName: 'samples:color wall',
  language: 'en-US',
  applicationName: 'Samples',
  applicationType: 'Web',
  applicationOS: 'Desktop',
  localStorageKeyOrder: 'ADOBE_SUBMITTED_ORDER'
}
