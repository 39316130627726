import axios from 'axios'
import {
  setAvailableColors,
  setLoadingColors,
  addToHold, emptyCart
} from './cart'
import { getServiceBaseUrl, getPrismApiBaseUrl } from '../../Utils/serviceUtils'
import { useContext } from 'react'
import CountryLangCtx from 'app/contexts/countryLang/CountryLangCtx'
// @tod remove supression -RS
// eslint-disable-next-line no-unused-vars
import { getSkusFromUrl, removeDeepLink } from 'app/Utils/url.util'
import { BUNDLE_URL_PARAM, ENRICHED_BUNDLE_URL_PARAM, SAMPLE_PARAM } from 'app/constants/general'
import { setSirianoUnavailable } from 'app/Store/actions/modal'
import { adjustCatalog } from 'app/Utils/product.util'

export const SET_LOADING = 'SET_LOADING'
export const setLoading = (isLoading) => ({ type: SET_LOADING, payload: isLoading })

export const SET_ERROR = 'SET_ERROR'
export const setError = (isError) => ({ type: SET_ERROR, payload: isError })

export const SET_ENABLED_PRODUCTS = 'SET_ENABLED_PRODUCTS'
export const setEnabledProducts = (data) => ({ type: SET_ENABLED_PRODUCTS, payload: data })

export const SET_PRODUCTS = 'SET_PRODUCTS'
export const setProducts = (products) => ({ type: SET_PRODUCTS, payload: { products } })

export const SET_PRODUCT_PRICES = 'SET_PRODUCT_PRICES'
export const setProductPrices = ({ swatches, chips }) => ({ type: SET_PRODUCT_PRICES, payload: { swatches, chips } })

let flagHasFetched = false // to prevent this from happening multiple times
export const fetchEverything = () => dispatch => {
  if (flagHasFetched) return
  flagHasFetched = true

  dispatch(setLoading(true))
  dispatch(setLoadingColors(true))
  const { country, language } = useContext(CountryLangCtx)

  const origin = country === 'us' ? 'WEB-US' : 'WEB-CA'
  axios.all([
    `${getServiceBaseUrl}/items/all?Origin=${origin}`,
    `${getPrismApiBaseUrl}/v1/colors/cscc?lng=${language}-${country.toUpperCase()}`
  ].map(axios.get)).then(([productData, colors]) => {
    const products = productData.data

    dispatch(setProducts(adjustCatalog(products, country)))
    dispatch(setAvailableColors(colors.data))

    // check the url and add skus to the item hold
    // right now you can ONLY deeplink to either samples (P&S and chips) simple bundles or enriched bundles (with prices)  but not more than one
    const isBundleDeeplink = getSkusFromUrl(BUNDLE_URL_PARAM)
    const isEnrichedBundleDeeplink = getSkusFromUrl(ENRICHED_BUNDLE_URL_PARAM)
    const urlColors = isBundleDeeplink ?? isEnrichedBundleDeeplink ?? getSkusFromUrl(SAMPLE_PARAM)

    // remove deep link params from URL once we've grabbed what we need
    if (isBundleDeeplink) {
      removeDeepLink(BUNDLE_URL_PARAM)
    } else if (isEnrichedBundleDeeplink) {
      removeDeepLink(ENRICHED_BUNDLE_URL_PARAM)
    } else if (urlColors?.length) {
      removeDeepLink(SAMPLE_PARAM)
    }

    if (urlColors?.length) {
      // URLs may have %2C instead of commas
      const matchedProducts = urlColors
        .replace(/%2C/g, ',')
        .split(',')
        .map((productId) => {
          const match = products.find((product) => {
            const { sku } = product
            return sku.toLowerCase() === productId.toLowerCase() ||
              sku.replace('-', '').toLowerCase() === productId.toLowerCase()
          })

          return match
            ? {
                productId: match.sku.toLowerCase(),
                data: match
              }
            : null
        })
        .filter(Boolean)

      const matchedAvailableProducts = matchedProducts.filter(product => product.data.active)

      // is this a bundle deep link, but we have no matched available products?
      if ((isBundleDeeplink || isEnrichedBundleDeeplink) && !matchedAvailableProducts.length) {
        // ... then set it unavailable; will show Sorry modal
        dispatch(setSirianoUnavailable(true))
      } else if (matchedProducts.length) {
        // ... otherwise, empty the cart and add the matched products to our product hold
        dispatch(emptyCart())
        dispatch(addToHold(matchedProducts))
      }
    }
    dispatch(setLoading(false))
    dispatch(setLoadingColors(false))
  }).catch(error => {
    console.error('error:', error)
    dispatch(setError(true))
    dispatch(setLoading(false))
    dispatch(setLoadingColors(false))
  })
}
