import './PageIndex.scss'

import React, { useContext, useRef, useEffect, useState } from 'react'
import RemoteNotification from 'components/RemoteNotification/RemoteNotification'
import ColorBrowser from 'components/ColorBrowser/ColorBrowser'
import TopNav from 'components/TopNav/TopNav'
import Footer from 'components/Footer/Footer'
import useResetNavigationTo from 'app/hooks/useResetOnNavigateTo.js'
import useTitle from 'app/hooks/useTitle.js'
import HomepageHero from 'components/HomepageHero/HomepageHero'
import SwatchScroller from 'components/SwatchScroller/SwatchScroller'
import DisclaimerFAQCombo from '../DisclaimerFAQCombo/DisclaimerFAQCombo'
import { useSelector, useDispatch } from 'react-redux'
import DeepLinkCartModal from 'components/DeepLinkCartModal/DeepLinkCartModal'
import { addToCart, alignCartWithInventory, emptyHold, processPendingAdds } from 'app/Store/actions/cart'
import { openSnackbar } from 'app/Store/actions/snackbar'
import useCart from 'app/hooks/useCart'
import CountryLangCtx from 'app/contexts/countryLang/CountryLangCtx'
import countries from 'app/constants/countries'
import { getEnrichedPSBundleSkus, getPSBundleSkus } from 'app/Utils/url.util'
import { DeeplinkProductBuilder, getBundledProducts } from 'app/Utils/general.utils'
import { PS_BUNDLE_ITEM_TYPE } from 'app/constants/product'
import { useIntl } from 'react-intl'
import ChristianSirianoBanner from 'components/ChristianSirianoBanner/ChristianSirianoBanner'
import { setNavToPeelAndStick, setNavToTop50 } from 'app/Store/actions/colorWall'

const CART_BOUNDARY_REQUIRED_ID = 'cart-boundary-required-id'

function PageIndex ({ initialCollection }) {
  const intl = useIntl()
  const itemHold = useSelector((store) => store.itemHold)
  const { availableColors } = useSelector((store) => store.cart)
  const [pendingCartItems, setPendingCartItems] = useState([])
  const { count } = useCart()
  const [showAddToCartModal, setShowAddToCartModal] = useState(itemHold?.length > 0)
  const [showSnackBar, setShowSnackBar] = useState(false)
  const { skusChips, skusSwatches, skuSwatchesBundles } = useSelector((store) => store.products)
  const { country } = useContext(CountryLangCtx)
  const isUS = country === countries.us
  const [isFromPSBundleDeepLink] = useState(getPSBundleSkus())
  const [isFromEnrichedPSBundleDeepLink] = useState(getEnrichedPSBundleSkus())
  const [showEnrichedAddToCartModal, setShowEnrichedAddToCartModal] = useState(itemHold?.length > 0)
  // There are now # similar modal modes (say that 10x fast), a simple deeplink, a deep link with price and other info and an add to cart one with product info.
  // isFromPSBundleDeepLink is a flag for the simple deeplink, isFromEnrichedPSBundleDeepLink is for other deep link with price and siranoAddToCart is add to cart
  const [sirianoAddToCartItems, setSirianoAddToCartItems] = useState([])
  const [sirianoDataReady, setSirianoDataReady] = useState(null)
  const sirianoUnavailable = useSelector((store) => store.sirianoUnavailable)
  const [unavailableShown, setUnavailableShown] = useState(false)

  const topNavRef = useRef()

  useEffect(() => {
    if (isFromPSBundleDeepLink.length || isFromEnrichedPSBundleDeepLink.length) {
      // just kill this hook and use another one to keep this logic separate -RS
      return
    }
    if (itemHold?.length && availableColors?.length && skusChips && skusSwatches) {
      const items = itemHold.map((item) => {
        const productId = item.productId.toLowerCase()
        const searchCrit = isUS ? '-s' : '-ca'
        const isPeelAndStick = productId.indexOf(searchCrit) === -1
        const product = isPeelAndStick
          ? skusSwatches.find((p) => p.sku.toLowerCase() === productId)
          : skusChips.find((p) => p.sku.toLowerCase() === productId)

        if (!product) {
          return
        }

        const productBuilder = new DeeplinkProductBuilder(product.colors[0], availableColors)
        productBuilder.setProductId(productId)
        productBuilder.setIsPeelAnStick(isPeelAndStick)
        productBuilder.setIsPseudoProduct(false)
        productBuilder.setIsBundledItem(false)
        productBuilder.setPrice(product.basePrice)
        productBuilder.setAvailability(product.active)

        return productBuilder.get()
      }).filter(Boolean)

      setPendingCartItems(items)
      setShowAddToCartModal(true)
    }
  }, [itemHold, availableColors, skusChips, skusSwatches])

  // @todo - this hook will not render the siriano banner until the data needed is ready, should turn to a skeleton -RS
  useEffect(() => {
    if (availableColors?.length && skuSwatchesBundles) {
      setSirianoDataReady(true)
    }
  }, [availableColors, skuSwatchesBundles])

  useEffect(() => {
    // this hook is only meant to handle bundles, do not sully this!
    // It basically uses the bundle skus from the url to populate a temp store with the items to show in the modal
    if ((isFromPSBundleDeepLink.length || isFromEnrichedPSBundleDeepLink.length) && itemHold?.length && availableColors?.length && skuSwatchesBundles) {
      const productId = itemHold[0].productId.toLowerCase()
      const product = skuSwatchesBundles.find((p) => p.sku.toLowerCase() === productId)
      // We create a pseudo products they will only be used to display in the add to cart modal, and only the true product
      // if the psuedo-product flag is truthy, the app will only pass to display logic and not order processing
      const products = getBundledProducts(product, availableColors)
      setPendingCartItems(products)
      if (isFromPSBundleDeepLink.length) {
        setShowAddToCartModal(true)
      } else if (isFromEnrichedPSBundleDeepLink.length) {
        setShowEnrichedAddToCartModal(true)
      }
    }
  }, [isFromPSBundleDeepLink, isFromEnrichedPSBundleDeepLink, itemHold, availableColors, skuSwatchesBundles])

  // This hook is used exclusively to show the add to cart modal, NOT THE DEEPLINK, This enables the add bundle/kit btn
  useEffect(() => {
  }, [availableColors, skuSwatchesBundles])

  useEffect(() => {
    if (showSnackBar) {
      dispatch(openSnackbar({
        message: `${count} ${intl.formatMessage({ id: 'SNACK_BAR_TEXT' })}`
      }))
      setShowSnackBar(false)
    }
  }, [showSnackBar])

  useResetNavigationTo()
  useTitle()

  const dispatch = useDispatch()

  const handleDeepLinkClose = (e) => {
    if (e) {
      e.preventDefault()
    }

    setShowAddToCartModal(false)
    setPendingCartItems([])
    dispatch(emptyHold())
  }

  const handleSirianoAddToCartClose = (e) => {
    if (e) {
      e.preventDefault()
    }
    setSirianoAddToCartItems([])
  }

  // for deeplink
  const handleAddToCart = (e) => {
    e.preventDefault()
    setTimeout(() => {
      // Remove fake items used only for modal display, only send real item to the cart
      // isPseudo is for c. siriano
      itemHold.filter((item) => !item.isPseudoProduct).forEach((item) => {
        dispatch(addToCart(item))
      })
      // @todo these won't allow the deep link to be added do I need to call these for the addtocart from banner? -RS
      dispatch(processPendingAdds())
      dispatch(alignCartWithInventory())
      setShowSnackBar(true)
    }, 500)

    handleDeepLinkClose()
  }

  // @todo this is the function to use with the c. siriano banner -RS
  // eslint-disable-next-line no-unused-vars
  const openSirianoAddToCart = (e) => {
    e.preventDefault()
    const product = skuSwatchesBundles.find((item) => item.itemType === PS_BUNDLE_ITEM_TYPE)
    if (!product) {
      console.error('No Christian Siriano Bundles found.')
    }
    const kits = getBundledProducts(product, availableColors)
    setSirianoAddToCartItems(kits)
  }

  const closeUnavailableModal = (e) => {
    e.preventDefault()
    setUnavailableShown(true)
  }

  const goToPeelAndStick = (e) => {
    e.preventDefault()
    dispatch(setNavToPeelAndStick(true))
    setUnavailableShown(true)
  }

  const goToTop50 = (e) => {
    e.preventDefault()
    dispatch(setNavToTop50(true))
    setUnavailableShown(true)
  }

  return (
    <>
      <RemoteNotification messageKey='REMOTE_MESSAGE_CONTENT' />
      <TopNav showCart ref={topNavRef} />
      {/* this needs an ID for the Sticky component to work... wish it could use a ref */}
      <div id={CART_BOUNDARY_REQUIRED_ID} className='PageIndex'>
        <HomepageHero />
        {skuSwatchesBundles?.length
          ? <ChristianSirianoBanner
          openSirianoAddToCart={openSirianoAddToCart} />
          : null}
        <div>
          <SwatchScroller />
        </div>
        <section className='section has-background-white'>
          <div className='container'>
            <ColorBrowser openSirianoAddToCart={openSirianoAddToCart} />
            <footer className='pt-6'>
              <DisclaimerFAQCombo />
            </footer>
          </div>
        </section>
      </div>
      <Footer />
      {showAddToCartModal && pendingCartItems.length
        ? <DeepLinkCartModal
        itemData={pendingCartItems}
        closeHandler={handleDeepLinkClose}
        addToCartHandler={handleAddToCart} />
        : null}
      {sirianoAddToCartItems.length && sirianoDataReady && isUS
        ? <DeepLinkCartModal
        addToCart
        itemData={sirianoAddToCartItems}
        closeHandler={handleSirianoAddToCartClose} />
        : null}
      {showEnrichedAddToCartModal && pendingCartItems.length && isUS
        ? <DeepLinkCartModal
        addToCart
        itemData={pendingCartItems}
        closeHandler={handleDeepLinkClose} />
        : null}
      {sirianoUnavailable && !unavailableShown && isUS
        ? <DeepLinkCartModal
        unavailable
        closeHandler={closeUnavailableModal}
        goToPeelAndStick={goToPeelAndStick}
        goToTop50={goToTop50} />
        : null}
    </>
  )
}

export default PageIndex
