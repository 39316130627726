import { isArray } from 'lodash'

export const sortCountries = (countries, defaultCountryKey) => {
  if (isArray(countries)) {
    const country = countries.find((item) => item.value?.toLowerCase() === defaultCountryKey?.toLowerCase())
    const newList = countries.map((item) => {
      return { ...item }
    }).filter((item) => item.value?.toLowerCase() !== defaultCountryKey?.toLowerCase())
    if (country) {
      newList.unshift({ ...country })
    }

    return newList
  }
  return countries
}
