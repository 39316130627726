
import React from 'react'
import { FormattedMessage } from 'react-intl'

import './UnavailableView.scss'

const base = 'unavailable-view'
const header = `${base}__header`
const subheading = `${base}__subheading`
const buttonWrapper = `${base}__buttons`
const button = `${base}__button`

function UnavailableView (props) {
  const { goToPeelAndStick, goToTop50 } = props
  return <div className={base}>
    <div>
      <div className={header}>
        <FormattedMessage id='CHRISTIAN_SIRIANO_MODAL_UNAVAIL_HEADER' />
      </div>
      <div className={subheading}>
        <FormattedMessage id='CHRISTIAN_SIRIANO_MODAL_UNAVAIL_SUB_HEADING' />
      </div>
    </div>
    <div className={buttonWrapper}>
      <button onClick={goToPeelAndStick} className={`${button}--dark`}><FormattedMessage id='CHRISTIAN_SIRIANO_MODAL_UNAVAIL_PS_BTN' /></button>
      <button onClick={goToTop50} className={`${button}--light`}><FormattedMessage id='CHRISTIAN_SIRIANO_MODAL_UNAVAIL_TOP_50_BTN' /></button>
    </div>
  </div>
}

export default UnavailableView
