export default function stringToBool (input) {
  const type = typeof input

  if (type === 'boolean') {
    return input
  } else if (type === 'string') {
    return input.toLowerCase() === 'true'
  }

  return false
}
