import React, { useContext } from 'react'
import useCart from 'app/hooks/useCart'
import { FormattedMessage, useIntl } from 'react-intl'
import MinusIcon from 'src/svg/minus.svg'
import SVG from 'react-inlinesvg'

import './CartTotals.scss'
import CountryLangCtx from 'app/contexts/countryLang/CountryLangCtx'
import countries from 'app/constants/countries'

const CLASS = 'CartTotals'

function CartTotals ({ children, isEstimate = true, className = '', ...other }) {
  const { subtotal, totalTax = 0, grandTotal, deliveryFee } = useCart()
  const { country, language } = useContext(CountryLangCtx)
  const isUS = country === countries.us
  const currency = isUS ? 'USD' : 'CAD'
  const intl = useIntl()
  const locale = `${language}-${country.toUpperCase()}`
  const opts = {
    style: 'currency',
    currency,
    locale
  }
  /** @todo due to this bug: https://stackoverflow.com/questions/66341208/how-to-ensure-int-numberformat-does-not-show-currency-code-when-using-the-same-c
    * I am working some JS magic to remove the Canadian currency code -RS
   */
  const removeCACurrencyCode = (amount) => isUS ? amount : amount.replace('CA', '').trim()
  // const subtotalLine = <FormattedNumber style='currency' value={subtotal} currency={currency} />
  // const taxLine = isEstimate ? <SVG aria-hidden='true' className='svg' src={MinusIcon} /> : <FormattedNumber style='currency' value={totalTax} currency={currency} />
  // const totalLine = isEstimate ? subtotalLine : <FormattedNumber style='currency' value={grandTotal} currency={currency} />

  const subtotalLine = removeCACurrencyCode(intl.formatNumber(subtotal, opts))
  const taxLine = isEstimate ? <SVG aria-hidden='true' className='svg' src={MinusIcon} /> : removeCACurrencyCode(intl.formatNumber(totalTax, opts))
  const totalLine = isEstimate ? removeCACurrencyCode(intl.formatNumber(subtotal + deliveryFee, opts)) : removeCACurrencyCode(intl.formatNumber(grandTotal, opts))
  const deliveryFeeLine = (deliveryFee && !isEstimate) ? removeCACurrencyCode(intl.formatNumber(deliveryFee, opts)) : <SVG aria-hidden='true' className='svg' src={MinusIcon} />

  return (
    <section {...other} className={`${CLASS} ${className}`}>
      <dl className={`${CLASS}__items`}>
        <dt className={`${CLASS}__items__name`}><FormattedMessage id='CART_SUBTOTAL' /></dt>
        <dd className={`${CLASS}__items__value`}>{subtotalLine}</dd>

        <dt className={`${CLASS}__items__name`}><FormattedMessage id='CART_ESTIMATED_TAX' /></dt>
        <dd className={`${CLASS}__items__value`}>{taxLine}</dd>

        {isUS ? <dt className={`${CLASS}__items__name`}><FormattedMessage id='RETAIL_DELIVERY_FEE' /></dt> : null }
        {isUS ? <dd className={`${CLASS}__items__value`}>{deliveryFeeLine}</dd> : null}

        <dt className={`${CLASS}__items__name`}><FormattedMessage id='CART_S&H' /></dt>
        <dd className={`${CLASS}__items__value`}><span className='is-uppercase has-text-weight-bold'><FormattedMessage id='PRICE_FREE' /></span></dd>

        <dt className={`${CLASS}__items__name ${CLASS}__items__name--final`}><FormattedMessage id={isEstimate ? 'CART_ESTIMATED_TOTAL' : 'CART_TOTAL'} /></dt>
        <dd className={`${CLASS}__items__value ${CLASS}__items__value--final`}>{totalLine}</dd>
      </dl>
      {children ? <div className={`${CLASS}__child`}>{children}</div> : null}
    </section>
  )
}

export default CartTotals
