import { useLayoutEffect } from 'react'
import { useLocation } from 'react-router'

export default function useResetNavigationTo () {
  const location = useLocation()

  useLayoutEffect(() => {
    typeof window.scrollTo === 'function' && window.scrollTo(0, 0)
    typeof document.body.focus === 'function' && document.body.focus()
  }, [location.pathname])
}
