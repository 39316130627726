import { PERSISTENT_CLEAR_CART_KEY } from 'app/constants/cart'
import { LIMIT_CART_CHIP, LIMIT_ITEM_PEEL_STICK, PS_BUNDLE_ITEM_TYPE } from 'app/constants/product'
import extractFullColorNumber from 'app/Utils/extractFullColorNumber.util'
import extractUser from 'app/Utils/user.util'
import productIsChip from 'app/Utils/productIsChip.util'
import productIsSwatch from 'app/Utils/productIsSwatch.util'
import axios from 'axios'

import { getServiceBaseUrl } from '../../Utils/serviceUtils'
import { writePersistentLocal } from '../storeUtils'
import { setNoOrderError } from 'app/Store/slices/OrderForm'
import { cloneDeep } from 'lodash/lang'

export const CLEAR_PENDING = 'CLEAR_PENDING'
export const removeFromPending = () => ({ type: CLEAR_PENDING })

export const PROCESS_PENDING_ADDS = 'PROCESS_PENDING_ADDS'
export const processPendingAdds = () => (dispatch, getState) => {
  const { pendingAdds, loadingColors } = getState().cart
  const { loading } = getState().products

  if (loading || loadingColors) return

  dispatch(removeFromPending())
  pendingAdds.forEach(product => dispatch(addToCart(product)))
}

export const ADD_TO_PENDING = 'ADD_TO_PENDING'
export const addToPending = ({ productId, qty }) => ({ type: ADD_TO_PENDING, payload: { productId, qty } })

export const CART_LOADING = 'CART_LOADING'
export const setCartLoading = (loading) => ({ type: CART_LOADING, payload: loading })

export const CART_ADD = 'CART_ADD'
export const addToCart = ({ productId, qty = 1 }) => (dispatch, getState) => {
  const { availableColors, items, loadingColors } = getState().cart
  // skuSwatchesBundles is added for c. siriano
  const { skusChips, skusSwatches, skuSwatchesBundles, loading } = getState().products

  if (loading || loadingColors) {
    return dispatch(addToPending({ productId, qty }))
  }

  // get a color match
  const matchedProduct = [...skusChips, ...skusSwatches, ...skuSwatchesBundles].find(({ sku }) => sku.toLowerCase() === productId.toLowerCase())
  const isChip = productIsChip(matchedProduct)
  const isSwatch = productIsSwatch(matchedProduct)
  const isKit = matchedProduct.itemType === PS_BUNDLE_ITEM_TYPE || matchedProduct?.colors.length > 1
  const colors = cloneDeep(matchedProduct.colors.map((cn) => availableColors.find((color) => extractFullColorNumber(color) === cn)).filter(Boolean))

  // @todo I think colors is vestigial. It doesn't look like it is consumed in the reducer -RS
  // not even sure we need this check for the existence of the color(s)
  if (!colors.length) {
    console.error('Error with color data.')
    return
  } // do nothing if we can't find a color

  const price = matchedProduct.basePrice

  if (price === undefined) { // do nothing if we can't find a price
    console.error('Error with product data')
    return
  }

  const available = (isChip || ((isSwatch || isKit) && matchedProduct.active))

  if (!available) { // do nothing if the item is not in stock
    return
  }

  // enforcing per-cart and per-item quantity limits
  // get all chips or all of a particular peel and stick swatch
  const lineItemsOfInterest = isChip ? items.filter(({ isChip }) => isChip) : items.filter(item => item.productId.toLowerCase() === productId.toLowerCase())
  // find out how many of whatever item(s) there are
  const howManyAlready = lineItemsOfInterest.map(({ qty }) => qty).reduce((accum, qty) => accum + qty, 0)
  // see how many more we're allowed to add based on per-cart or per-line-item limitations
  // @todo are there limits for kits? ADDRESS THIS -RS
  const howManyToAdd = Math.min((isChip ? LIMIT_CART_CHIP : LIMIT_ITEM_PEEL_STICK) - howManyAlready, qty)

  dispatch({ type: CART_ADD, payload: { colors, productId, qty: howManyToAdd, price, isChip, isSwatch, isKit } })
}

export const SYNC_ITEMS = 'SYNC_ITEMS'
const syncItems = (payload) => {
  return { type: SYNC_ITEMS, payload }
}

export const SET_ORDER_ID = 'SET_ORDER_ID'
export const setOrderId = (orderId) => ({ type: SET_ORDER_ID, payload: { orderId } })

export const loadCart = (intl) => (dispatch, getState) => {
  const CART_ERROR = intl.formatMessage({ id: 'ERROR_CART' })
  const { orderId } = getState().cart

  if (!orderId) {
    dispatch(setCartLoading(false))
    dispatch(setNoOrderError(true))
    console.error('No order ID present, unable to load cart.')
    return // do nothing if we for some reason have no orderId
  }

  dispatch(clearCartErrors())
  dispatch(setCartLoading(true))

  axios({ method: 'GET', url: `${getServiceBaseUrl}/orders/${orderId}` })
    .then(({ data }) => {
      const { lineItems, deliveryFee } = data
      const user = extractUser(data)

      dispatch(syncItems({ items: lineItems, cleanUp: true }))
      dispatch(setUserInfo(user))

      if (deliveryFee) {
        dispatch(setDeliveryFee(deliveryFee))
      } else {
        dispatch(setDeliveryFee(0))
      }
    })
    .catch(err => {
      dispatch(setCartErrors([CART_ERROR]))
      console.error('Error loading cart.', err)
    })
    .then(() => {
      dispatch(setCartLoading(false))
    })
}

// since we are assuming the localStorage cart is the most up to date cart, this method will perform either a
// POST (if new) or a PATCH (if existing orderId) the localStorage cart and when the service returns the cart back we
// populate our localStorage with what is on the server. This is where we can handle if we have user information
// stored on the server as well.
export const synchronizeCart = (payload) => (dispatch) => {
  const { items, orderId, user, cleanUp, clientToken } = payload
  dispatch(setOrderId(orderId))
  dispatch(setUserInfo(user))
  dispatch(setClientToken(clientToken))
  dispatch(syncItems({ items, cleanUp }))
}

export const CART_REMOVE = 'CART_REMOVE'
export const removeFromCart = ({ productId, qty = Infinity }) => ({ type: CART_REMOVE, payload: { productId, qty } })

export const CART_EMPTY = 'CART_EMPTY'
export const emptyCart = (emptyAllForOrderId) => (dispatch, getState) => {
  // if we've received an order ID, it means we'll need to empty carts for ALL carts referencing this ID
  // write the clear cart request to local storage, passing in this order ID as a reference number
  if (emptyAllForOrderId) {
    writePersistentLocal(PERSISTENT_CLEAR_CART_KEY, { orderId: `${emptyAllForOrderId}` })
  }
  dispatch({ type: CART_EMPTY })
}

export const CART_ERRORS = 'CART_ERRORS'
export const setCartErrors = (errors) => ({ type: CART_ERRORS, payload: { errors } })

export const CART_ERRORS_CLEAR = 'CART_ERRORS_CLEAR'
export const clearCartErrors = () => ({ type: CART_ERRORS_CLEAR })

export const SET_AVAILABLE_COLORS = 'SET_AVAILABLE_COLORS'
export const setAvailableColors = (availableColors) => {
  return { type: SET_AVAILABLE_COLORS, payload: { availableColors } }
}

export const SET_CLIENT_TOKEN = 'SET_CLIENT_TOKEN'
export const setClientToken = (clientToken) => (dispatch, getState) => {
  dispatch({ type: SET_CLIENT_TOKEN, payload: { clientToken } })
}

export const SET_LOADING_COLORS = 'SET_LOADING_COLORS'
export const setLoadingColors = (value) => ({ type: SET_LOADING_COLORS, payload: value })

export const SET_USER_INFO = 'SET_USER_INFO'
export const setUserInfo = (user = {}) => (dispatch, getState) => {
  dispatch({ type: SET_USER_INFO, payload: { user } })
}

export const SEE_OPTIONS = 'SEE_OPTIONS'
export const setSeeOptions = (color) => {
  return { type: SEE_OPTIONS, payload: color }
}

export const HIDE_OPTIONS = 'HIDE_OPTIONS'
export const setHideOptions = () => ({ type: HIDE_OPTIONS })

export const ALIGN_CART_WITH_INVENTORY = 'ALIGN_CART_WITH_INVENTORY'
export const alignCartWithInventory = () => (dispatch, getState) => {
  const { skusChips, skusSwatches, skuSwatchesBundles } = getState().products
  dispatch({ type: ALIGN_CART_WITH_INVENTORY, payload: { chips: skusChips, swatches: skusSwatches, kits: skuSwatchesBundles } })
}

export const CLEAR_TOKENS_AND_IDS = 'CLEAR_TOKENS_AND_IDS'
export const clearTokensAndIDs = () => (dispatch, getState) => {
  const user = getState().cart?.user || {}
  dispatch(setOrderId(null))
  dispatch(setClientToken(null))
  dispatch(setUserInfo({
    ...user,
    userId: null
  }))
}

export const SET_DELIVERY_FEE = 'SET_DELIVERY_FEE'
export const setDeliveryFee = (fee = 0) => {
  return {
    type: SET_DELIVERY_FEE,
    payload: fee
  }
}

// temp place just before add to cart
export const ADD_TO_HOLD = 'ADD_TO_HOLD'
export function addToHold (items = []) {
  return {
    type: ADD_TO_HOLD,
    payload: items
  }
}

export function emptyHold () {
  return addToHold()
}

export const SET_AUTH_ID = 'SET_AUTH_ID'
export function setAuthId (authId = '') {
  return {
    type: SET_AUTH_ID,
    payload: authId
  }
}
