import countries from './countries'
import languages from './languages'
/**
 * Parse the link object to the target region
 * @param {object} link specify the object of the externalLink you want to parse
 * @param {string} country specify the country for the link
 * @param {string} language specify the language for the link
 */
export const parseLink = (link, country, language) => {
  if (typeof link === 'string') {
    return link
  }
  let result = link.default
  if (country && link[country]) {
    result = link[country].default || result
    if (language && link[country][language]) {
      result = link[country][language] || result
    }
  }
  return result
}

export default {
  expertColorAdvice: 'https://homeowner.sherwin-williams.com/colorconsult/',
  storeLocator: {
    default: 'https://www.sherwin-williams.com/store-locator',
    [countries.ca]: {
      [languages.en]: 'https://www.sherwin-williams.ca/en/find-a-store/',
      [languages.fr]: 'https://www.sherwin-williams.ca/fr/trouver-un-magasin/'
    }
  },
  contactUs: {
    default: 'https://www.sherwin-williams.com/homeowners/contact-us/feedback',
    [countries.ca]: {
      [languages.en]: 'https://www.sherwin-williams.ca/en/contact-us/',
      [languages.fr]: 'https://www.sherwin-williams.ca/fr/contactez-nous/'
    }
  }
}
