import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import throttle from 'lodash/throttle'
import { composeWithDevTools } from 'redux-devtools-extension'
import omit from 'lodash/omit'

import { readPersistentLocal, writePersistentLocal } from './storeUtils'
import rootReducer from './reducers/rootReducer'
import { emptyCart, setCartErrors } from './actions/cart'
import { PERSISTENT_CART_KEY, PERSISTENT_CLEAR_CART_KEY } from 'app/constants/cart'

const enhancers = applyMiddleware(
  thunkMiddleware
)

const persistedState = readPersistentLocal(PERSISTENT_CART_KEY) ?? {}

const store = createStore(
  rootReducer,
  persistedState,
  composeWithDevTools(
    enhancers
  )
)

// sync cart items to localStorage on change but, throttle to 1s to alleviate overcalling
const persistCart = throttle(() => writePersistentLocal(PERSISTENT_CART_KEY, {
  cart: omit(store.getState().cart,
    'errors',
    'seeOptionsFor',
    'orderId',
    'clientToken',
    'user.userId')
}), 1000)

store.subscribe(persistCart)

const checkForCartReset = () => {
  const { orderId: clearRequestOrderId } = readPersistentLocal(PERSISTENT_CLEAR_CART_KEY) ?? {}

  // if there's a clear cart key present (it'll be an orderId)...
  if (clearRequestOrderId) {
    const { orderId: thisOrderId } = store.getState().cart // get THIS order ID

    // if this instance has an order ID and it matches the clear request...
    if (thisOrderId && `${thisOrderId}` === `${clearRequestOrderId}`) {
      // a different instance has cleared cart for this order ID, so empty this instance's cart as well
      store.dispatch(emptyCart())
      // populate cart error to display in checkout flow
      store.dispatch(setCartErrors(['Your order session has expired.']))
    }
  }
}

setInterval(checkForCartReset, 500)
checkForCartReset()

export default store
