import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import CountryLangCtx from 'app/contexts/countryLang/CountryLangCtx'
import ColorSnap from 'src/svg/colorsnap.svg'
import SVG from 'react-inlinesvg'
import './ColorBrowser.scss'
import Toggle from 'components/Toggle/Toggle'
import axios from 'axios'
import Prism, {
  ColorWall,
  ColorSwatch,
  ColorWallToolBar,
  Search
  , pushToDataLayer, GTMDataBuilder, AnalyticsEvents, AnalyticsInteractionTypes, AnalyticsToolNames
} from '@prism/toolkit'
import { setSeeModal } from 'app/Store/actions/modal'
import { isColorEde, isColorHistoric, isColorTimeless } from 'app/Utils/isColorFamily.util'
import { setSeeOptions } from 'app/Store/actions/cart'
import SearchSwatch from 'components/ColorBrowser/SearchSwatch'
import debounce from 'lodash/debounce'
import TextButton from 'components/ColorBrowser/TextButton'
import Disclaimer from 'components/Disclaimer/Disclaimer'
import archivedColors from './archived-colors'
import SwatchContentRenderer from './SwatchContentRenderer'
import { shouldRemoveCSirianoFromColorCollection } from 'app/Utils/christinaSiriano.utils'
import { setNavToChristianSiriano, setNavToPeelAndStick, setNavToTop50 } from 'app/Store/actions/colorWall'
import { getPrismApiBaseUrl } from 'app/Utils/serviceUtils'
import countries from 'app/constants/countries'
import defaultKitImage from 'src/images/kit-image.png'
import { ANALYTICS_FILTER_TYPES, trackColorCollection, trackColorFamily } from 'app/Utils/analytics'
import labels from 'app/constants/adobeAnalytics'
import { getDevelopmentEnvFromHostname, getPageInfoFromURL } from 'app/Utils/url.util'

const P_S_GROUP_NAME = 'Peel & Stick'
const CART_PROMPT_REGEX = /[&]?sampleModal=([^&]+)[&]?/
const baseViewUrl = '/VIEW/'
const baseURL = `${getPrismApiBaseUrl}/v1`

function ColorBrowser (props) {
  const { openSirianoAddToCart } = props
  const { country, language } = useContext(CountryLangCtx)
  const { formatMessage } = useIntl()
  const lang = `${language}-${country.toUpperCase()}`
  // @todo all label comparisons should be lowercased -RS
  const top50Section = lang === 'en-US' ? 'Top 50 Colors' : lang === 'en-CA' ? 'Top 50 Colours' : '50 Couleurs Les plus Populaires'
  const ede = lang === 'en-US' ? 'Designer Color Collection' : lang === 'en-CA' ? 'Designer Color Collection' : 'Collection de couleurs Designer'
  const historicColor = lang === 'en-US' ? 'Historic Colors' : lang === 'en-CA' ? 'Historic Colours' : 'Couleur Historique'
  const timelessColor = lang === 'en-US' ? 'Timeless Colors' : lang === 'en-CA' ? 'Timeless Colours' : 'Couleur Intemporelle'
  const defaultSwColorsSection = lang === 'en-US' ? 'Sherwin-Williams Colors' : lang === 'en-CA' ? 'Sherwin-Williams Colours' : 'Sherwin-Williams Couleurs'
  const colorWallConfig = { bloomEnabled: true }
  // eslint-disable-next-line no-unused-vars
  const christinSiranoSection = 'Christian Siriano Color Collection'
  // eslint-disable-next-line no-unused-vars
  const christinaSirianoFamily = 'Christian Siriano x Sherwin-Williams Color Collection'
  const isUS = country === countries.us
  const checkForView = makeCheckForView(isUS)

  const [activeColorId, setActiveColorId] = useState(null)
  const [activeGroup, setActiveGroup] = useState(top50Section)
  const [activeShape, setActiveShape] = useState(null)
  const [activeSubGroup, setActiveSubGroup] = useState(null)
  const [colorFromUrl] = useState(getCartPrompt(window.location.href))
  const [currentSubGroups, setCurrentSubGroups] = useState([])
  const [groupData, setGroupData] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [peelAndStickToggledOn, setPeelAndStickToggledOn] = useState(false)
  const [results, setResults] = useState([])
  const [shapeData, setShapeData] = useState(null)
  const [showSearch, setShowSearch] = useState(false)
  const [shapeId, setShapeId] = useState(0)
  const [subGroupData, setSubGroupData] = useState(null)
  const [suggestions, setSuggestions] = useState([])
  const { availableColors, loadingColors } = useSelector(state => state.cart)
  const { skusChips, skusSwatches, skuSwatchesBundles, loading } = useSelector(state => state.products)
  const dispatch = useDispatch()
  const scrollAnchorRef = useRef()
  const hasScrollToColorWall = useRef(false)
  const navToPeelAndStick = useSelector(store => store.navToPeelAndStick)
  const navToTop50 = useSelector(store => store.navToTop50)
  const navToChristianSiriano = useSelector(store => store.navToChristianSiriano)
  const [query, setQuery] = useState('')
  const setQueryDb = useCallback(debounce(setQuery, 800), [])

  // Adobe page info
  const environment = getDevelopmentEnvFromHostname(window.location.hostname)
  const pageInfo = getPageInfoFromURL()
  // End Adobe page info

  const scrollToColorWall = useCallback(() => {
    setTimeout(() => {
      if (scrollAnchorRef.current) {
        const stickyHeaderHeight = document.querySelector('.TopNav').getBoundingClientRect().height
        const y = scrollAnchorRef.current.getBoundingClientRect().y - stickyHeaderHeight
        const scrollTop = window.scrollY || document.documentElement.scrollTop

        if (typeof window.scrollTo === 'function') {
          window.scrollTo({
            // eslint-disable-line
            top: y + scrollTop,
            left: 0,
            behavior: 'smooth'
          })
        } else {
          // just for IE and old browser support
          window.current.scrollTop = y + scrollTop // eslint-disable-line
          window.current.scrollLeft = 0 // eslint-disable-line
        }
      }
    }, 1000)
  }, [])

  // These may look like dupes, but these handle programmatic nav from NON-DEEPLINKS.
  useEffect(() => {
    if (navToPeelAndStick) {
      setPeelAndStickToggledOn(true)
      dispatch(setNavToPeelAndStick(false))
      setShouldScrollToColorWall(true)
    }
  }, [navToPeelAndStick])

  useEffect(() => {
    if (navToTop50) {
      dispatch(setNavToTop50(false))
      setActiveGroup(top50Section)
      setActiveSubGroup(null)
      setActiveColorId(null)
      setShouldScrollToColorWall(true)
    }
  }, [navToTop50])

  useEffect(() => {
    if (navToChristianSiriano) {
      dispatch(setNavToChristianSiriano(false))
      setActiveGroup(christinSiranoSection)
      setActiveSubGroup(null)
      setActiveColorId(null)
      setShouldScrollToColorWall(true)
    }
  }, [navToChristianSiriano])

  const getResults = async (query, lang) => {
    setLoading(true)
    return axios
      .get(`${baseURL}/search/cscc?query=${query.toLowerCase()}&lng=${lang}`)
      .then((r) => r.data)
      .then((colors) => {
        setLoading(false)
        return colors
      })
  }

  useEffect(() => {
    if (query.length < 2) {
      setResults([])
      return
    }

    getResults(query, lang)
      .then((searchResults) => {
        const numberOfSearchResults = searchResults.results.length
        const gtmBuilder = new GTMDataBuilder()
        gtmBuilder.setToolName(AnalyticsToolNames.colorWall)
          .setEvent(AnalyticsEvents.colorTool)
          .setLinkText(query)
          .setInteractionType(AnalyticsInteractionTypes.colorWallSearch)
        if (numberOfSearchResults) {
          gtmBuilder.setSearchResults(numberOfSearchResults)
          pushToDataLayer(gtmBuilder.getData())

          setResults(searchResults.results)
          setSuggestions([])
        } else {
          gtmBuilder.setSearchResults(numberOfSearchResults)
          pushToDataLayer(gtmBuilder.getData())
          setSuggestions(searchResults.suggestions)
          setResults([])
        }

        // Adobe colorwall_search event
        const adobeEvent = {
          event: labels.evPrefix + 'search',
          eventinfo: {
            eventName: labels.evNamePrefix + 'Search',
            cwSearchTerm: query,
            cwSearchResults: numberOfSearchResults
          },
          pageInfo: {
            pageName: pageInfo.pageName,
            pageURL: pageInfo.pageURL,
            previousPageName: pageInfo.previousPageName,
            environment,
            businessCountryLanguage: lang
          },
          applicationInfo: {
            applicationName: labels.applicationName,
            applicationType: labels.applicationType,
            applicationOS: labels.applicationOS
          }
        }
        window.adobeDataLayer.push(adobeEvent)
        // End Adobe colorwall_search event
      })
      .catch(() => setResults([]))
  }, [query])

  useEffect(() => {
    if (groupData && shapeData && availableColors && availableColors.length && colorFromUrl && !hasScrollToColorWall.current) {
      hasScrollToColorWall.current = true
      setTimeout(() => {
        // THIS IS FOR BLOOMING A SPECIFIC COLOR ON LOAD
        const chosenColor = availableColors
          .filter(color => {
            return (`${color.brandKey}${color.colorNumber}`.toLocaleLowerCase() === colorFromUrl.toLowerCase())
          })
        if (chosenColor.length) {
          // could have more than one color (same color data, different IDs), grab first for section
          // testing below
          const checkedColor = chosenColor[0]
          // will hold our chosen color section
          let targetSection
          // generally prefer non-bright since brights only exist in main SW color wall, but we won't know
          // until we determine section (hence the let)
          let preferBright = false

          if (isColorEde(checkedColor)) {
            targetSection = ede
          } else if (isColorTimeless(checkedColor)) {
            targetSection = timelessColor
          } else if (isColorHistoric(checkedColor)) {
            targetSection = historicColor
          } else {
            targetSection = defaultSwColorsSection
            // if we decide to show
            preferBright = true // prefer bright when showing default SW color wall (if available)
          }

          // look for a bright if bright is preferred, look for non-bright if non-bright is preferred
          const bestColorForChosenWall = chosenColor.find((color) => {
            const isBright = color.id.match(/^bright/)
            if ((preferBright && isBright) || (!preferBright && !isBright)) {
              return true
            }
            return false
          })

          // if no "best" color could be found, fall back to checkedColor
          const finalColor = bestColorForChosenWall ?? checkedColor

          setActiveGroup(targetSection)
          setActiveSubGroup(null)

          if (finalColor) {
            setActiveColorId(finalColor.id)
          }

          setShouldScrollToColorWall(true)
        }
      }, 1125) // this is more art than science :) -RS
    }
  }, [availableColors, groupData, shapeData])

  const colorMap = useMemo(() => {
    return availableColors?.reduce((map, c) => {
      map[c.id] = c
      return map
    }, {})
  }, [availableColors])

  const onGroupBtnClick = (label) => {
    // color collection select
    trackColorCollection(label)

    setActiveColorId(null)
    setActiveGroup(label)
    setActiveSubGroup(null)
  }
  const onSubGroupBtnClick = (label) => {
    // color family select
    trackColorFamily(label)

    setActiveColorId(null)
    setActiveGroup(null)
    setActiveSubGroup(label)
  }
  const onShowAllBtnClick = () => {
    setActiveColorId(null)
    setActiveSubGroup(null)
    setActiveGroup(defaultSwColorsSection)
    setPeelAndStickToggledOn(false)
  }
  const onSearchBtnClick = () => {
    setShowSearch(!showSearch)
  }

  const toolBarProps = {
    uiStyle: 'minimal',
    onSearchBtnClick,
    onSubGroupBtnClick,
    onGroupBtnClick,
    onShowAllBtnClick,
    messages: {
      CLOSE: formatMessage({ id: 'COLOR_WALL_CLOSE' }),
      SEARCH_COLOR: formatMessage({ id: 'SEARCH_FOR_A_COLOR' }),
      VIEW_ENTIRE_COLOR_WALL: formatMessage({ id: 'VIEW_ENTIRE_COLOR_WALL' }),
      CANCEL: formatMessage({ id: 'CANCEL' }),
      SELECT_COLLECTION: formatMessage({ id: 'SELECT_COLLECTION' }),
      EXPLORE_COLOR_FAMILIES: formatMessage({ id: 'EXPLORE_COLOR_FAMILIES' }),
      EXPLORE_COLLECTIONS: formatMessage({ id: 'EXPLORE_COLLECTIONS' })
    },
    toolBarData: {
      groups: shouldRemoveCSirianoFromColorCollection(groupData, !skuSwatchesBundles?.length)?.map((group) => group.name).filter((name) => name !== P_S_GROUP_NAME),
      activeGroup,
      subgroups: currentSubGroups,
      activeSubgroup: activeSubGroup,
      primeColorWall: defaultSwColorsSection
    },
    toolBarConfig: { alwaysShowSubGroups: false, closeBtn: {}, shouldShowCloseButton: false }
  }

  useEffect(() => {
    const endPoints = [
      `${baseURL}/shapes/cscc?lng=${lang}`,
      `${baseURL}/groups/cscc?lng=${lang}`,
      `${baseURL}/subgroups/cscc?lng=${lang}`
    ]
    axios.all(endPoints.map((endpoint) => axios.get(endpoint))).then(
      (data) => {
        setShapeData(data[0].data)
        setGroupData(data[1].data)
        setSubGroupData(data[2].data)
      }
    )
  }, [])

  useEffect(() => {
    /**
     * Does two things here...
     * First, we look through all our groups, find their subgroups, and (if they exist) make one of
     * them active if our current activeSubGroup exists in that list.
     * -
     * After that, we look at either groupData or subGroupData and choose our wall shape based on which should be active
     */

    // FIND ALL SUBGROUPS MATCHING CURRENT ACTIVE GROUP; SET ACTIVE SUBGROUP IF MATCHES, OTHERWISE UNSET ACTIVE SUBGROUP
    let _activeSubGroup = activeSubGroup

    if (subGroupData && groupData) {
      groupData
        .filter(({ name }) => activeGroup === name) // gets active group
        .map(({ subgroups }) => subgroups) // gets group's subgroups
        .forEach(subgroups => {
          // map subgroup IDs to subgroup names
          const currentGroupSubgroups = subgroups.map(subgroupId => {
            return subGroupData.find(({ id }) => id === subgroupId)?.name
          }).filter(Boolean)
          // set current group's subgroups as currentSubGroups (it could be a 0-length array)
          setCurrentSubGroups(currentGroupSubgroups)

          _activeSubGroup = currentGroupSubgroups.includes(activeSubGroup) ? activeSubGroup : null
          // if current activeSubGroup is in our group's subgroups, set it as active; otherwise unset
          setActiveSubGroup(_activeSubGroup)
        })
    }

    // FIND SHAPE THAT MATCHES ACTIVE GROUP OR SUBGROUPS
    // prefer subGroupData if we have _activeSubGroup; otherwise prefer groupData if we have an activeGroup
    const currentId = [...(groupData ?? []), ...(subGroupData ?? [])].find(({ name }) => (_activeSubGroup ?? activeGroup) === name)?.id

    if (currentId) {
      setShapeId(currentId)
      // eslint-disable-next-line no-unused-expressions
      const matchedShape = shapeData?.find((shape) => currentId === shape.id)?.shape
      matchedShape && setActiveShape(matchedShape)
    }
  }, [activeGroup, groupData, activeSubGroup, subGroupData, skuSwatchesBundles])

  const [shouldScrollToColorWall, setShouldScrollToColorWall] = useState(false)

  useEffect(() => {
    /**
     * All this does is look to see if a group or subgroup has been requested via the URL
     * if so, it will set them appropriately in state
     * and then scroll to the color wall
     */
    if (subGroupData && groupData) {
      const chosenSg = checkForView(subGroupData)
      const chosenG = checkForView(groupData)

      if (chosenSg) {
        setActiveGroup(defaultSwColorsSection)
        setActiveSubGroup(chosenSg)
        setShouldScrollToColorWall(true)
      } else if (chosenG) {
        setActiveGroup(chosenG)
        setActiveSubGroup(null)
        setShouldScrollToColorWall(true)
      }
    }
  }, [subGroupData, groupData])

  useEffect(() => {
    if (shouldScrollToColorWall && availableColors && !loadingColors) {
      scrollToColorWall()
    }
  }, [shouldScrollToColorWall, availableColors, loadingColors])

  useEffect(() => {
    // ---------------------------------------------------------------------
    // [DCT-448] show product modal when colors and products have loaded,
    // and if we have a color prompt in the URL
    // ---------------------------------------------------------------------
    if (colorFromUrl) {
      // we have a color prompt
      if (!loading && !loadingColors) {
        new Promise((resolve, reject) => {
          console.info(`Checking for product match to prompt in URL: ${colorFromUrl}`)
          // colors and products have loaded
          // look through products for a color match; if we have one, open options modal
          const promptColorNumber = [
            skusChips.filter(({ colors, active }) => {
              return colors.indexOf(colorFromUrl) >= 0 && active
            }).reduce((accum, next) => accum || next, null),
            skusSwatches.filter(({ colors, active }) => {
              return colors.indexOf(colorFromUrl) >= 0 && active
            }).reduce((accum, next) => accum || next, null)
          ]
            .filter(Boolean)
            // this is ok since these are for single color items like chips and p&s
            .map(v => v.colors[0]?.match(/\d{4}/g)?.[0])
            .reduce((accum, next) => {
              return accum || next
            }, undefined)

          // if we have a match in our available chips or swatches skus...
          if (promptColorNumber) {
            // ... then find the matching color object
            const foundColor = availableColors
              .filter(({ colorNumber }) => colorNumber === promptColorNumber).reduce((accum, next) => accum || next, undefined)
            // if we have a matching color object...
            if (foundColor) {
              // ... then resolve promise with the color we found
              resolve(foundColor)
              return
            }
          }

          // if we're here then something has gone wrong; reject the promise
          reject(Error('Unable to find matching product from color prompt'))
        }).then(color => {
          console.info('Product prompt found!', color)
          dispatch(setSeeOptions(color))
        }).catch(err => {
          console.info('No product prompt found.', err)
          dispatch(setSeeModal({
            title: <div className='m-5 has-text-centered-tablet is-size-5-mobile has-text-weight-bold'>We encountered a problem</div>,
            content: <p className='m-5 has-text-centered-tablet'>We are unable to find a product matching the color that was provided.</p>
          }))
        })

        // store off a copy of pushState
        const pushState = window.history.pushState
        // override pushState so we can hook into it
        window.history.pushState = function (state) {
          try {
            // when pushState is run (read: when navigating anywhere within samples), see if the cart prompt exists in the URL
            // and remove it
            const urlParams = window.location.search.replace(CART_PROMPT_REGEX, '').replace(/\?$/, '')
            // replace the current state with the new no-prompt-param URL before navigating away
            window.history.replaceState(null, null, `${window.location.pathname}${urlParams}`)
          } catch (err) {
            console.warn('Unable to replace swatchModal query param.', err)
          }

          // run pushState as usual after this point
          return pushState.apply(window.history, arguments)
        }

        return () => {
          // after unloading this component, replace our modified pushState with the original copy we stored off
          window.history.pushState = pushState
        }
      } else {
        console.info('Loading products and colors')
      }
    }
  }, [loadingColors, loading, skusChips, skusSwatches, availableColors, colorFromUrl])

  const suggestedMessage = (
    <div className={'flex flex-col'}>
      <div className={'font-bold text-sm'}>
        <FormattedMessage id='SEARCH_NO_RESULTS' className={'font-bold'} />
      </div>
      {suggestions.length
        ? (
        <div className={'text-sm'}>
          <FormattedMessage
            id='SEARCH_SUGGESTIONS'
            values={{
              suggestions: (
                <>
                  {suggestions.map((suggestion, i, arr) => (
                    <React.Fragment key={i}>
                      <TextButton
                        onClick={() => setQuery(suggestion)}
                      >
                        {suggestion}
                      </TextButton>
                      {i < arr.length - 1 && ', '}
                    </React.Fragment>
                  ))}
                </>
              )
            }}
          />
        </div>
          )
        : null}
    </div>
  )

  const searchPrompt = (
    <div className='text-base font-bold'>
      <FormattedMessage id='SEARCH_PROMPT' />
    </div>
  )

  const isPeelAndStick = activeGroup === P_S_GROUP_NAME

  // Near the top of the code, the default section has been changed from the full color wall to Top 50.
  // The below logic is here to preserve the business' desire to have the full wall show after P&S has been toggled off.
  const togglePeelAndStick = () => {
    if (peelAndStickToggledOn) {
      setShowSearch(false)
      setActiveGroup(defaultSwColorsSection)
      setActiveSubGroup(null)
      setActiveColorId(null)
    } else {
      const gtmBuilder = new GTMDataBuilder()
      gtmBuilder.setEvent(AnalyticsEvents.colorTool)
        .setToolName(AnalyticsToolNames.colorWall)
        .setLinkText('see all available colors')
        .setInteractionType(AnalyticsInteractionTypes.filter)
      const gtmData = gtmBuilder.getData()
      gtmData.filter_type = ANALYTICS_FILTER_TYPES.PEEL_AND_STICK

      pushToDataLayer(gtmData)

      // Adobe colorwall_toggle event
      const adobeEvent = {
        event: labels.evPrefix + 'toggle',
        eventinfo: {
          eventName: labels.evNamePrefix + 'Toggle',
          buttonName: labels.toolName + '-toggle',
          toolName: labels.toolName
        },
        pageInfo: {
          pageName: pageInfo.pageName,
          pageURL: pageInfo.pageURL,
          previousPageName: pageInfo.previousPageName,
          environment,
          businessCountryLanguage: lang
        },
        applicationInfo: {
          applicationName: labels.applicationName,
          applicationType: labels.applicationType,
          applicationOS: labels.applicationOS
        }
      }
      window.adobeDataLayer.push(adobeEvent)
      // End Adobe colorwall_toggle event

      setShowSearch(false)
      setActiveGroup(P_S_GROUP_NAME)
      setActiveSubGroup(null)
      setActiveColorId(null)
    }

    setPeelAndStickToggledOn(!peelAndStickToggledOn)
  }

  return (
    <section ref={scrollAnchorRef} className='ColorBrowser'>
      <div className='has-text-dark'>
        <header>
          <SVG aria-hidden='true' className='svg PageIndex__colorsnap-logo' src={ColorSnap} />
          <h1 className='mb-5 has-text-weight-semibold is-size-4-mobile is-size-3-tablet'>
            <FormattedMessage id='EXPLORE_COLORWALL' />
          </h1>
          <input
            className='ColorBrowser__toggle__checkbox is-sr-only'
            checked={isPeelAndStick}
            aria-checked={isPeelAndStick}
            role='switch'
            onChange={togglePeelAndStick}
            type='checkbox'
            id='peel-stick-toggle'
            aria-labelledby='peel-stick-toggle-label'
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <label className='ColorBrowser__toggle' htmlFor='peel-stick-toggle' id='peel-stick-toggle-label'>
            {country === 'us' &&
              <span className='level is-mobile'>
                <span className='level-item'>
                  <Toggle isOn={isPeelAndStick} />
                </span>
                <span className='bg-blue-500'>
                  <FormattedMessage id='AVAILABLE_IN_PEEL_AND_STICK' />
                </span>
              </span>
            }
          </label>
        </header>

        <div className='mt-5'>
          { (activeShape && groupData && subGroupData && !loadingColors)
            ? (
              <>
                <div style={{ minHeight: '475px' }}>
                  <Prism>
                    {(!showSearch)
                      ? (
                      <>
                        <ColorWallToolBar {...toolBarProps} />
                        <ColorWall
                          shape={activeShape}
                          colorWallConfig={colorWallConfig}
                          height={475}
                          key={shapeId}
                          onActivateColor={(uniqColorId) => setActiveColorId(uniqColorId)}
                          activeColorId={activeColorId}
                          colorResolver={(id) => colorMap[id]}
                          activeSwatchContentRenderer={({ color }) => <SwatchContentRenderer color={color} />}
                          swatchBgRenderer={(props) => ColorWall.DefaultSwatchBackgroundRenderer({
                            ...props,
                            overlayRenderer: ({ color }) => {
                              const isUnavailable = archivedColors.has(`${color.colorNumber}`)
                              return isUnavailable ? <ColorSwatch.Dogear /> : null
                            }
                          })}
                        />
                      </>
                        )
                      : (
                      <Search
                        height={475}
                        isLoading={isLoading}
                        messages={{
                          cancel: formatMessage({ id: 'CANCEL' }),
                          searchPlaceholder: formatMessage({ id: 'SEARCH_FOR_A_COLOR' })
                        }}
                        minimal
                        noResultsContent={suggestedMessage}
                        onClickCancel={onSearchBtnClick}
                        query={query}
                        results={results}
                        searchPromptContent={searchPrompt}
                        setQuery={setQueryDb}
                        swatchRenderer={SearchSwatch}
                      />
                        )}
                  </Prism>
                </div>
                {(peelAndStickToggledOn || activeGroup === ede)
                  ? <div className='ColorBrowser__disclaimer'>
                    <Disclaimer
                      showHeading
                      className='has-background-white-bis'
                      media={<img src={require('src/images/emerald-designer-edition.png')} alt={formatMessage({ id: 'EMERALD_DESIGNER_EDITION' })} />}
                      message={<FormattedMessage id='EDE_DISCLAIMER' />}
                      heading={<FormattedMessage id='EDE_DISCLAIMER_TITLE' />} />
                  </div>
                  : (activeGroup === christinSiranoSection && isUS)
                      ? <div className='ColorBrowser__disclaimer'>
                      <Disclaimer
                        showHeading
                        className='has-background-white-bis'
                        media={<img src={defaultKitImage} alt={formatMessage({ id: 'CHRISTIAN_SIRIANO_KIT_ALT_TEXT' })} />}
                        message={formatMessage({ id: 'SIRIANO_CTA_MSG' })}
                        tertiary={<button className='button is-dark is-uppercase is-fullwidth' onClick={openSirianoAddToCart}>
                          <FormattedMessage id='ORDER_KIT' />
                        </button>}
                        heading={formatMessage({ id: 'SIRIANO_CTA_HEADING' })} />
                    </div>
                      : null}
              </>
              )
            : null
          }
        </div>
      </div>
    </section>
  )
}

function getCartPrompt (input = '') {
  const [, color] = input.match(CART_PROMPT_REGEX) ?? []

  if (color) {
    return color
  }
  return null
}

function makeCheckForView (isUs) {
  return function checkForView (data = []) {
    const searchIn = window.location.href
      .replace(/-/g, '') // remove hyphens
      .replace(/&/g, '%26') // replace ampersands with %26
      .replace(/%20/g, '') // explicitly remove URI-encoded spaces first
      .replace(/%[a-zA-Z0-9]{2}/g, '') // remove any URI-encoded characters
      .toLowerCase() // make all lowercase

    const chosen = data.reduce((accum, sg) => {
      const { name } = sg

      const searchFor = `${baseViewUrl}${encodeURIComponent(name)
        .replace(/-/g, '') // remove hyphens
        .replace(/%20/g, '') // explicitly remove URI-encoded spaces first
        .replace(/%[a-zA-Z0-9]{2}/g, '')}` // remove any other URI-encoded characters
        .toLowerCase()

      return accum || (
        searchIn.includes(searchFor)
          ? name
          : accum
      )
    }, null)

    // filter here to kill colorwall deeplinks
    if (!isUs && `${chosen}`.toLowerCase().indexOf('siriano') > -1) {
      return null
    }

    return chosen
  }
}

export default ColorBrowser
