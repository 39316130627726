import findIndex from 'lodash/findIndex'

/**
 * Searches for an item in an array that causes a predicate function to return true, then replaces it
 * with the value returned from the replacement function. If no items cause the predicate function to
 * return true, then the value returned from the replacement function called with no arguments, is
 * added to the end or the original array.
 *
 * @param {T[]} arr - an array to mutate
 * @param {T => boolean} predicate - predicate used to find item being replaced
 * @param {(T|null) => T} getItem - replacement function
 *
 * @returns {T[]} the mutated array with an item replaced according to predicate and replacement functions
 */
function addOrReplaceBy (arr = [], predicate, getItem) {
  const index = findIndex(arr, predicate)
  return index === -1
    ? [...arr, getItem()]
    : [
        ...arr.slice(0, index),
        getItem(arr[index]),
        ...arr.slice(index + 1)
      ]
}

export default addOrReplaceBy
