import { combineReducers } from 'redux'

import { modal, sirianoUnavailable } from './modal'
import { cart, authId } from './cart'
import { products } from './products'
import { snackbar } from './snackbar'

import orderForm from '../slices/OrderForm'
import { itemHold } from 'app/Store/reducers/itemHold'
import { navToPeelAndStick, navToTop50, navToChristianSiriano } from 'app/Store/reducers/colorWall'

export default combineReducers({
  cart,
  modal,
  orderForm,
  products,
  snackbar,
  itemHold,
  sirianoUnavailable,
  navToPeelAndStick,
  navToTop50,
  navToChristianSiriano,
  authId
})
