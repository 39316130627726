/**
 * set api url based on current hostname
 */
export const getServiceBaseUrl = (
  {
    'samples.sherwin-williams.ca': '/api', // prod CA
    'samples.sherwin-williams.com': '/api', // production US
    'colorchips.sherwin-williams.com': '/api', // production US
    'colorchipscanada.ebus.swaws': '/api', // internal prod CA
    'samples.ebus.swaws': '/api', // internal-facing production
    'colorchips.ebus.swaws': '/api', // internal-facing production
    'qa-colorchipscanada.ebus.swaws': '/api-qa-ext', // CA QA
    'qa-colorchips.ebus.swaws': '/api-qa-ext', // QA
    'qa-samples.sherwin-williams.ca': '/api-qa', // QA
    'qa-samples.sherwin-williams.com': '/api-qa' // External QA
  }[window.location.hostname] || API_PATH // everything else
) + '/colorsamples'

console.log('Service URL: ', getServiceBaseUrl)

export const getPrismApiBaseUrl = {
  'samples.sherwin-williams.ca': 'https://api.sherwin-williams.com/prism', // CA production prism
  'samples.sherwin-williams.com': 'https://api.sherwin-williams.com/prism', // production prism
  'colorchips.sherwin-williams.com': 'https://api.sherwin-williams.com/prism', // production prism
  'colorchipscanada.ebus.swaws': 'https://api.sherwin-williams.com/prism', // CA internal-facing production
  'samples.ebus.swaws': 'https://api.sherwin-williams.com/prism', // internal-facing production
  'colorchips.ebus.swaws': 'https://api.sherwin-williams.com/prism', // internal-facing production
  'qa-colorchipscanada.ebus.swaws': 'https://qa-api.sherwin-williams.com/prism', // CA QA prism
  'qa-colorchips.ebus.swaws': 'https://qa-api.sherwin-williams.com/prism', // QA prism
  'qa-samples.sherwin-williams.ca': 'https://qa-api.sherwin-williams.com/prism', // CA QA prism
  'qa-samples.sherwin-williams.com': 'https://qa-api.sherwin-williams.com/prism' // QA prism
}[window.location.hostname] || 'https://develop-prism-api.ebus.swaws' // every other host gets develop prism

console.log('Prism URL: ', getPrismApiBaseUrl)

export const getTransArmorToken = {
  'samples.sherwin-williams.ca': 'LJS4', // production transarmor token
  'samples.sherwin-williams.com': 'LJS4', // production transarmor token
  'colorchips.sherwin-williams.com': 'LJS4', // production transarmor token
  'colorchipscanada.ebus.swaws': 'LJS4', // CA internal-facing production
  'samples.ebus.swaws': 'LJS4', // production transarmor token
  'colorchips.ebus.swaws': 'LJS4', // production transarmor token
  'qa-colorchipscanada.ebus.swaws': 'NOIW', // CA sandbox transarmor token
  'qa-colorchips.ebus.swaws': 'NOIW', // sandbox transarmor token
  'qa-samples.sherwin-williams.ca': 'NOIW', // sandbox transarmor token
  'qa-samples.sherwin-williams.com': 'NOIW' // sandbox transarmor token
}[window.location.hostname] || 'NOIW' // sandbox transarmor token

console.log('Token val: ', getTransArmorToken)

export const getColorVisualizerUrl = {
  'samples.sherwin-williams.ca': 'https://www.sherwin-williams.com/en-us/color/color-tools/color-visualizer', // CA production prism
  'samples.sherwin-williams.com': 'https://www.sherwin-williams.com/en-us/color/color-tools/color-visualizer', // production prism
  'colorchips.sherwin-williams.com': 'https://www.sherwin-williams.com/en-us/color/color-tools/color-visualizer', // production prism
  'colorchipscanada.ebus.swaws': 'https://www.sherwin-williams.com/en-us/color/color-tools/color-visualizer', // CA internal-facing production
  'samples.ebus.swaws': 'https://www.sherwin-williams.com/en-us/color/color-tools/color-visualizer', // internal-facing production
  'colorchips.ebus.swaws': 'https://www.sherwin-williams.com/en-us/color/color-tools/color-visualizer', // internal-facing production
  'qa-colorchipscanada.ebus.swaws': 'https://qa-www.sherwin-williams.com/en-us/color/color-tools/color-visualizer', // CA QA prism
  'qa-colorchips.ebus.swaws': 'https://qa-www.sherwin-williams.com/en-us/color/color-tools/color-visualizer', // QA prism
  'qa-samples.sherwin-williams.ca': 'https://qa-www.sherwin-williams.com/en-us/color/color-tools/color-visualizer', // CA QA prism
  'qa-samples.sherwin-williams.com': 'https://qa-www.sherwin-williams.com/en-us/color/color-tools/color-visualizer', // QA prism
  'local-colorchipscanada.ebus.swaws': 'https://dev-www.sherwin-williams.com/en-us/color/color-tools/color-visualizer', // CA dev prism
  'local-colorchips.ebus.swaws': 'https://dev-www.sherwin-williams.com/en-us/color/color-tools/color-visualizer', // dev prism
  'local-samples.sherwin-williams.ca': 'https://dev-www.sherwin-williams.com/en-us/color/color-tools/color-visualizer', // CA dev prism
  'local-samples.sherwin-williams.com': 'https://dev-www.sherwin-williams.com/en-us/color/color-tools/color-visualizer' // dev prism
}[window.location.hostname] || 'https://www.sherwin-williams.com/en-us/color/color-tools/color-visualizer' // default to prod

console.log('Color Visualizer URL: ', getColorVisualizerUrl)
