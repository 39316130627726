import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import './NextSteps.scss'

function NextSteps () {
  const { formatMessage } = useIntl()

  return (
    <section className='next-steps'>
      <div className='columns is-vcentered'>
        <div className='column'>
          <img className='next-steps__media' alt={formatMessage({ id: 'CONFIRMATION_NEXT_STEPS_TITLE' })} src={require('src/images/next-steps.png')} />
        </div>
        <div className='column next-steps__content has-text-centered-mobile has-text-left-tablet'>
          <h1 className='title'><FormattedMessage id='CONFIRMATION_NEXT_STEPS_TITLE' values={{
            line: (chunk, i) => <>{chunk}<br /></>
          }} /></h1>
          <p className='mb-4'><FormattedMessage id='CONFIRMATION_NEXT_STEPS_MSG' /></p>
          <FormattedMessage id='CONFIRMATION_NEXT_STEPS_CTA' values={{
            buyPaintOnlineBtn: chunk => <a className='button is-dark is-uppercase' href='https://www.sherwin-williams.com/homeowners/buy-paint'>{chunk}</a>
          }} />
        </div>
      </div>
    </section>
  )
}

export default NextSteps
