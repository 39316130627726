import React from 'react'
import { FormattedMessage } from 'react-intl'

const Header = (props) => {
  const { countOfItems } = props
  return (
    <div className='header-wrapper'>
      <div className='header-content'>
        <div className='header-text'>
          <FormattedMessage id='PAGE_DEEPLINK_HEADER' values={{
            count: countOfItems,
            dlAmount: (chunk) => {
              return <span className='heavier'><FormattedMessage id='PAGE_DEEPLINK_COUNT' values={{ count: chunk }} /></span>
            },
            pronoun: (chunk) => <FormattedMessage id='PAGE_DEEPLINK_PRONOUN' values={{ count: chunk }} />
          }} /></div>
      </div>
    </div>
  )
}

export default Header
