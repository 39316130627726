import { useEffect } from 'react'
import at from 'lodash/at'
import { useIntl } from 'react-intl'

export default function useTitle (title = '') {
  const { formatMessage } = useIntl()

  useEffect(() => {
    const wholeTitle = title ? `${title} | ${formatMessage({ id: 'PAGE_TITLE_MAIN' })}` : formatMessage({ id: 'PAGE_TITLE_MAIN' })

    try {
      if (document.title !== wholeTitle) {
        document.title = wholeTitle
      }

      Array.from(document.getElementsByTagName('meta')).filter(meta => at(meta, 'attributes.property.value')[0] === 'og:title').forEach(meta => { meta.attributes.content.value = wholeTitle })
    } catch (err) { console.warn('Unable to update title to:', wholeTitle, err) }
  }, [title])

  // useEffect(() => {
  //   try {
  //     const metas = document.getElementsByTagName('meta')
  //     Array.from(metas).filter(meta => at(meta, 'attributes.property.value')[0] === 'og:description').forEach(meta => { meta.attributes.content.value = title })
  //     Array.from(metas).filter(meta => meta.name === 'description').forEach(meta => { meta.attributes.content.value = description })
  //   } catch (err) { console.warn('Unable to update description to:', description, err) }
  // }, [description])
}
