import React from 'react'
import CartBlock from 'components/Cart/CartBlock'

export default function ProductListView (props) {
  const { displayMode, qtyChangeHandler, products } = props

  return products.map((item, i) => {
    return <CartBlock.Item
      displayMode={displayMode}
      onQtyChange={qtyChangeHandler}
      item={item}
      key={`${item.productId}-${i}`}
    />
  })
}
